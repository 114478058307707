<template>
  <div class="mt-24 userGrid-wrapper">
    <p class="cursor-pointer -mt-6 mb-6 text-3xl" @click="appPage">
      <b>{{ store.user.appName }}</b>
    </p>
    <div class="splitter">
      <ul class="nav">
        <li :class="{ active: show === 'lsco' }" @click="lsco">
          LS&amp;Co Internal Users
        </li>
        <li :class="{ active: show === 'vendor' }" @click="vendor">
          Vendor Users
        </li>
        <li
          v-if="
            !(
              store.user.appName === 'NestRVT' ||
              store.user.appName === 'VendorRVT'
            )
          "
          :class="{ active: show === 'access' }"
          @click="access"
        >
          User Access Requests
        </li>
        <li
          v-if="
            !(
              store.user.appName === 'NestRVT' ||
              store.user.appName === 'VendorRVT'
            )
          "
          :class="{ active: show === 'deny' }"
          @click="deny"
        >
          Denied Requests
        </li>
        <li
          v-if="
            store.user.appName === 'NestRVT' ||
            store.user.appName === 'VendorRVT'
          "
          :class="{ active: show === 'access' }"
          @click="access"
        >
          Vendor Access Requests
        </li>
        <li
          v-if="
            store.user.appName === 'NestRVT' ||
            store.user.appName === 'VendorRVT'
          "
          :class="{ active: show === 'deny' }"
          @click="deny"
        >
          Denied Requests
        </li>
        <!-- <li @click="urlUser">
          <router-link :to="url"> LS&amp;Co Internal Users </router-link>
        </li>
        <li @click="urlVendor">
          <router-link :to="url"> Vendor Users </router-link>
        </li> -->
        <li :class="{ active: show === 'issues' }" @click="issues">Issues</li>
        <li
          v-if="store.user.appName === 'BOM'"
          :class="{ active: show === 'compare' }"
          @click="compare"
        >
          CDC Compare
        </li>
      </ul>
      <div class="inlineSearchInput mb-8" :class="{ active: isSearching }">
        <input
          v-model="searchTerm"
          type="text"
          placeholder="Enter User Name or ID"
        />
      </div>
      <div class="pt-3 flex pr-3" @click="() => (isSearching = !isSearching)">
        <i class="fa fa-search mr-3"></i>
        <p class="cursor-pointer mb-12">Search</p>
      </div>
    </div>
    <!-- <LSCOUsers v-if="show === 'lsco'" />
    <VendorUsers v-else /> -->

    <VendorUsers v-if="show === 'vendor'" :response="response" />
    <LSCOUsers v-else-if="show === 'lsco'" :response="response" />
    <AccessUsers v-else-if="show === 'access'" :response="response" />
    <Issues v-else-if="show === 'issues'" :response="response" />
    <Compare
      v-else-if="show === 'compare' && store.user.appName === 'BOM'"
      :response="response"
    />
    <DeniedUsers v-else :response="response" />
    <router-view />
  </div>
</template>

<script>
import LSCOUsers from '../views/user/LSCOUsers.vue'
import VendorUsers from '../views/user/VendorUsers.vue'
import AccessUsers from '../views/user/AccessUsers.vue'
import DeniedUsers from '../views/user/DeniedUsers.vue'
import Issues from '../views/user/Issues.vue'
import Compare from '../views/user/Topics.vue'
export default {
  inject: ['store'],
  components: {
    VendorUsers,
    LSCOUsers,
    AccessUsers,
    DeniedUsers,
    Issues,
    Compare,
  },
  data() {
    return {
      show: 'lsco',
      isSearching: false,
      searchTerm: '',
      response: [],
    }
  },
  computed: {},
  watch: {
    async searchTerm(newVal, oldVal) {
      if (newVal !== oldVal) {
        let resp
        if (this.show === 'lsco') {
          resp = await this.store.assort.search(this.searchTerm)
        } else if (this.show === 'issues') {
          resp = this.applySearch(this.searchTerm)
        } else {
          resp = await this.store.assort.searchVendor(this.searchTerm)
        }
        this.response = resp
      } else {
        if (this.show === 'lsco') {
          this.response = this.store.assort.users
        } else {
          this.response = this.store.assort.approvedvendors
        }
      }
    },
  },
  methods: {
    getClass(which) {
      if (this.$route.path === which) {
        return 'active'
      }
      return ''
    },
    lsco() {
      this.show = 'lsco'
      // this.url = `/userRVT/applications/${localStorage.getItem('currAppName')}`
    },
    vendor() {
      this.show = 'vendor'
      // this.url = `/userRVT/applications/${localStorage.getItem(
      //   'currAppName',
      // )}/vendorUsers`
    },
    access() {
      this.show = 'access'
    },
    deny() {
      this.show = 'deny'
    },
    appPage() {
      this.store.user.condition = 'global'
    },
    issues() {
      this.show = 'issues'
    },
    compare() {
      this.show = 'compare'
    },
    applySearch(term) {
      const tmpArr = []
      const response = this.store.assort.issues
      const termLowered = term.toLowerCase()
      if (term.length > 2) {
        response.forEach(res => {
          let hasPushed = false
          const fname = res.assignedToFullName?.toLowerCase()
          const createdBy = res.createdBy.toLowerCase()
          const status = res.issueStatus.toLowerCase()
          const subject = res.subject.toLowerCase()
          if (fname?.indexOf(termLowered) >= 0) {
            tmpArr.push(res)
            hasPushed = true
          }
          if (!hasPushed) {
            if (createdBy.indexOf(termLowered) >= 0) {
              tmpArr.push(res)
              hasPushed = true
            }
          }
          if (!hasPushed) {
            if (status.indexOf(termLowered) >= 0) {
              tmpArr.push(res)
              hasPushed = true
            }
          }
          if (!hasPushed) {
            if (subject.indexOf(termLowered) >= 0) {
              tmpArr.push(res)
              hasPushed = true
            }
          }
        })
        return (this.response = tmpArr)
      } else {
        return (this.response = this.store.assort.issues)
      }
    },
  },
}
</script>
<style lang="scss">
.splitter {
  // @include centerColumn();
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;

  .nav {
    display: flex;
    li {
      cursor: pointer;
      margin-right: 3rem;
      &:first-child {
        a {
          padding-left: 0;
        }
      }

      a {
        padding: 0 10px;
        height: $headerHeight;
        display: flex;
        align-items: center;
        color: $black;
        transition: $anim-trans-base;
        position: relative;
        font-size: 2rem;
        font-weight: bold;

        &:hover {
          &::after {
            width: 100%;
            left: 0;
            opacity: 0.15;
          }

          svg path {
            stroke: $white;
          }
        }

        &::after {
          content: '';
          background: $gray-lines;
          position: absolute;
          left: 50%;
          bottom: 0;
          height: 1px;
          width: 0;
          transition: all 0.25s;
          opacity: 0;
        }

        &.active {
          color: #b3aeae;

          &::after {
            width: 100%;
            background: #c41320;
            height: 4px;
            opacity: 1;
            left: 0;
            // margin-bottom: 1px;
          }

          svg path {
            stroke: $white;
          }
        }

        svg {
          margin-right: 0.5rem;
          width: 16px;
          height: 16px;
          path {
            transition: $anim-trans-base;
          }
        }
      }
    }
  }

  .docPageIcon {
    height: 16px;
    width: 16px;

    path {
      stroke: $gray-header-links;
    }
  }

  .prefs {
    margin-left: auto;
    height: $headerHeight;
    display: flex;
    align-items: center;
  }
}
.userGrid-wrapper {
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);
}
ul.nav {
  border-bottom: 2px solid #d8d8d8;
  margin-top: 20px;
  margin-bottom: 40px;
  width: 100%;
  font-size: 16px;
}
ul.nav li {
  display: inline-block;

  a.router-link-active {
    color: #c21a31;
    border-left: 0 !important ;
    margin-right: 20px;
    border-bottom: 2px solid;
  }
}
.active {
  color: #c41230;
  font-weight: bold;
}
input[type='text'] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  color: black;
}
</style>
