import Vue from 'vue'
import { brandToUrl, affiliates, regions } from '../util/valueHelpers'

const addGlobalAssortment = store => {
  return new Vue({
    data: {
      addMode: false,
      affiliate: localStorage.getItem('currAffiliate') || '',
      affiliatesHelper: affiliates,
      assortmentIds: [],
      assortMetaDataArray: [],
      assortType: localStorage.getItem('currAssortType') || 'personal',
      brand: localStorage.getItem('brand') || '483',
      carryMode: false,
      carryOver: false,
      carryOverComplete: false,
      carryOverSubmitted: false,
      copyMode: false,
      componentKey: 0,
      createdAssortID: '',
      createSubmitted: false,
      creatorUserId: '',
      currentGridView: localStorage.getItem('gridview') || 'grid',
      deleteSubmitted: false,
      editMode: false,
      editRows: [],
      error: false,
      errorBrand: false,
      errorMatch: false,
      errorName: false,
      errorPC9: false,
      errorSeason: false,
      errorYear: false,
      fromExcel: false,
      formKey: 0,
      footerKey: 0,
      gridKey: 0,
      isDeleting: false,
      loading: false,
      localHub: localStorage.getItem('lookupHub') || 'global',
      locked: false,
      name: '',
      newLocalHub: 'global',
      newSeason: '',
      newYear: '',
      paletteMode: false,
      pc9array: [],
      pc9existing: [],
      pc9input: '',
      pc9rejects: [],
      pc9sadded: false,
      pc9status: false,
      pc9statusName: '',
      pc9success: [],
      preSelectedAssortId: '',
      preSelectedPath: '',
      region: localStorage.getItem('currRegion') || 'global',
      regionsHelper: regions,
      season: localStorage.getItem('season') || 'S3',
      selectedRows: [],
      showStatusReport: false,
      statusResults: [],
      visibility: localStorage.getItem('currVisibility') || 'personal',
      year: localStorage.getItem('year') || '2019',
      compare: false,
      compareMode: false,
      selectedGroup: [],
      selectedAssortmentsName: [],
    },
    asyncComputed: {
      assortmentRegions: {
        default: [],
        lazy: true,
        async get() {
          const response = await store.api.get({
            url: `/api/lookup/regions/${store.currentBrand}/global`,
          })
          // eslint-disable-next-line no-unused-vars
          // console.log('assortmentRegions', [response)
          // console.log('REGIONs')
          // console.log(response)
          return [...response]
        },
      },
      assortmentAffiliates: {
        default: [],
        lazy: true,
        async get() {
          const affiliateLevel =
            this.localHub === 'global' ? '/' : `/${this.region}`
          const response = await store.api.get({
            url: `/api/lookup/regions/${store.currentBrand}${affiliateLevel}`,
          })
          // eslint-disable-next-line no-unused-vars
          const filteredResponse = response.filter(aff => {
            // console.log(aff)
            return !store.regions.items.includes(aff)
          }) /* */
          const filtered = filteredResponse.filter(function (
            value,
            index,
            arr,
          ) {
            return value !== 'TEST_REGION'
          })
          console.log('filtered affilaites HERE')
          console.log(filtered)
          localStorage.setItem('currAssortAffiliates', JSON.stringify(filtered))
          return filtered
        },
      },
    },
    computed: {
      isEmpty() {
        return (
          this.brand === '' &&
          this.name === '' &&
          this.season === '' &&
          this.year === '' &&
          this.pc9input === ''
        )
      },
      brandName() {
        return brandToUrl(this.brand)
      },
      affiliateFriendly() {
        const result = this.affiliatesHelper(this.affiliate)
        return result
      },
      regionFriendly() {
        const result = this.regionsHelper(this.region)
        return result
      },
    },
    mounted() {
      if (window.localStorage.getItem('year')) {
        this.year = window.localStorage.getItem('year')
      }
      if (store.router.path === '/assort/global') {
        this.visibility = 'global'
      }
      if (store.router.path === '/assort/my') {
        this.visibility = 'personal'
      }
    },
    methods: {
      async add() {
        console.log('ADD API')
        // this.loading = true
        // store.popups.add({
        //   text: 'Assort create submitted. Notification below once complete',
        // })

        // if (this.region !== 'global') {
        //   this.visibility = 'region'
        //   localStorage.setItem('currVisibility', 'region')
        // }
        this.pc9statusName = this.name
        try {
          const result = await store.api.post({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/assortment`,
            body: {
              season: this.season,
              year: this.year,
              brand: this.brand,
              localHub: this.localHub,
              name: this.name,
              visibility: this.visibility,
              isLocked: this.locked,
            },
          })
          this.createdAssortID = result.assortmentId
          console.log('created assort')
          console.log(this.createdAssortID)
          localStorage.setItem('year', this.year)
          localStorage.setItem('brand', this.brand)
          localStorage.setItem('season', this.season)
          console.log('ADD GLBAL')
          store.addGlobalAssortment.addPC9()
        } catch (ex) {
          // We can ignore the error if they added one they already have
          // on their list.
          if (ex.message !== '400') {
            throw ex
          }
        }
      },
      async edit() {
        // this.loading = true
        this.pc9statusName = this.name
        try {
          const result = await store.api.put({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/assortment/${this.createdAssortID}`,
            body: {
              name: this.name,
              visibility: this.visibility,
              isLocked: this.locked,
            },
          })
          localStorage.setItem('year', this.year)
          localStorage.setItem('brand', this.brand)
          localStorage.setItem('season', this.season)
          console.log('EDIT RESULT')
          console.log(result)

          // this.pc9status = true
          // this.createdAssortID = result.assortmentId
          if (this.pc9input.length > 5) {
            store.addGlobalAssortment.addPC9()
          } else {
            this.loading = false
            this.pc9rejects = []
            this.pc9success = []
            this.pc9existing = []
            store.assort.refresh()
            store.applications.refresh()
            this.pc9status = true
            this.createSubmitted = false
            this.editMode = false
            this.selectedRows = []
            this.editRows = []
            store.applications.componentKey += 1
            store.searchAssortments.componentKey += 1
            store.assort.componentKey += 1
          }
          // this.redirectMyRefresh()
        } catch (ex) {
          // We can ignore the error if they added one they already have
          // on their list.
          if (ex.message !== '400') {
            throw ex
          }
        }
      },
      async addPC9() {
        // ^\d{5}-\d{4}$
        this.pc9rejects = []
        this.pc9success = []
        // this.showStatusReport = false
        // const regTest = /[^0-9,-]*$/
        const strippedPC9input = this.pc9input.replace(/[^\r\n0-9,-]/g, '')
        this.pc9input = strippedPC9input
        if (this.pc9input.trim().length < 15) {
          this.pc9array.push(this.pc9input)
        } else {
          if (this.pc9input.includes(',')) {
            const inputToArray = this.pc9input.replace(/\s+/g, '').split(',')
            // this.pc9array = ['24827-0008', '32987-0005']
            this.pc9array = inputToArray
          } else {
            const inputToArray = this.pc9input.split('\n')
            // this.pc9array = ['24827-0008', '32987-0005']
            this.pc9array = inputToArray
          }
        }

        const regTestPC9 = /^\d{5}-\d{4}$/
        this.pc9array = this.pc9array.filter(val => {
          return regTestPC9.test(val)
        })
        const pc9objarr = this.arryToObj()
        try {
          const result = await store.api.post({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/assortment/${this.createdAssortID}/pc9`,
            body: pc9objarr,
          })
          const resultRejects = result.filter(pc9 => {
            return pc9.status >= 400
          })

          this.pc9rejects = [...resultRejects]
          const resultSuccess = result.filter(pc9 => {
            return pc9.status === 200
          })
          this.pc9success = [...resultSuccess]
          const resultExisting = result.filter(pc9 => {
            return pc9.status === 202
          })
          this.pc9existing = [...resultExisting]
          console.log('PC9 results')
          console.log(result)
          console.log(JSON.stringify(result))
          localStorage.setItem('statusresults', result)
          this.statusResults = result

          // store.router.$router.push({ name: 'assortLanding' })
          this.createSubmitted = false
          localStorage.setItem('currAssortName', this.name)
          this.pc9sadded = true
          this.loading = false
          // store.assort.refresh()
          // store.applications.refresh()
          // this.selectedRows = []
          // this.editRows = []
          // store.applications.componentKey += 1
          // store.searchAssortments.componentKey += 1
          // store.assort.componentKey += 1
          // this.pc9status = true
          store.addGlobalAssortment.pc9status = true
          // store.assort.componentKey += 1
          // store.assortments.componentKey += 1

          // localStorage.setItem('gridview', 'list')
          // store.addGlobalAssortment.currentGridView = 'list'

          this.editMode = false
          this.showStatusReport = true
          // store.assortUser.componentKey += 1
          // this.footerKey += 1
          console.log('completed ADD ASSORTMENT')
          this.reset()
          // setTimeout(this.redirectMy, 1500)
          // this.createdAssortID = result
        } catch (ex) {
          // We can ignore the error if they added one they already have
          // on their list.
          if (ex.message !== '400') {
            throw ex
          }
        }
      },
      async copy() {
        console.log('CARRY OVER')
        // this.loading = true
        let localVisibility = 'global'
        if (store.router.path === '/assort/my') {
          localVisibility = 'personal'
        }
        this.pc9statusName = this.name
        let bodyNew = ''
        if (this.copyMode) {
          bodyNew = `name=${this.name}&visibility=${localVisibility}`
        } else {
          bodyNew = `newSeason=${this.newSeason}&newYear=${this.newYear}&localHub=${this.localHub}&createdBy=${store.user.current.lscoUserName}&modifiedBy=${store.user.current.lscoUserName}&visibility=${localVisibility}`
        }
        console.log(bodyNew)
        try {
          const result = await store.api.postParams({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/assortment/${this.createdAssortID}/copy`,
            body: bodyNew,
            // params: {
            //   newSeason: this.newSeason,
            //   newYear: this.newYear,
            //   localHub: this.newLocalHub,
            // },
          })

          // console.log('UPDATE RESULT')
          // console.log(result)
          // console.log(result.assortmentId)
          store.teams.assortmentId = result.assortmentId
          // const jsonresults = JSON.stringify(result)
          // console.log(jsonresults)
          // this.year = this.newYear
          // this.season = this.newSeason

          if (!this.copyMode) {
            localStorage.setItem('year', this.newYear)
            localStorage.setItem('season', this.newSeason)
          }
          localStorage.setItem('currVisibility', localVisibility)
          localStorage.setItem('gridview', 'list')

          console.log('COPY VARS')
          console.log(this.copyMode)
          console.log(this.teamSelected)
          if (this.processTeam) {
            store.teams.addTeamAssortment(result.assortmentId)
          } else {
            this.loading = false
            this.pc9rejects = []
            this.pc9success = []
            this.pc9existing = []
            store.addGlobalAssortment.carryOverComplete = true
            store.addGlobalAssortment.carryOverSubmitted = true
            this.currentGridView = 'list'
            this.carryMode = false

            setTimeout(this.redirectMyRefresh, 10)
          }
        } catch (ex) {
          // We can ignore the error if they added one they already have
          // on their list.
          if (ex.message !== '400') {
            throw ex
          }
        }
      },
      async copyAll() {
        console.log('CARRY OVER ALL')
        // this.loading = true
        let localVisibility = 'global'
        if (store.router.path === '/assort/my') {
          localVisibility = 'personal'
        }
        this.pc9statusName = this.name
        const bodyNew = `newSeason=${this.newSeason}&newYear=${this.newYear}&localHub=${this.localHub}&createdBy=${store.user.current.lscoUserName}&modifiedBy=${store.user.current.lscoUserName}&visibility=${localVisibility}`
        console.log(bodyNew)
        // let assortId1 = 'f0adca78-b84a-4925-9da7-3cc682525072'
        // let assortId2 = '9875019d-daf9-4695-8b42-6a712c1b49f6'
        const assortIds = [...this.assortmentIds]
        console.log('ASSORT IDS')
        console.log(assortIds)
        try {
          let markers = []

          for (let i = 0; i < assortIds.length; i++) {
            try {
              let result = await store.api.postParams({
                url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/assortment/${assortIds[i]}/copy`,
                body: bodyNew,
              })
              markers[i] = result
              console.log('MARKERS ARRAY')
              console.log(markers)
            } catch (e) {
              console.error(e.message)
            }
          }

          console.log('LOOP DONE')

          Promise.all(markers)
            .then(function (responses) {
              // Get a JSON object from each of the responses
              return Promise.all(
                responses.map(function (response) {
                  console.log(response)
                  return response
                }),
              )
            })
            .then(responses => {
              console.log(responses)
              localStorage.setItem('year', this.newYear)
              // localStorage.setItem('brand', this.newBrand)
              localStorage.setItem('season', this.newSeason)
              localStorage.setItem('currVisibility', localVisibility)
              localStorage.setItem('gridview', 'list')

              this.loading = false
              this.pc9rejects = []
              this.pc9success = []
              this.pc9existing = []
              store.addGlobalAssortment.carryOverComplete = true
              store.addGlobalAssortment.carryOverSubmitted = true
              this.currentGridView = 'list'
              this.carryMode = false

              setTimeout(this.redirectMyRefresh, 10)
            })
            .catch(function (error) {
              // if there's an error, log it
              console.log(error)
            })
        } catch (ex) {
          // We can ignore the error if they added one they already have
          // on their list.
          if (ex.message !== '400') {
            throw ex
          }
        }
      },
      async cache(addOrDelete) {
        // this.loading = true
        this.pc9statusName = this.name

        let userCache = false
        console.log('ASSORT ID')
        console.log(this.createdAssortID)
        if (addOrDelete === 'add') {
          if (
            store.assetCache.timestamps[this.createdAssortID] !== 'undefined'
          ) {
            userCache = true
            // cacheStamp = store.assetCache.timestamps[this.createdAssortID]
            // let date1 = new Date(cacheStamp)

            // cacheStamp = date1.toISOString()
            // console.log(cacheStamp)
          }
        }

        console.log('write CACHE')
        console.log(userCache)
        try {
          const result = await store.api.put({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/assortment/${this.createdAssortID}`,
            body: {
              isCachedByUser: userCache,
              // cacheStamp: cacheStamp,
            },
          })
          // localStorage.setItem('year', this.year)
          // localStorage.setItem('brand', this.brand)
          // localStorage.setItem('season', this.season)
          console.log(result)

          // this.pc9status = true
          // this.createdAssortID = result.assortmentId
          // if (this.pc9input.length > 5) {
          //   store.addGlobalAssortment.addPC9()
          // } else {
          this.loading = false
          this.pc9rejects = []
          this.pc9success = []
          this.pc9existing = []
          store.assort.refresh()
          store.applications.refresh()
          store.assort.componentKey += 1
          store.assortments.componentKey += 1
          localStorage.removeItem(`cacheAssort${this.createdAssortID}`)

          setTimeout(this.redirectMyRefresh, 500)
          // this.pc9status = true
          // this.createSubmitted = false
          // }
        } catch (ex) {
          // We can ignore the error if they added one they already have
          // on their list.
          if (ex.message !== '400') {
            throw ex
          }
        }
      },
      async delete(assortId) {
        // {{BaseURL}}/api/assortment/{{assortmentId}}?shouldHardDelete=true
        // this.isSaving = true
        // store.addFavoriteSeason.lastSeasonId = false
        store.assortUser.displayMetaData = false
        console.log('DELETE CALLED')
        console.log(assortId)
        try {
          const url = `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/assortment/${assortId}`
          const params = { shouldHardDelete: true }
          await store.api.delete({
            url,
            params,
          })
          console.log('deleted')

          store.addGlobalAssortment.isDeleting = false
          // this.clearMenu()
          store.assort.refresh()
          store.applications.refresh()
          setTimeout(this.clearDeleteNotice, 1500)
        } catch (ex) {
          // We can ignore the error if they added one they already have
          // on their list.
          if (ex.message !== '400') {
            throw ex
          }
        }
      },
      async unlock(assortId) {
        // this.loading = true
        console.log('Unlocking')
        this.pc9statusName = this.name
        const localLocked = false
        try {
          const result = await store.api.put({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/assortment/${assortId}`,
            body: {
              isLocked: localLocked,
            },
          })
          // localStorage.setItem('year', this.year)
          // localStorage.setItem('brand', this.brand)
          // localStorage.setItem('season', this.season)
          console.log('UNLOCKED RESULT')
          console.log(result)

          // this.pc9status = true
          // this.createdAssortID = result.assortmentId
          this.clearEditRows()
          this.loading = false
          this.pc9rejects = []
          this.pc9success = []
          this.pc9existing = []
          store.assort.refresh()
          store.applications.refresh()
          this.pc9status = false
          this.createSubmitted = false
          this.editMode = false
          store.applications.componentKey += 1
          store.searchAssortments.componentKey += 1
          store.assort.componentKey += 1
          this.gridKey += 1

          this.unlockRedirect()
          // this.redirectMyRefresh()
        } catch (ex) {
          // We can ignore the error if they added one they already have
          // on their list.
          if (ex.message !== '400') {
            throw ex
          }
        }
      },
      unlockRedirect() {
        switch (this.store.router.path) {
          case '/assort/global':
            store.router.push({
              name: 'global',
            })
            return true
          case '/assort/region':
            store.router.push({
              name: 'assortregion',
            })
            return true
          case '/assort/affiliate':
            store.router.push({
              name: 'affiliateseason',
            })
            return true
          case '/assort/my':
            store.router.push({
              name: 'my',
            })
            return true
          case '/search':
            store.router.push({
              name: 'global',
            })
            return true
          default:
            store.router.push({
              name: 'global',
            })
            return true
        }
      },
      clearDeleteNotice() {
        this.selectedRows = []
        this.editRows = []
        store.addGlobalAssortment.pc9status = false
        store.addGlobalAssortment.createSubmitted = false
        store.assortUser.displayMetaData = false
        store.addGlobalAssortment.deleteSubmitted = false
        store.addGlobalAssortment.isDeleting = false
        store.addGlobalAssortment.carryOverComplete = false
        store.addGlobalAssortment.carryOverSubmitted = false
        this.selectedAssortmentsName = []
        this.redirectMyRefresh()
      },
      clearMenu() {
        store.addGlobalAssortment.selectedRows = []
        store.addGlobalAssortment.editRows = []
        store.assortUser.displayMetaData = false
        store.addGlobalAssortment.createSubmitted = false
        store.addGlobalAssortment.selectedAssortmentsName = []
      },
      clearEditRows() {
        this.selectedRows = this.selectedRows.filter(
          code => code !== this.preSelectedAssortId,
        )
        this.editRows = this.editRows.filter(
          row => row.assortmentId !== this.preSelectedAssortId,
        )
      },
      redirect() {
        this.clearDeleteNotice()
        window.location.href = '/assort/global/'
      },
      redirectMy() {
        // console.log(store.router)

        if (this.addMode) {
          if (store.assortUser.currentVisibility === 'personal') {
            return (window.location.href = '/assort/my')
          }
          return (window.location.href = '/assort/global')
        } else {
          if (this.visibility === 'global') {
            if (store.router.path === '/assort/global') {
              return true
            } else {
              return (window.location.href = '/assort/global')
            }
          } else {
            if (store.router.path === '/assort/my') {
              return true
            } else {
              return (window.location.href = '/assort/my')
            }
          }
        }
      },
      redirectMyCopy() {
        window.location.href = '/assort/my'
      },
      // redirectMyRefresh() {
      //   if (store.router.path === '/assort/my') {
      //     return (window.location.href = '/assort/my')
      //   }

      //   if (store.router.path === '/assort/global') {
      //     return (window.location.href = '/assort/global')
      //   }

      //   return true
      // },
      redirectMyRefresh() {
        if (store.router.path === '/assort/my') {
          return (window.location.href = '/assort/my')
        }

        if (store.router.path === '/assort/global') {
          // this.store.addGlobalAssortment.visibility === 'global'
          // this.store.addGlobalAssortment.assortType === 'global'
          return (window.location.href = '/assort/global')
        }
        if (
          store.router.path === '/assort/region' ||
          store.addGlobalAssortment.assortType === 'region'
        ) {
          return (window.location.href = '/assort/region')
        }
        if (
          store.router.path === '/assort/affiliate' ||
          store.addGlobalAssortment.assortType === 'affiliate'
        ) {
          return (window.location.href = '/assort/affiliate')
        }

        return window.location.reload()
      },
      reset() {
        // this.brand = ''
        this.error = false
        this.name = ''
        // this.season = ''
        this.pc9input = ''
        this.pc9array = []
        // this.year = ''
        this.errorName = false
        this.errorSeason = false
        this.errorBrand = false
        this.errorYear = false
        this.errorPC9 = false
      },
      arryToObj() {
        const res = []
        this.pc9array.forEach(function (val) {
          res.push({ colorwayCode: val })
        })
        return res
      },
      refreshRegions() {
        console.log('refresh?')
        this.$asyncComputed.assortmentRegions.update()
      },
      refreshAffiliates() {
        this.$asyncComputed.assortmentAffiliates.update()
      },
      async doCompare() {
        const result = await store.api.post({
          url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/assortment/${this.selectedRows[0]}/compare`,
          body: this.selectedRows,
        })
        const names = []
        this.selectedAssortmentsName.forEach(element => {
          names.push(element.name)
        })

        this.selectedGroup = this.selectedRows
        var filter = { results: [], toggle: [] }
        var products = []
        result.results.forEach(res => {
          products = {
            assortments: [],
          }
          products['name'] = res.colorwayName
          products['assortmentsnames'] = names

          var innerState = []

          this.selectedGroup.forEach(group => {
            if (res.containedIn.includes(group)) {
              innerState.push('<img src = "/images/compare-tick.svg"/>')
            } else innerState.push('<img src = "/images/compare-X.svg"/>')
          })

          products.assortments.push(innerState)
          filter.results.push(products)
          if (!innerState.every((val, i, arr) => val === arr[0])) {
            filter.toggle.push(products)
          }
        })
        return filter ?? []
      },
    },
  })
}

export default addGlobalAssortment
