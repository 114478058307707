class FileLoader {
  constructor({ cache, headers = {} } = {}) {
    this.cache = cache
    this.headers = headers
  }

  async load({ url, addToCache = false, skipCache = false }) {
    const request = this.createRequest({
      url,
    })

    if (!skipCache) {
      const cached = await this.cache.match(request)
      if (cached) {
        return cached
      }
    }

    const response = await fetch(request)
    if (addToCache && response.status < 300) {
      const cachedResponse = response.clone()
      await this.cache.put(request, cachedResponse)
    }
    return response
  }

  createRequest({ url }) {
    // console.log('create request')
    // console.log(this.headers.Authorization)
    // console.log('HEADERS HEADERS')
    const authHeader = this.headers.Authorization
    // console.log(this.headers)
    if (authHeader === 'Bearer null') {
      console.log('bad headers, lets refresh token')
      this.store.auth.refreshToken()
      /*
      if (window.location.pathname === '/assort/my') {
        return (window.location.href = '/assort/my')
      }

      if (window.location.pathname === '/assort/global') {
        return (window.location.href = '/assort/global')
      } else {
        this.store.auth.refreshToken()
      }
      */
    }
    if (authHeader.length < 100) {
      // if (this.store.auth.accessToken === undefined) {
      //   console.log('undefined')
      //   return document.location.reload(true)
      // }
      this.headers.Authorization = `Bearer ${this.store.auth.idToken}`
    }

    const request = new Request(url, {
      headers: new Headers({
        ...this.headers,
      }),
    })
    return request
  }
}

export default FileLoader
