<template>
  <div class="mt-24 ml-8 userGrid-wrapper">
    <p class="cursor-pointer -mt-6 mb-6 text-3xl" @click="appPage">
      <b
        >{{ store.user.appName.split('RVT')[0] + ' RVT' }}
        <span v-if="!store.user.userData"> > Add Vendor User </span>
        <span v-else> > Vendor Details</span>
      </b>
    </p>
    <div class="mb-8 flex flex-end justify-end button-top">
      <button class="CTAButton bg-transparent mr-2 mt-4" @click="valueNotNull">
        Save Changes
      </button>
    </div>
    <div class="splitter">
      <ul class="nav">
        <li
          :class="{ active: page === 'vendorDetails' }"
          @click="vendorDetails"
        >
          Vendor Details
        </li>
        <li :class="appDis" @click="applications">Applications</li>
        <!-- <li @click="urlUser">
          <router-link :to="url"> LS&amp;Co Internal Users </router-link>
        </li>
        <li @click="urlVendor">
          <router-link :to="url"> Vendor Users </router-link>
        </li> -->
      </ul>
      <p v-if="store.user.userData" class="mb-8 mr-8">
        {{ store.user.userData }}
      </p>
    </div>
    <div v-if="page === 'vendorDetails'">
      <VendorDetails :save="save" />
    </div>
    <div v-else>
      <VendorApplication />
    </div>
  </div>
</template>

<script>
import VendorDetails from './VendorDetails.vue'
import VendorApplication from './VendorApplication.vue'
export default {
  name: 'AddUser',
  components: {
    VendorDetails,
    VendorApplication,
  },
  inject: ['store'],
  data() {
    return {
      page: 'vendorDetails',
      save: false,
    }
  },
  computed: {
    appDis() {
      if (!this.store.user.userData) {
        return 'disabled'
      }
      if (this.page !== 'vendorDetails') {
        return 'active'
      }
      return ''
    },
  },
  methods: {
    valueNotNull() {
      this.store.user.save = true
    },
    vendorDetails() {
      this.page = 'vendorDetails'
    },
    applications() {
      this.page = 'vendorApp'
    },
    appPage() {
      this.store.user.condition = 'global'
    },
  },
}
</script>
<style scoped>
.active {
  color: #c41230;
  font-weight: bold;
  border-bottom: 2px solid #c41230;
}
.disabled {
  color: gray;
  pointer-events: none;
  cursor: default;
}
</style>
