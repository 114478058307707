<template>
  <header class="Header">
    <div class="logoBox">
      <img src="/images/levilogo.png" />
    </div>
    <!-- <h4
      v-if="store.assort.enableBack"
      class="items-center justify-center mt-4"
      style="color: blue"
      @click="goHome"
    >
      Back
    </h4> -->
    <!-- <div class="logo"><FooterLogos /></div> -->
    <h1 class="title pl-8 pt-32">User RVT</h1>
    <!-- <div><CardGrid /></div> -->
    <ul class="sideNav">
      <div class="pt-16">
        <a href="https://dashboard.userrvt.io/" target="_blank">
          <u>Dashboard</u></a
        >
      </div>
      <div class="listOfApp">
        <li v-for="{ name } in appNames" :key="name" @click="appName(name)">
          <router-link
            v-if="name == 'BOM'"
            :to="'/userRVT/applications/' + name"
            >{{ name.split('RVT')[0] }}</router-link
          >
          <router-link
            v-else-if="name == 'ProductGap'"
            :to="'/userRVT/applications/' + name"
            >Product Gap</router-link
          >
          <router-link v-else :to="'/userRVT/applications/' + name">{{
            name.split('RVT')[0] + ' RVT'
          }}</router-link>
        </li>
      </div>
      <!-- <li @click="dev">
        <router-link to="/userRVT/applications/DevRVT">Dev RVT</router-link>
      </li>
      <li @click="nest">
        <router-link to="/userRVT/applications/NestRVT">Nest RVT</router-link>
      </li>
      <li @click="view">
        <router-link to="/userRVT/applications/ViewRVT">View RVT</router-link>
      </li> -->
    </ul>
    <div class="splitter">
      <div class="cursor-pointer prefs" @click="emailOpen">
        <HelpModal ref="modal" />
        <b> <u> HELP </u> </b>
      </div>
    </div>
    <div class="splitter signOut">
      <div class="cursor-pointer prefs" @click="signout">
        <b> <u> SIGN OUT </u> </b>
      </div>
    </div>
  </header>
</template>

<script>
// import SearchIcon from './icons/SearchIcon.vue'
// import SettingsMenu from './SettingsMenu.vue'
// import FooterLogos from './svg/FooterLogos.vue'
// import CardGrid from '../components/Assort/CardGrid.vue'
import HelpModal from '../common/components/HelpModal.vue'

export default {
  components: {
    // SearchIcon,
    // SettingsMenu,
    // CardGrid,
    // FooterLogos,
    HelpModal,
  },
  inject: ['store'],
  data() {
    return {}
  },
  computed: {
    appNames() {
      return this.store.assort.allApnames
    },
    componentKey() {
      return this.store.assort.componentKey
    },
    viewOnly() {
      return this.store.rbac.hasPermission('view', 'devBoms')
    },
  },
  mounted() {
    this.store.user.current
    this.store.assort.fetchApplications().then(apps => {
      if (apps.length > 0) {
        this.store.user.appName = apps[0].displayName
        this.appName(this.store.user.appName)
        this.$router.push({
          path: '/userRVT/applications/' + this.store.user.appName,
        })
      }
    })
  },
  methods: {
    appName(name) {
      // this.store.assort.refreshUsers()
      this.store.assort.refreshVendors()
      //  localStorage.setItem('currAppName', name)
      this.store.user.appName = name
      this.store.user.save = false
      this.store.assort.applicationName = ''
      this.store.user.condition = 'global'
    },
    getClass(which) {
      if (this.$route.path === which) {
        return 'active'
      }
      if (
        this.$route.path.startsWith(which) &&
        this.$route.path !== '/assort/global/season'
      ) {
        return 'active'
      }
      return ''
    },
    goHome() {
      return (window.location.href = `/userRVT/applications`)
    },
    async signout() {
      await this.store.auth.signOut()
    },
    emailOpen() {
      this.$refs.modal.open()
    },
  },
}
</script>

<style lang="scss">
.title {
  font-size: 34px;
  font: bold;
}
.Header {
  padding-top: 3%;
  overflow: auto;
}
header.Header {
  background: #c21a31;
  color: #fff;
  position: relative;
  font-weight: 500;
  width: 20%;
  z-index: 111;
  padding-bottom: 6%;
  .LooksGridCard div {
    font-size: 18px;
    margin: 40px 15px;
  }
  .splitter {
    // @include centerColumn();
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    margin: 20px auto;
    padding: 0 20px;

    .nav {
      display: flex;
      // li:last-child {
      //   float: right;
      //   display: flex-end;
      // }
      li {
        margin-right: 1rem;
        &:first-child {
          a {
            padding-left: 0;
          }
        }

        a {
          padding: 0 10px;
          height: $headerHeight;
          display: flex;
          align-items: center;
          color: $gray-header-links;
          transition: $anim-trans-base;
          position: relative;
          font-size: 1.3rem;
          text-transform: uppercase;
          letter-spacing: 0.05rem;

          &:hover {
            color: $white;

            &::after {
              width: 100%;
              left: 0;
              opacity: 0.15;
            }

            svg path {
              stroke: $white;
            }
          }

          &::after {
            content: '';
            background: $gray-lines;
            position: absolute;
            left: 50%;
            bottom: 0;
            height: 1px;
            width: 0;
            transition: all 0.25s;
            opacity: 0;
          }

          svg {
            margin-right: 0.5rem;
            width: 16px;
            height: 16px;
            path {
              transition: $anim-trans-base;
            }
          }
        }
      }
    }

    .docPageIcon {
      height: 16px;
      width: 16px;

      path {
        stroke: $gray-header-links;
      }
    }

    .prefs {
      margin-left: 10%;
      position: relative;
    }
  }
}

// my Code
.logo {
  margin: 20px 0 10px 10px;
}
.sideNav {
  font-size: 18px;

  a {
    color: #fff;
    padding: 0 0 0 15px;
    border-left: 7px solid #c21a31;
    height: 2em;
    display: inline-block;
  }

  li {
    margin: 50px 0;
  }
}
.router-link-active {
  border-left: 7px solid #fff !important;
  height: 2em;
  display: inline-block;
}
.logoBox {
  width: 30%;
  position: absolute;
}
.listOfApp {
  height: 58vh;
  overflow: auto;
}
.signOut {
  position: absolute;
  top: 90vh;
}
// ::-webkit-scrollbar {
//   color: red;
// }
</style>
