<template>
  <div class="Popups">
    <div v-if="store.user.message || store.user.errormsg">
      <div class="popupMessage">
        <span class="messageContent">
          {{ store.user.message }}
        </span>
        <span class="error">
          {{ store.user.errormsg }}
        </span>
        <span class="closeButton" @click="onCloseClick()">
          <h1>x</h1>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  inject: ['store'],
  data() {
    return {}
  },
  computed: {
    // messages () {
    //   return this.store.popups.messages
    // }
  },
  mounted() {
    setTimeout(() => {
      this.onCloseClick()
    }, 5000)
  },
  methods: {
    onCloseClick() {
      this.store.user.message = null
      this.store.user.errormsg = null
    },
    onLinkClick(id) {
      this.store.popups.remove(id)
    },
  },
}
</script>

<style lang="scss">
.Popups {
  position: absolute;
  bottom: 64px;
  left: 16px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  overflow: hidden;
  max-width: calc(100% - 32px);
  z-index: 1000;

  .popupMessage {
    display: flex;
    text-align: center;
    font-size: 16px;
    color: white;
    background-color: $gray;
    padding: 16px 24px;
    border-radius: 5px;
    box-shadow: 3px 3px 3px $gray-card-border;
    margin-top: 16px;
    animation: 500ms SlideIn;

    .messageContent {
      display: flex;
      align-items: center;
      margin-right: 16px;
    }

    .error {
      display: flex;
      align-items: center;
      margin-right: 16px;
      color: lighten($red, 15%);
    }

    .status {
      display: flex;
      align-items: center;
      margin-right: 16px;
    }

    .link {
      display: flex;
      align-items: center;
      margin-right: 16px;
    }

    .closeButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      flex-shrink: 0;
      transition: background-color 500ms ease;
      cursor: pointer;

      &:hover {
        background-color: $gray-sec;
      }

      svg {
        path {
          stroke: white;
        }
      }
    }
  }
}
</style>
