<template>
  <div
    v-if="isReady"
    ref="gridBox"
    class="gridlist grid-Scroll grid-ScrollList"
  >
    <DeleteUser ref="delUser">
      Are you sure you want to delete this User from this application?
    </DeleteUser>
    <ag-grid-vue
      :style="{ width, height }"
      class="ag-theme-fresh"
      :row-height="50"
      :default-col-def="defaultColDef"
      :column-defs="columnDefs"
      :pagination="true"
      :pagination-page-size="paginationPageSize"
      :row-data="rowData"
      :row-selection="'multiple'"
      :modules="modules"
      :row-class-rules="rowClassRules"
      @row-selected="onRowSelection"
      @cell-clicked="onCellClick"
      @grid-ready="onGridReady"
    />
  </div>
  <Spinner v-else />
</template>
<script>
import 'ag-grid-community/dist/styles/ag-theme-fresh.css'
import { AgGridVue } from '@ag-grid-community/vue'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { RichSelectModule } from '@ag-grid-enterprise/rich-select'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import Spinner from '../components/Spinner.vue'
import DeleteUser from '../components/Modal/DeleteUser.vue'

export default {
  components: {
    AgGridVue,
    Spinner,
    DeleteUser,
  },
  inject: ['store'],
  props: {
    response: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rowData: [],
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      width: '100%',
      height: '470px',
      rowSelection: 'multiple',
      rowClassRules: null,
      modules: [
        SetFilterModule,
        ClientSideRowModelModule,
        MenuModule,
        ColumnsToolPanelModule,
        RichSelectModule,
        AllModules,
      ],
      title: '',
    }
  },
  computed: {
    isAppName() {
      return this.store.user.appName
    },
    isReady() {
      return this.store.user.listOfUsers
    },
  },
  watch: {
    isReady(newVal, oldVal) {
      if (newVal) {
        this.rowData = this.store.user.listOfUsers
      }
    },
    isAppName(newVal, oldVal) {
      if (newVal) {
        this.rowData = this.store.user.listOfUsers
      }
    },
    response(newVal, oldVal) {
      if (newVal) {
        this.rowData = this.response
      }
    },
  },
  beforeMount() {
    this.gridOptions = {
      // floatingFiltersHeight: 40,
      // headerHeight: 32,
      // suppressScrollOnNewData: true,
    }
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      cellStyle: {
        'background-color': '#E5E5E5',
        transform: 'translateY(0px)',
      },
      // filter: 'agTextColumnFilter',
      // floatingFilter: true,
    }
    this.paginationPageSize = 7
    this.paginationNumberFormatter = params => {
      return '[' + params.value.toLocaleString() + ']'
    }
    this.rowData = this.store.user.listOfUsers
    this.columnDefs = [
      {
        headerName: '',
        field: '',
        width: 190,
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      {
        headerName: 'Name',
        field: 'fullName',
        width: 190,
      },
      {
        headerName: 'User Id',
        field: 'userId',
        width: 190,
      },
      {
        headerName: 'Email',
        field: 'email',
        width: 190,
      },
      {
        headerName: 'Parent Group',
        field: '',
        width: 190,
      },
      {
        headerName: 'Group',
        field: '',
        width: 190,
      },
      {
        headerName: '',
        field: '',
        cellClass: 'cellAlign',
        cellRenderer: this.createElement.bind(this),
        width: 35,
        filter: false,
      },
      {
        headerName: '',
        field: '',
        cellClass: 'cellAlign',
        cellRenderer: this.deleteUser.bind(this),
        minWidth: 80,
        maxWidth: 80,
        filter: false,
      },
    ]
  },
  mounted() {
    this.rowData = this.store.user.listOfUsers
  },
  methods: {
    onGridReady(params) {
      setTimeout(() => {
        params.api.redrawRows()
      }, 100)
    },
    onRowSelection(event) {
      if (event.node.selected) {
        this.store.user.grpUsers.push(event.data.userId)
      } else {
        const index = this.store.user.grpUsers.indexOf(event.data.userId)
        this.store.user.grpUsers.splice(index, 1)
      }
    },
    getRowHeight(params) {
      return params.data.rowHeight
    },
    onCellClick(event) {
      if (event.column.colDef.headerName === 'Full Name') {
        this.title = event.value
        this.openModal()
      }
    },
    createElement(data) {
      var link = document.createElement('a')
      link.innerHTML = '<i class="fa fa-pencil" ></i>'
      link.addEventListener('click', () => {
        // this.store.user.condition = 'newUser'
        // this.store.user.userData = data.data.userId
      })
      return link
    },
    deleteUser(data) {
      var link = document.createElement('a')
      link.innerHTML = '<i class="fa fa-trash"  ></i>'
      link.addEventListener('click', () => {
        this.$refs.delUser.open(data)

        // this.store.user.condition = 'newUser'
        // this.store.user.userData = data.data.userId
      })
      return link
    },
    status(event) {},
    openModal() {
      this.$refs.userModal.open()
    },
  },
}
</script>
<style lang="scss">
// .ag-header-cell-label,
// .ag-header-row,
// .ag-theme-fresh .ag-row-odd {
//   background-color: #fff;
//   background: none;
// }
.ag-body-viewport .ag-center-cols-container,
.ag-theme-fresh .ag-body-viewpor,
.ag-center-cols-viewport,
.ag-center-cols-clipper,
.ag-center-cols-clipper,
.ag-theme-fresh .ag-row-even,
.ag-theme-fresh .ag-paging-panel,
.ag-header-viewport,
.ag-header-container,
.ag-header-cell-label,
.ag-header-row {
  background-color: #e5e5e5 !important;
}
.ag-header-cell-label .ag-header-cell-text {
  color: #050505;
  font-size: 14px;
  font-weight: 700;
}
.ag-theme-fresh .ag-root-wrapper {
  border: none;
}
.ag-theme-fresh .ag-header-cell {
  border-right: none;
}
.ag-theme-fresh .ag-header-cell::after,
.ag-theme-fresh .ag-header-group-cell::after {
  content: none;
}
.ag-row-position-absolute {
  position: relative;
  margin: 20px 0;
}
#page-size {
  width: 60px;
}
ul.nav {
  border-bottom: 2px solid #d8d8d8;
  margin-top: 20px;
  margin-bottom: 40px;
}
ul.nav li {
  display: inline-block;

  a.router-link-active {
    color: #c21a31;
    border-left: 0 !important ;
    margin-right: 20px;
    border-bottom: 2px solid;
  }
}
a {
  color: #d8d8d8;
}
img {
  float: left;
  margin: 0 5px;
}
.ag-paging-row-summary-panel {
  position: absolute;
  left: 0;
}
.ag-paging-page-summary-panel .ag-paging-button button {
  position: relative;
}
.pages {
  width: 100%;
  background-color: #e5e5e5;
}
.button-top {
  position: fixed;
  top: 22px;
  right: 35px;
}
</style>
