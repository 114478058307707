var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"flex mb-5"},[_vm._m(1),_c('div',{staticClass:"ml-32"},[_c('div',{staticClass:"w-70 ml-10",on:{"change":_vm.parentType}},[_vm._m(2),_vm._m(3)])])]),(_vm.parentGroup === 'Yes')?_c('div',{staticClass:"flex mb-5"},[_vm._m(4),_c('input',{staticClass:"ml-40 inputText",attrs:{"type":"text"}}),_c('button',{staticClass:"CTAButton bg-transparent ml-12",on:{"click":_vm.addChildGroup}},[_vm._v(" Add Child Group ")])]):_c('div',{staticClass:"flex mb-5"},[_vm._m(5),_c('input',{staticClass:"ml-40 inputText",attrs:{"type":"text"}})]),_vm._m(6),_vm._m(7),_vm._m(8),(_vm.parentGroup === 'Yes')?_c('span',[_vm._m(9),_vm._m(10)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex mb-5"},[_c('label',{staticClass:"pt-5 pl-4 w-70"},[_c('b',[_vm._v("Group Name")])]),_c('input',{staticClass:"ml-40 inputText",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"pt-5 pl-4 w-70"},[_c('b',[_vm._v("Parent")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float-left"},[_c('input',{attrs:{"id":"yes","type":"radio","name":"grpType","value":"Yes","checked":""}}),_c('label',{attrs:{"for":"yes"}},[_vm._v("Yes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-20"},[_c('input',{attrs:{"id":"no","type":"radio","name":"grpType","value":"No"}}),_c('label',{attrs:{"for":"no"}},[_vm._v("No")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"pt-5 pl-4 w-70"},[_c('b',[_vm._v("Sub Groups")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"pt-5 pl-4 w-70"},[_c('b',[_vm._v("Parent Group")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex mb-5"},[_c('label',{staticClass:"pt-5 pl-4 w-70"},[_c('b',[_vm._v("Group Id")])]),_c('input',{staticClass:"ml-40 inputText",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex mb-5"},[_c('label',{staticClass:"pt-5 pl-4 w-70"},[_c('b',[_vm._v("Organization")])]),_c('select',{staticClass:"transparentSelect w-70",attrs:{"id":"org","name":"org"}},[_c('option',{staticClass:"trans",attrs:{"value":"Organization1"}},[_vm._v("Organization1")]),_c('option',{staticClass:"trans",attrs:{"value":"Organization2"}},[_vm._v("Organization2")]),_c('option',{staticClass:"trans",attrs:{"value":"Organization3"}},[_vm._v("Organization3")]),_c('option',{staticClass:"trans",attrs:{"value":"Organization4"}},[_vm._v("Organization4")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex mb-5"},[_c('label',{staticClass:"pt-5 pl-4 w-70"},[_c('b',[_vm._v("Application")])]),_c('input',{staticClass:"ml-40 inputText",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex mb-5"},[_c('label',{staticClass:"pt-5 pl-4 w-70"},[_c('b',[_vm._v("Admin Name")])]),_c('input',{staticClass:"ml-40 inputText",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex mb-5"},[_c('label',{staticClass:"pt-5 pl-4 w-70"},[_c('b',[_vm._v("Admin E-mail")])]),_c('input',{staticClass:"ml-40 inputText",attrs:{"type":"text"}})])
}]

export { render, staticRenderFns }