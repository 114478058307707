var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-24 userGrid-wrapper"},[_c('p',{staticClass:"cursor-pointer -mt-6 mb-6 text-3xl",on:{"click":_vm.appPage}},[_c('b',[_vm._v(_vm._s(_vm.store.user.appName))])]),_c('div',{staticClass:"splitter"},[_c('ul',{staticClass:"nav"},[_c('li',{class:{ active: _vm.show === 'lsco' },on:{"click":_vm.lsco}},[_vm._v(" LS&Co Internal Users ")]),_c('li',{class:{ active: _vm.show === 'vendor' },on:{"click":_vm.vendor}},[_vm._v(" Vendor Users ")]),(
          !(
            _vm.store.user.appName === 'NestRVT' ||
            _vm.store.user.appName === 'VendorRVT'
          )
        )?_c('li',{class:{ active: _vm.show === 'access' },on:{"click":_vm.access}},[_vm._v(" User Access Requests ")]):_vm._e(),(
          !(
            _vm.store.user.appName === 'NestRVT' ||
            _vm.store.user.appName === 'VendorRVT'
          )
        )?_c('li',{class:{ active: _vm.show === 'deny' },on:{"click":_vm.deny}},[_vm._v(" Denied Requests ")]):_vm._e(),(
          _vm.store.user.appName === 'NestRVT' ||
          _vm.store.user.appName === 'VendorRVT'
        )?_c('li',{class:{ active: _vm.show === 'access' },on:{"click":_vm.access}},[_vm._v(" Vendor Access Requests ")]):_vm._e(),(
          _vm.store.user.appName === 'NestRVT' ||
          _vm.store.user.appName === 'VendorRVT'
        )?_c('li',{class:{ active: _vm.show === 'deny' },on:{"click":_vm.deny}},[_vm._v(" Denied Requests ")]):_vm._e(),_c('li',{class:{ active: _vm.show === 'issues' },on:{"click":_vm.issues}},[_vm._v("Issues")]),(_vm.store.user.appName === 'BOM')?_c('li',{class:{ active: _vm.show === 'compare' },on:{"click":_vm.compare}},[_vm._v(" CDC Compare ")]):_vm._e()]),_c('div',{staticClass:"inlineSearchInput mb-8",class:{ active: _vm.isSearching }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],attrs:{"type":"text","placeholder":"Enter User Name or ID"},domProps:{"value":(_vm.searchTerm)},on:{"input":function($event){if($event.target.composing)return;_vm.searchTerm=$event.target.value}}})]),_c('div',{staticClass:"pt-3 flex pr-3",on:{"click":() => (_vm.isSearching = !_vm.isSearching)}},[_c('i',{staticClass:"fa fa-search mr-3"}),_c('p',{staticClass:"cursor-pointer mb-12"},[_vm._v("Search")])])]),(_vm.show === 'vendor')?_c('VendorUsers',{attrs:{"response":_vm.response}}):(_vm.show === 'lsco')?_c('LSCOUsers',{attrs:{"response":_vm.response}}):(_vm.show === 'access')?_c('AccessUsers',{attrs:{"response":_vm.response}}):(_vm.show === 'issues')?_c('Issues',{attrs:{"response":_vm.response}}):(_vm.show === 'compare' && _vm.store.user.appName === 'BOM')?_c('Compare',{attrs:{"response":_vm.response}}):_c('DeniedUsers',{attrs:{"response":_vm.response}}),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }