import addGlobalAssortment from './addGlobalAssortment.js'
import api from './api.js'
import assort from './assort.js'
import assortUser from './assortUser.js'
import assortments from './assortments.js'
import auth from './auth.js'
import config from './config.js'
import lines from './lines.js'
import router from './router.js'
import session from './session.js'
import user from './user.js'
import applications from './applications.js'
import contextMenu from './contextMenu.js'

import { brandToUrl } from '../util/valueHelpers'

class Store {
  constructor() {
    this.addGlobalAssortment = addGlobalAssortment(this)
    this.api = api(this)
    this.assort = assort(this)
    this.assortUser = assortUser(this)
    this.auth = auth(this)
    this.config = config(this)
    this.lines = lines(this)
    this.router = router(this)
    this.assortments = assortments(this)
    this.session = session(this)
    this.user = user(this)
    this.assetLoader = null
    this.applications = applications(this)
    this.currentBrand = localStorage.getItem('brand') || '483'
    this.currentSeason = localStorage.getItem('season') || 'S1'
    this.currentYear = localStorage.getItem('year') || '2021'
    this.currentBrandName = brandToUrl(this.currentBrand)
    this.lookupHub = localStorage.getItem('lookupHub') || 'global'
    this.currentAffiliate = ''
    this.currentRegion = 'global'
    this.currentGridView = 'grid'
    this.assetLoader = null
    this.fetchingAssortment = false
    this.adopted = localStorage.getItem('adopted') || 'Y'
    this.contextMenu = contextMenu(this)
  }

  init() {
    // this.assortments.currentAssortment = '483-S1-2021-663-674-global'
    this.auth.init()
    // this.createAssetLoader()
  }
}

export default Store
