import Vue from 'vue'

const assort = store => {
  return new Vue({
    data: {
      assortMetaData: {
        bottoms: 0,
        items: 0,
        mens: 0,
        tops: 0,
        womens: 0,
      },
      assortMetaDataLocal: {
        assort: '',
        bottoms: 0,
        items: 0,
        mens: 0,
        tops: 0,
        womens: 0,
      },
      assortMetaDataLocalArray: [],
      assortResults: [],
      brand: '',
      colorDominantFront: '',
      colorPaletteFront: [],
      colorPaletteMenuItem: false,
      componentKey: 0,
      currentAssortName: localStorage.getItem('currAssortName') || '',
      currentGridView: 'grid',
      currentStatus: [],
      currentVisibility: localStorage.getItem('currVisibility') || 'personal',
      displayMetaData: false,
      fromGridSelect: false,
      fromLanding: false,
      isReload: false,
      landing: false,
      landingAssortID: '',
      selectedSeason: '',
      attributes: [],
      isLocked: false,
      createdBy: '',
    },
    computed: {
      error() {
        return this.$asyncComputed.items.exception
      },
      isLoading() {
        const notStarted =
          this.items.length === 0 && this.$asyncComputed.items.success
        return notStarted || this.$asyncComputed.items.updating
      },
      isReady() {
        // return this.$asyncComputed.items.success && this.items.length >= 0
        if (this.isReload) {
          return false
        }
        if (
          this.$asyncComputed.items.updating &&
          this.$asyncComputed.dataFields.updating
        ) {
          return false
        }
        if (this.$asyncComputed.items && this.$asyncComputed.dataFields) {
          if (this.items.length > 0) {
            return true
          }
        } else {
          if (this.items.length > 0 && this.dataFields) {
            return true
          } else {
            return false
          }
        }
        return false
      },
      isEmpty() {
        return this.items.length === 0
      },
      isUnauthorized() {
        return this.error && this.error.status === 401
      },
      season() {
        return store.addGlobalAssortment.season
      },
      year() {
        return store.addGlobalAssortment.year
      },
      isEditable() {
        return this.dataFields && this.dataFields.isEditableByUser
      },
      currentAssortment() {
        if (this.landing) {
          return this.landingAssortID
        } else {
          if (typeof store.router.params.assortmentId === 'undefined') {
            return '4cc39977-fde9-4e8c-903a-049aa0fc0bd1'
          }
          return store.router.params.assortmentId
        }
      },
    },
    asyncComputed: {
      dataFields: {
        lazy: true,
        default: undefined,
        async get() {
          store.fetchingAssortment = true
          const response = await store.api.get({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/assortment/${this.currentAssortment}`,
          })
          if (response.status === 404) {
            const error = new Error('Not found')
            error.status = 404
            throw error
          }
          if (response.message === 'Unauthorized') {
            const error = new Error('Unauthorized')
            error.status = 401
            throw error
          }
          store.fetchingAssortment = false

          this.isLocked = response.isLocked
          this.createdBy = response.createdBy
          return response
        },
      },
      items: {
        default: [],
        lazy: true,
        async get() {
          store.fetchingAssortment = true
          const response = await store.api.get({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/assortment/${this.currentAssortment}/all`,
          })

          if (response.status === 404) {
            const error = new Error('Not found')
            error.status = 404
            throw error
          }
          if (response.message === 'Unauthorized') {
            const error = new Error('Unauthorized')
            error.status = 401
            throw error
          }
          this.assortResults = response.pc9s
          // const pathArray = ['/assort/global', '/assort/my']
          // if (pathArray.includes(store.router.path) && this.fromLanding) {
          //   this.calcMetaData(response)
          // }

          this.isReload = false
          store.fetchingAssortment = true
          // let sorted = []

          if (response.pc9s[0]?.sortingNumber !== undefined) {
            response.pc9s.sort((a, b) => {
              return a.sortingNumber - b.sortingNumber
            })
          } else {
            response.pc9s.sort((a, b) => {
              a.PC9.lscoColorwayCode - b.PC9.lscoColorwayCode
            })
          }

          localStorage.setItem(
            `cacheAssort${this.currentAssortment}`,
            JSON.stringify(response.pc9s),
          )
          this.attributes = [...response.attributes]
          return [...response.pc9s]
        },
      },
    },
    methods: {
      async refresh() {
        // eslint-disable-next-line no-unused-vars
        // this.currentStatus = currentStatus
        this.$asyncComputed.items.update()
      },
      applyUpdates(looks) {
        this.items.map(item => {
          looks.map(look => {
            if (look.PC9.lscoColorwayCode === item.PC9.lscoColorwayCode) {
              item.sortingNumber = look.sortingNumber
              item.deleted = look.isDeleted
              item.posX = look.posX
              item.posY = look.posY
              item.posZ = look.posZ
            }
          })
        })
      },
      setCurrentPC9(pc9Data) {
        this.pc9Data = pc9Data
      },
      calcMetaData(response) {
        const resultsArray = response
        const mensArray = resultsArray.filter(item => {
          return item.PC5.lscoGender === '663'
        })
        const womensArray = resultsArray.filter(item => {
          return item.PC5.lscoGender === '664'
        })
        const topsArray = resultsArray.filter(item => {
          return item.PC5.lscoProductCategory === '673'
        })
        const bottomsArray = resultsArray.filter(item => {
          return item.PC5.lscoProductCategory === '674'
        })
        this.assortMetaDataLocal.items = resultsArray.length
        this.assortMetaDataLocal.mens = mensArray.length
        this.assortMetaDataLocal.womens = womensArray.length
        this.assortMetaDataLocal.tops = topsArray.length
        this.assortMetaDataLocal.bottoms = bottomsArray.length
        // this.assortMetaDataLocal.assort = this.currentAssortment
        const localObj = {
          items: resultsArray.length,
          mens: mensArray.length,
          womens: womensArray.length,
          tops: topsArray.length,
          bottoms: bottomsArray.length,
          // assort: this.currentAssortment,
        }

        // const found = store.addGlobalAssortment.assortMetaDataArray.some(
        //   el => el.assort === this.currentAssortment,
        // )
        // if (!found) {
        //   store.addGlobalAssortment.assortMetaDataArray.push(localObj)
        // }

        this.assortMetaData = localObj
        this.componentKey += 1
        this.displayMetaData = true
        store.addGlobalAssortment.pc9status = true

        // this.cumulativeMetaData()

        // return this.assortMetaData
      },
      appendObjTo(thatArray, newObj) {
        const frozenObj = Object.freeze(newObj)
        return Object.freeze(thatArray.concat(frozenObj))
      },

      cumulativeMetaData() {
        // const localArr = this.assortMetaDataLocalArray
        // const storeArr = store.addGlobalAssortment.assortMetaDataArray
        // const arr3 = [...this.currentStatus, ...localArr]
        // store.addGlobalAssortment.assortMetaDataArray = arr3

        // store
        if (
          Array.isArray(store.addGlobalAssortment.assortMetaDataArray) &&
          store.addGlobalAssortment.assortMetaDataArray.length >= 1
        ) {
          const result = store.addGlobalAssortment.assortMetaDataArray.reduce(
            function (previousValue, currentValue) {
              return {
                items: previousValue.items + currentValue.items,
                mens: previousValue.mens + currentValue.mens,
                womens: previousValue.womens + currentValue.womens,
                tops: previousValue.tops + currentValue.tops,
                bottoms: previousValue.bottoms + currentValue.bottoms,
              }
            },
          )

          this.assortMetaData = result
          this.displayMetaData = true
          store.addGlobalAssortment.pc9status = true
        }
        // this.componentKey += 1
        // return result
      },
      async saveOrder(assortment) {
        this.isReload = true
        this.componentKey += 1
        try {
          await store.api
            .put({
              url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/assortment/${assortment}/pc9`,
              body: [...this.items],
            })
            .then(response => {
              this.refresh()
            })
        } catch (ex) {
          // We can ignore the error if they added one they already have
          // on their list.
          if (ex.message !== '400') {
            throw ex
          }
        }
      },
    },
  })
}

export default assort
