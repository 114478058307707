/* eslint-disable vue/no-dupe-keys */
import storage from 'store2'
import Vue from 'vue'

const accessTokenKey = 'accessToken'
const idTokenKey = 'idToken'
const nextPathKey = 'nextPath'
const pkceStateKey = 'pkceState'
const pkceCodeVerifierKey = 'pkceCodeVerifier'
const seasonIdKey = 'seasonId'

const session = store => {
  return new Vue({
    data() {
      return {
        get accessToken() {
          return storage.session.get(accessTokenKey)
        },
        set accessToken(value) {
          if (!value || !value.length) {
            storage.session.remove(accessTokenKey)
          } else {
            storage.session.set(accessTokenKey, value)
          }
        },
        get idToken() {
          return storage.session.get(idTokenKey)
        },
        set idToken(value) {
          if (!value || !value.length) {
            storage.session.remove(idTokenKey)
          } else {
            storage.session.set(idTokenKey, value)
          }
        },
        get nextPath() {
          return storage.session.get(nextPathKey)
        },
        set nextPath(value) {
          if (!value || !value.length) {
            storage.session.remove(nextPathKey)
          } else {
            storage.session.set(nextPathKey, value)
          }
        },
        get pkceCodeVerifier() {
          return storage.session.get(pkceCodeVerifierKey)
        },
        set pkceCodeVerifier(value) {
          if (!value || !value.length) {
            storage.session.remove(pkceCodeVerifierKey)
          } else {
            storage.session.set(pkceCodeVerifierKey, value)
          }
        },
        get pkceState() {
          return storage.session.get(pkceStateKey)
        },
        set pkceState(value) {
          if (!value || !value.length) {
            storage.session.remove(pkceStateKey)
          } else {
            storage.session.set(pkceStateKey, value)
          }
        },
        get seasonId() {
          return storage.get(seasonIdKey)
        },
        set seasonId(value) {
          storage.set(seasonIdKey, value)
        },
      }
    },
  })
}

export default session
