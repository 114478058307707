// import Dashboard from '../views/Dashboard.vue'
import deepEqual from 'deep-equal'
import queryString from 'query-string'
import Router from 'vue-router'
import SignIn from '../views/SignIn.vue'
import SignInRedirect from '../views/SignInRedirect.vue'
// import LSCOUsers from '../views/user/LSCOUsers.vue'
// import VendorUsers from '../views/user/VendorUsers.vue'
import Vue from 'vue'

Vue.use(Router)

const screenHeight = window.screen.height
const screenWidth = window.screen.width
const isIpad =
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) ||
  navigator.platform === 'iPad'
const isDevUserRvt = window.location.href.indexOf('dev.userrvt') > 0

console.log(' CHECK IPAD AND DEV')
console.log('isIpad', isIpad)
console.log('isDevUserRvt', isDevUserRvt)
console.log(screenHeight)
console.log(screenWidth)
const createRouter = store => {
  const authorize = (to, from, next) => {
    if (isDevUserRvt) {
      if (isIpad) {
        if (store.auth.isSignedIn) {
          next()
        } else {
          const nextPath = encodeURIComponent(to.fullPath)
          const signInUrl = `/sign-in#nextPath=${nextPath}`
          next(signInUrl)
        }
      } else {
        const denyView = `/ipad`
        next(denyView)
      }
    } else {
      if (store.auth.isSignedIn) {
        next()
      } else {
        const nextPath = encodeURIComponent(to.fullPath)
        const signInUrl = `/sign-in#nextPath=${nextPath}`
        next(signInUrl)
      }
    }
  }
  const router = new Router({
    mode: 'history',
    base: store.config.baseUrl,
    routes: [
      {
        path: '/sign-in',
        name: 'sign-in',
        component: SignIn,
      },
      {
        path: '/',
        redirect: '/sign-in',
      },
      {
        path: '/auth-callback',
        component: SignInRedirect,
      },
      {
        path: '/ipad',
        name: 'ipad',
        component: SignInRedirect,
      },
      {
        path: '/addNewApp',
        name: 'addNewApp',
        beforeEnter: authorize,
        component: () => import('../views/AddApplication.vue'),
      },
      // {
      //   path: '/userRVT/applications',
      //   name: 'applications',
      //   beforeEnter: authorize,
      //   component: () => import('../views/ApplicationsLanding.vue'),
      // },
      // {
      //   path: `/userRVT/applications/${localStorage.getItem('currAppName')}`,
      //   beforeEnter: authorize,
      //   component: () => import('../views/UsersGrid.vue'),
      //   children: [

      //   ],
      // },

      // {
      //   path: '/',
      //   redirect: '/userRVT/applications',
      // },
      // {
      //   path: '/userRVT',
      //   redirect: '/userRVT/applications',
      // },
    ],
  })

  router.updateQuery = (newValues, options = { useReplace: false }) => {
    const { hash, path, query } = router.history.current

    const newQuery = { ...query, ...newValues }

    if (options.useReplace) {
      router.replace({
        hash,
        path,
        query: newQuery,
      })
    } else {
      router.push({
        hash,
        path,
        query: newQuery,
      })
    }
  }

  router.updateHash = (newValues, options = { useReplace: false }) => {
    const { hash, path, query } = router.history.current

    const hashStr = queryString.parse(hash)
    const newHash = queryString.stringify({ ...hashStr, ...newValues })

    if (newHash === hash) {
      // VueRouter throws an error if we set the same hash
      return
    }

    if (options.useReplace) {
      router.replace({
        hash: newHash,
        path,
        query,
      })
    } else {
      router.push({
        hash: newHash,
        path,
        query,
      })
    }
  }

  const routerProxy = new Vue({
    data: {
      hashString: {},
      instance: router,
      params: {},
      path: '',
      query: {},
    },
    computed: {
      fullPath() {
        return `${this.path}${this.queryStringWithQuestion}${this.hashStringWithHash}`
      },
      hash() {
        return queryString.parse(this.hashString)
      },
      hashStringWithHash() {
        if (this.hashString.length) {
          return `#${this.hashString}`
        }
        return ''
      },
      queryString() {
        return queryString.stringify(this.query)
      },
      queryStringWithQuestion() {
        if (this.queryString.length) {
          return `?${this.queryString}`
        }
        return ''
      },
    },
    methods: {
      clearHash(names, options) {
        if (!Array.isArray(names)) {
          names = [names]
        }
        const params = names.reduce((acc, name) => {
          return { ...acc, [name]: undefined }
        }, {})
        this.setHash(params, options)
      },
      init() {
        routerProxy.path = router.history.current.path
        routerProxy.hashString = router.history.current.hash
        routerProxy.query = router.history.current.query
        routerProxy.params = router.history.current.params

        router.afterEach((to, from) => {
          if (to.hash !== from.hash) {
            routerProxy.hashString = to.hash
          }

          if (!deepEqual(to.query, from.query)) {
            routerProxy.query = to.query
          }

          if (!deepEqual(to.params, from.params)) {
            routerProxy.params = to.params
          }

          if (to.path !== from.path) {
            routerProxy.path = to.path
          }
        })
      },
      push(params) {
        return router.push(params)
      },
      replace(params) {
        return router.replace(params)
      },
      setHash(obj, options) {
        router.updateHash(obj, options)
      },
      setQuery(obj) {
        router.updateQuery(obj)
      },
    },
  })

  routerProxy.init()

  return routerProxy
}

export default createRouter
