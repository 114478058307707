const cacheName = 'ViewRivetAssetCache'

class StorageCache {
  async init() {
    this.instance = await window.caches.open(cacheName)
  }

  async clear() {
    await window.caches.delete(cacheName)
    this.instance = await window.caches.open(cacheName)
  }

  async match(request) {
    return this.instance.match(request)
  }

  async put(request, response) {
    return this.instance.put(request, response)
  }
}

export default StorageCache
