<template>
  <div
    v-if="isReady"
    ref="gridBox"
    class="gridlist grid-Scroll grid-ScrollList"
  >
    <div class="mb-8 flex flex-end justify-end button-top">
      <!-- <button class="CTAButton bg-transparent mr-2" @click="addNewGroup">
        Add New Group
      </button>-->
      <button class="CTAButton bg-transparent" @click="addNewUser">
        Add New User
      </button>
    </div>
    <DeleteUser ref="delUser">
      Are you sure you want to delete this User?
    </DeleteUser>
    <ag-grid-vue
      :style="{ width, height }"
      class="ag-theme-fresh"
      :get-row-height="getRowHeight"
      :default-col-def="defaultColDef"
      :column-defs="columnDefs"
      :pagination="true"
      :pagination-page-size="paginationPageSize"
      :row-data="rowData"
      :modules="modules"
      :row-class-rules="rowClassRules"
      @cell-clicked="onCellClick"
      @grid-ready="onGridReady"
    />
  </div>
  <Spinner v-else />
</template>
<script>
import 'ag-grid-community/dist/styles/ag-theme-fresh.css'
import { AgGridVue } from '@ag-grid-community/vue'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { RichSelectModule } from '@ag-grid-enterprise/rich-select'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import Spinner from '../../components/Spinner.vue'
import DeleteUser from '../../components/Modal/DeleteUser.vue'

export default {
  components: {
    AgGridVue,
    Spinner,
    DeleteUser,
  },
  inject: ['store'],
  props: {
    response: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rowData: [],
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      width: '100%',
      height: '80vh',
      rowSelection: 'multiple',
      rowClassRules: null,
      modules: [
        SetFilterModule,
        ClientSideRowModelModule,
        MenuModule,
        ColumnsToolPanelModule,
        RichSelectModule,
        AllModules,
      ],
      title: '',
      rowHeight: 50,
    }
  },
  computed: {
    isAppName() {
      return this.store.user.appName
    },
    isReady() {
      return this.store.assort.users
    },
    isDel() {
      return this.store.user.userDetails
    },
  },
  watch: {
    isReady(newVal, oldVal) {
      if (newVal) {
        this.rowData = this.store.assort.users
      }
    },
    // async isDel(newVal, oldVal) {
    //   if (newVal) {
    //     this.rowData = await this.store.assort.fetchUsers()
    //   }
    // },
    isAppName(newVal, oldVal) {
      if (newVal) {
        this.rowData = this.store.assort.users
      }
    },
    response(newVal, oldVal) {
      if (newVal) {
        this.rowData = this.response
      }
    },
  },
  beforeMount() {
    this.gridOptions = {
      // floatingFiltersHeight: 40,
      // headerHeight: 32,
      // suppressScrollOnNewData: true,
    }
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      cellStyle: {
        transform: 'translateY(0px)',
        'background-color': '#E5E5E5',
        'border-bottom': '2px solid #D8D8D8',
        'padding-top': '10px;',
      },
      // filter: 'agTextColumnFilter',
      // floatingFilter: true,
    }
    this.paginationPageSize = 10
    this.paginationNumberFormatter = params => {
      return '[' + params.value.toLocaleString() + ']'
    }
    this.rowData = this.store.assort.users
    this.columnDefs = [
      {
        headerName: 'Name',
        field: 'fullName',
        width: 150,
      },
      {
        headerName: 'UserName/ID',
        field: 'userId',
        width: 150,
      },
      {
        headerName: 'Email',
        field: 'email',
        width: 250,
      },
      {
        headerName: 'Group',
        // field: 'data.applications[0].name',
        width: 190,
        cellRenderer: this.group.bind(this),
      },
      {
        headerName: 'Sub Group',
        // field: 'email',
        width: 190,
        cellRenderer: this.subgroup.bind(this),
      },
      {
        headerName: 'Last LogIn',
        field: 'lastLogIn',
        width: 170,
      },
      {
        headerName: 'Total LogIn',
        field: 'totalLogIns',
        width: 170,
      },
      {
        headerName: '',
        field: '',
        cellClass: 'cellAlign',
        cellRenderer: this.createElement.bind(this),
        width: 35,
        filter: false,
      },
      {
        headerName: '',
        field: '',
        cellClass: 'cellAlign',
        cellRenderer: this.deleteUser.bind(this),
        minWidth: 80,
        maxWidth: 80,
        filter: false,
      },
    ]
  },
  mounted() {
    this.rowData = this.store.assort.users
  },
  methods: {
    onGridReady(params) {
      setTimeout(() => {
        params.api.redrawRows()
      }, 100)
    },
    getRowHeight(params) {
      let rowLength = 100
      let totalLen = 0
      params.data.applications.map(app => {
        if (app.name === this.store.user.appName) {
          totalLen = totalLen + app.groups.length * 22
          // app.groups.map(m => {
          //   totalLen = totalLen + m.groupList.length
          // })
          rowLength = rowLength + totalLen
        }
      })
      return rowLength
    },
    onCellClick(event) {
      if (event.column.colDef.headerName === 'Full Name') {
        this.title = event.value
        this.openModal()
      }
    },
    cellStyle(params) {
      return {
        backgroundColor: '#E5E5E5',
        // rowHeight: '200px'
        //   color: '#7c7c7c',
      }
    },
    subgroup(event) {
      let subgrp = []
      event.data.applications.map(app => {
        if (app.groups.length && app.name === this.store.user.appName) {
          app.groups.map(a => {
            if (a.isParentLinked) {
              subgrp.push('All Child Groups')
            } else {
              a.groupList.map(sb => {
                subgrp.push(sb.name)
              })
            }
          })
        }
      })
      var link = document.createElement('a')
      subgrp.map(gp => {
        link.innerHTML =
          link.innerHTML + `<i class="fa pb-2 mb-2 flex text-black" >${gp}</i>`
      })
      link.addEventListener('click', () => {})
      //  if(event.data.status === 'Active'){
      return link
      // } else {
      //   return ''
      // }
    },
    group(event) {
      let grp = []
      event.data.applications.map(app => {
        if (app.groups.length && app.name === this.store.user.appName) {
          app.groups.map(gp => {
            grp.push(gp.name)
          })
        }
      })

      var link = document.createElement('a')
      grp.map(gp => {
        link.innerHTML =
          link.innerHTML + `<i class="fa pb-2 mb-2 flex text-black" >${gp}</i>`
      })
      // link.innerHTML = `<div>
      //  <i class="fa pb-2 flex fa-caret-down text-black" >Display</i>
      //  </div>`
      link.addEventListener('click', () => {
        // this.store.user.condition = 'newUser'
        // this.store.user.userData = data.data.userId
      })
      //   if(event.data.status === 'Active'){
      return link
      //  } else {
      //    return ''
      //  }
    },
    addNewUser() {
      this.store.user.condition = 'newUser'
      this.store.user.userData = null
    },
    addNewGroup() {
      this.store.user.condition = 'newGroup'
      this.store.user.userData = null
    },
    createElement(data) {
      var link = document.createElement('a')
      link.innerHTML = '<i class="fa fa-pencil"  ></i>'
      link.addEventListener('click', () => {
        this.store.user.condition = 'newUser'
        this.store.user.userData = data.data.userId
      })
      return link
    },
    deleteUser(event) {
      var link = document.createElement('a')
      link.innerHTML = '<i class="fa fa-trash" ></i>'
      link.addEventListener('click', async () => {
        // this.$refs.delUser.open(event)
        await this.$refs.delUser.open(event.data, 'global')
        // this.store.user.condition = 'newUser'
        // this.store.user.userData = data.data.userId
      })
      return link
    },
    openModal() {
      this.$refs.userModal.open()
    },
  },
}
</script>
<style lang="scss">
// .ag-header-cell-label,
// .ag-header-row,
// .ag-theme-fresh .ag-row-odd {
//   background-color: #fff;
//   background: none;
// }
.ag-body-viewport .ag-center-cols-container,
.ag-theme-fresh .ag-body-viewpor,
.ag-center-cols-viewport,
.ag-center-cols-clipper,
.ag-center-cols-clipper,
.ag-theme-fresh .ag-row-even,
.ag-theme-fresh .ag-paging-panel,
.ag-header-viewport,
.ag-header-container,
.ag-header-cell-label,
.ag-header-row {
  background-color: #e5e5e5 !important;
}
.ag-header-cell-label .ag-header-cell-text {
  color: #050505;
  font-size: 14px;
  font-weight: 700;
}
.ag-theme-fresh .ag-root-wrapper,
.ag-root.ag-layout-normal {
  border: none;
}
.ag-theme-fresh .ag-header {
  border-bottom: 2px solid #d8d8d8;
}
.ag-theme-fresh .ag-header-cell,
.ag-theme-fresh .ag-ltr .ag-cell {
  border-right: none;
}
.ag-theme-fresh .ag-paging-panel {
  padding-top: 3%;
  padding-bottom: 3%;
}
.ag-theme-fresh .ag-header-cell::after,
.ag-theme-fresh .ag-header-group-cell::after {
  content: none;
}
.ag-row-position-absolute {
  // position: relative;
  margin: 20px 0;
}
.ag-row {
  position: absolute !important;
}
.ag-cell-focus {
  border: none !important;
}
#page-size {
  width: 60px;
}
ul.nav {
  border-bottom: 2px solid #d8d8d8;
  margin-top: 20px;
  margin-bottom: 40px;
}
ul.nav li {
  display: inline-block;

  a.router-link-active {
    color: #c21a31;
    border-left: 0 !important ;
    margin-right: 20px;
    border-bottom: 2px solid;
  }
}
a {
  color: #d8d8d8;
}
img {
  float: left;
  margin: 0 5px;
}
.ag-paging-row-summary-panel {
  position: absolute;
  left: 0;
}
.ag-paging-page-summary-panel .ag-paging-button button {
  position: relative;
}
.pages {
  width: 100%;
  background-color: #e5e5e5;
}
.button-top {
  position: fixed;
  top: 22px;
  right: 35px;
}
.fa-pencil,
.fa-trash {
  color: #c21a31;
  font-size: 15px;
}
.CTAButton {
  transition-duration: 0.6s;
  background-color: #e5e5e5;
  color: #c21a31;
  border: 2px solid #c21a31;
  border-radius: 8px;
  min-width: 160px;
  padding: 12px 24px;
  margin: 0 0 0 15px;
}
.CTAButton:hover {
  background-color: #c21a31; /* Green */
  color: white;
}
</style>
