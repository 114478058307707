import Vue from 'vue'
import axios from 'axios'
const userAssortments = store => {
  return new Vue({
    data: {
      selectedSeason: '',
      componentKey: 0,
      allPC9s: [],
    },
    computed: {
      error() {
        return this.$asyncComputed.items.exception
      },
      isLoading() {
        const notStarted =
          this.items.length === 0 && this.$asyncComputed.items.success
        return notStarted || this.$asyncComputed.items.updating
      },
      isReady() {
        return this.$asyncComputed.items.success && this.items.length >= 0
      },
      isEmpty() {
        return this.items.length === 0
      },
      isUnauthorized() {
        return this.error && this.error.status === 401
      },
      season() {
        return store.addGlobalAssortment.season
      },
      year() {
        return store.addGlobalAssortment.year
      },
      brand() {
        return store.addGlobalAssortment.brand
      },
    },
    asyncComputed: {
      items: {
        default: [],
        lazy: true,
        async get() {
          const response = await store.api.get({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/application/all`,
          })

          if (response.status === 404) {
            const error = new Error('Not found')
            error.status = 404
            throw error
          }
          if (response.message === 'Unauthorized') {
            const error = new Error('Unauthorized')
            error.status = 401
            throw error
          }
          let myArr = response
          return myArr
        },
      },
    },
    methods: {
      refresh() {
        this.$asyncComputed.items.update()
      },
      setCurrentPC9(pc9Data) {
        this.pc9Data = pc9Data
      },
      async getAllPC9s(assorts) {
        const promises = assorts.map(async assort => {
          const response = await store.api.get({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/assortment/${assort.assortmentId}/pc9/all`,
          })

          return response
        })

        const results = await Promise.all(promises)
        this.allPC9s = [...results]
        return results
      },
      async downloadFile(path, fName) {
        await axios({
          url: `${process.env.VUE_APP_LEVI_RVT_API_URL}${path}`,
          method: 'GET',
          responseType: 'blob',
          headers: { Authorization: `Bearer ${store.auth.idToken}` },
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fName)
          document.body.appendChild(link)
          link.click()
        })
      },
    },
  })
}

export default userAssortments
