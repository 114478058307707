<template>
  <div class="">
    <DeniedRequest ref="delUser">
      <p class="mb-8 text-left font-bold">Denied Access for {{ fullName }}</p>
      <div class="text-left mb-3">Denied Access By : {{ deniedBy }}</div>
      <div class="text-left mb-3">Denied on : {{ formattedDate }}</div>
      <div class="text-left">Note for User</div>
      <textarea v-model="notetouser"></textarea>
      <div class="text-left">Internal Description</div>
      <textarea v-model="comments"></textarea>
    </DeniedRequest>
    <div
      v-if="isReady"
      ref="gridBox"
      class="gridlist grid-Scroll grid-ScrollList"
    >
      <AgGridVue
        :style="{ width, height }"
        class="ag-theme-fresh"
        :row-height="rowHeight"
        :get-row-height="getRowHeight"
        :default-col-def="defaultColDef"
        :column-defs="columnDefs"
        :pagination="true"
        :pagination-page-size="paginationPageSize"
        :row-data="denied"
        :modules="modules"
        :row-class-rules="rowClassRules"
        @grid-ready="onGridReady"
      >
      </AgGridVue>
    </div>
    <Spinner v-else />
  </div>
</template>
<script>
import 'ag-grid-community/dist/styles/ag-theme-fresh.css'
import { AgGridVue } from '@ag-grid-community/vue'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { RichSelectModule } from '@ag-grid-enterprise/rich-select'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import Spinner from '../../components/Spinner.vue'
import DeniedRequest from '../../components/Modal/DeniedRequest.vue'
import moment from 'moment'

export default {
  components: {
    AgGridVue,
    Spinner,
    DeniedRequest,
  },
  inject: ['store'],
  props: {
    response: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      denied: [],
      fullName: null,
      deniedBy: null,
      deniedDate: null,
      formattedDate: null,
      notetouser: null,
      comments: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      width: '100%',
      height: '80vh',
      rowSelection: 'multiple',
      rowClassRules: null,
      modules: [
        SetFilterModule,
        ClientSideRowModelModule,
        MenuModule,
        ColumnsToolPanelModule,
        RichSelectModule,
        AllModules,
      ],
      rowHeight: 150,
    }
  },
  computed: {
    isReady() {
      return this.store.assort.denied
    },
    isAppName() {
      return this.store.user.appName
    },
    isDel() {
      return this.store.user.userDetails
    },
  },
  watch: {
    isReady(newVal, oldVal) {
      if (newVal) {
        this.denied = this.store.assort.denied
      }
    },
    isAppName(newVal, oldVal) {
      if (newVal) {
        this.denied = this.store.assort.denied
      }
    },
    async isDel(newVal, oldVal) {
      if (newVal) {
        this.denied = await this.store.assort.fetchdenied()
      }
    },
    response(newVal, oldVal) {
      if (newVal) {
        this.denied = this.response
      }
    },
  },

  beforeMount() {
    this.gridOptions = {
      // floatingFiltersHeight: 60,
      // headerHeight: 60,
    }
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      cellStyle: {
        'background-color': '#E5E5E5',
        'border-bottom': '2px solid #D8D8D8',
        'padding-top': '10px;',
        transform: 'translateY(0px)',
      },
      // filter: 'agTextColumnFilter',
      // floatingFilter: true,
      enableCellTextSelection: true,
    }
    this.paginationPageSize = 10
    this.paginationNumberFormatter = params => {
      return '[' + params.value.toLocaleString() + ']'
    }
    this.denied = this.store.assort.denied
    if (
      this.store.user.appName === 'NestRVT' ||
      this.store.user.appName === 'VendorRVT'
    ) {
      this.columnDefs = [
        {
          headerName: 'Name',
          field: 'fullName',
          width: 150,
        },

        {
          headerName: 'User Id',
          // field: 'data.applications[0].name',
          width: 190,
          field: 'userId',
        },
        {
          headerName: 'Email',
          field: 'email',
          width: 250,
        },
        {
          headerName: 'Vendor Name',
          // field: 'userId',
          width: 300,
          field: 'vendorId',
          // cellRenderer: this.subgroup.bind(this),
        },
        {
          headerName: '',
          field: '',
          cellClass: 'cellAlign',
          cellRenderer: this.deleteUser.bind(this),
          width: 130,
          filter: false,
        },
        {
          headerName: '',
          field: '',
          cellClass: 'cellAlign',
          cellRenderer: this.createElement.bind(this),
          width: 110,
          filter: false,
        },
      ]
    } else {
      this.columnDefs = [
        {
          headerName: 'Name',
          field: 'fullName',
          width: 150,
        },

        {
          headerName: 'User Id',
          // field: 'data.applications[0].name',
          width: 190,
          field: 'userId',
        },
        {
          headerName: 'Email',
          field: 'email',
          width: 250,
        },
        {
          headerName: 'Role',
          width: 170,
          field: 'role',
        },
        {
          headerName: 'Brand',
          width: 170,
          field: 'brand',
        },
        {
          headerName: 'PDC',
          width: 170,
          field: 'pdc',
        },
        {
          headerName: '',
          field: '',
          cellClass: 'cellAlign',
          cellRenderer: this.deleteUser.bind(this),
          width: 130,
          filter: false,
        },
        {
          headerName: '',
          field: '',
          cellClass: 'cellAlign',
          cellRenderer: this.createElement.bind(this),
          width: 110,
          filter: false,
        },
      ]
    }
  },
  mounted() {
    this.denied = this.store.assort.denied
    // this.fetch()
  },
  methods: {
    onGridReady(params) {
      setTimeout(() => {
        params.api.redrawRows()
      }, 100)
    },

    getRowHeight(params) {
      let rowLength = 70
      let totalLen = 0
      params.data.groups?.map(app => {
        totalLen = totalLen + app.length
        // totalLen = totalLen + app.groupList.length
      })
      rowLength = rowLength + totalLen * 30
      return rowLength
    },
    // getRowHeight(params) {
    //   return params.data.rowHeight
    // },
    subgroup(event) {
      let subgrp = []
      event.data.applications.map(app => {
        if (app.groups.length && app.name === this.store.user.appName) {
          if (app.groups[0].groupList.length)
            app.groups[0].groupList.map(sb => {
              subgrp.push(sb.groupId)
            })
        }
      })
      var link = document.createElement('a')
      subgrp.map(gp => {
        link.innerHTML =
          link.innerHTML + `<i class="fa pb-2 flex text-black" >${gp}</i>`
      })
      link.addEventListener('click', () => {})
      //  if(event.data.status === 'Active'){
      return link
      // } else {
      //   return ''
      // }
    },
    group(event) {
      let grp = []
      event.data.applications.map(app => {
        if (app.groups.length && app.name === this.store.user.appName) {
          if (app.groups[0].groupList.length)
            app.groups[0].groupList.map(sb => {
              grp.push(sb.name)
            })
        }
      })

      var link = document.createElement('a')
      grp.map(gp => {
        link.innerHTML =
          link.innerHTML + `<i class="fa pb-2 mb-2 flex text-black" >${gp}</i>`
      })
      // link.innerHTML = `<div>
      //  <i class="fa pb-2 flex fa-caret-down text-black" >Display</i>
      //  </div>`
      link.addEventListener('click', () => {
        // this.store.user.condition = 'newUser'
        // this.store.user.userData = data.data.userId
      })
      //   if(event.data.status === 'Active'){
      return link
      //  } else {
      //    return ''
      //  }
    },
    newVendor() {
      this.store.user.condition = 'newVendor'
      this.store.user.userData = null
    },
    createElement(data) {
      var link = document.createElement('a')
      link.innerHTML = ' <div class="boxType">Approve</div>'
      link.addEventListener('click', async () => {
        await this.store.assort.statusData(
          this.store.user.appName,
          data.data.userId,
        )
        this.store.assort.refreshVendors()
        this.store.assort.refreshDenied()
      })
      return link
    },
    deleteUser(event) {
      var link = document.createElement('a')
      link.innerHTML = ' <div class="boxType">View Notes</div>'
      link.addEventListener('click', async () => {
        this.fullName = event.data.fullName
        this.deniedBy = event.data.modifiedBy
        this.deniedDate = event.data.modifyStamp
        this.formattedDate = moment(this.deniedDate).format('YYYY-MM-DD')
        this.notetouser = event.data.noteToUser
        this.comments = event.data.comments
        await this.$refs.delUser.open(event.data, 'global')
      })
      return link
    },
  },
}
</script>
<style lang="scss">
// .ag-header-cell-label,
// .ag-header-row,
// .ag-theme-fresh .ag-row-odd {
//   background-color: #fff;
//   background: none;
// }
.ag-body-viewport .ag-center-cols-container,
.ag-theme-fresh .ag-body-viewpor,
.ag-center-cols-viewport,
.ag-center-cols-clipper,
.ag-center-cols-clipper,
.ag-theme-fresh .ag-row-even,
.ag-theme-fresh .ag-paging-panel,
.ag-header-viewport,
.ag-header-container,
.ag-header-cell-label,
.ag-header-row {
  background-color: #e5e5e5 !important;
}
.ag-header-cell-label .ag-header-cell-text {
  color: #050505;
  font-size: 14px;
  font-weight: 700;
}
.ag-theme-fresh .ag-root-wrapper {
  border: none;
}
.ag-theme-fresh .ag-header-cell {
  border-right: none;
}
.ag-theme-fresh .ag-header-cell::after,
.ag-theme-fresh .ag-header-group-cell::after {
  content: none;
}
.ag-row-position-absolute {
  position: relative;
  margin: 20px 0;
}
#page-size {
  width: 60px;
}
ul.nav {
  border-bottom: 2px solid #d8d8d8;
  margin-top: 20px;
  margin-bottom: 40px;
}
ul.nav li {
  display: inline-block;

  a.router-link-active {
    color: #c21a31;
    border-left: 0 !important ;
    margin-right: 20px;
    border-bottom: 2px solid;
  }
}
a {
  color: #d8d8d8;
}
img {
  float: left;
  margin: 0 5px;
}
.ag-paging-row-summary-panel {
  position: absolute;
  left: 0;
}
.ag-paging-page-summary-panel .ag-paging-button button {
  position: relative;
}
.pages {
  width: 100%;
  background-color: #e5e5e5;
}

.button-top {
  position: fixed;
  top: 22px;
  right: 35px;
}
.boxType {
  border: 2px solid rgb(1, 1, 51);
  padding-left: 6px;
  border-radius: 10px;
  margin-left: 15px;
  color: #050505;

  &:hover {
    background-color: black;
    color: white;
    cursor: pointer;
  }
}
</style>
