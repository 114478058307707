<template>
  <div class="">
    <IssueNote ref="noteRef">
      <p class="">
        <span class="mb-14 mt-18 block text-left font-bold">
          Notes/Description here <span class="ml-64"> {{ number }}</span>
        </span>
      </p>
      <div class="text-left mb-14">
        <span class="text-3xl font-bold pr-26"> Subject </span> {{ subject }}
      </div>
      <div class="text-left mb-14">
        <span class="text-3xl font-bold pr-26">
          Attachments

          <span
            v-for="(at, index) in attachments"
            :key="index"
            class="inline-block ml-6"
          >
            <p
              class="border-gray-300 border-2 rounded-lg shadow-2xl att-title cursor-pointer"
            >
              {{ at.displayName }}
              <i class="ml-6 fa fa-download" @click="downloadImg(at)"></i>
            </p>
          </span>
        </span>
      </div>
      <div class="text-left mb-8 text-3xl font-bold">
        Description
        <textarea v-model="notetouser" class="textHeight"></textarea>
      </div>
    </IssueNote>
    <div
      v-if="isReady"
      ref="gridBox"
      class="gridlist grid-Scroll grid-ScrollList"
    >
      <AgGridVue
        :style="{ width, height }"
        class="ag-theme-fresh"
        :row-height="rowHeight"
        :get-row-height="getRowHeight"
        :default-col-def="defaultColDef"
        :column-defs="columnDefs"
        :pagination="true"
        :pagination-page-size="paginationPageSize"
        :row-data="issues"
        :modules="modules"
        :row-class-rules="rowClassRules"
        @grid-ready="onGridReady"
        @cellValueChanged="onCellValueChanged"
      >
      </AgGridVue>
    </div>
    <Spinner v-else />
  </div>
</template>
<script>
import 'ag-grid-community/dist/styles/ag-theme-fresh.css'
import { AgGridVue } from '@ag-grid-community/vue'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { RichSelectModule } from '@ag-grid-enterprise/rich-select'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import Spinner from '../../components/Spinner.vue'
import IssueNote from '../../components/Modal/IssueNote.vue'
import moment from 'moment'
import { AutocompleteSelectCellEditor } from 'ag-grid-autocomplete-editor'
import { loadImage } from '../../util/rendering-utils'
import { createTimestamp } from '../../util/dates'

export default {
  components: {
    AgGridVue,
    Spinner,
    IssueNote,
  },
  inject: ['store'],
  props: {
    response: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      issues: [],
      fullName: null,
      subject: null,
      notetouser: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      width: '100%',
      height: '80vh',
      rowSelection: 'multiple',
      rowClassRules: null,
      modules: [
        SetFilterModule,
        ClientSideRowModelModule,
        MenuModule,
        ColumnsToolPanelModule,
        RichSelectModule,
        AllModules,
      ],
      rowHeight: 150,
      number: null,
      attachments: [],
      blob: '',
    }
  },
  computed: {
    isReady() {
      return this.store.assort.issues
    },
    isAppName() {
      return this.store.user.appName
    },
  },
  watch: {
    isReady(newVal, oldVal) {
      if (newVal) {
        this.issues = this.store.assort.issues
      }
    },
    isAppName(newVal, oldVal) {
      if (newVal) {
        this.issues = this.store.assort.issues
      }
    },
    response(newVal, oldVal) {
      if (newVal) {
        this.issues = this.response
      }
    },
  },

  beforeMount() {
    const self = this
    this.gridOptions = {
      // floatingFiltersHeight: 60,
      // headerHeight: 60,
    }
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      cellStyle: {
        'background-color': '#E5E5E5',
        'border-bottom': '2px solid #D8D8D8',
        'padding-top': '10px;',
        transform: 'translateY(0px)',
      },
      enableCellTextSelection: true,
    }
    this.paginationPageSize = 10
    this.paginationNumberFormatter = params => {
      return '[' + params.value.toLocaleString() + ']'
    }
    this.issues = this.store.assort.issues

    this.columnDefs = [
      {
        headerName: 'Issue Id',
        field: 'number',
        width: 130,
      },

      {
        headerName: 'Created By',
        width: 190,
        field: 'createdByFullName',
      },
      {
        headerName: 'Created Date',
        field: 'createStamp',
        width: 210,
        cellRenderer: this.formatdDate.bind(this),
      },
      {
        headerName: 'Subject',
        width: 170,
        field: 'subject',
      },
      {
        headerName: 'Assigned To',
        width: 170,
        editable: () => {
          return this.store.user.isAdmin
        },
        cellStyle: { height: '70px', fontSize: '14px', padding: '10px' },
        //  cellStyle: { height: '100px', fontSize: '15px', bottom: '0%', padding:'20px'},
        field: 'assignedToFullName',
        cellEditor: AutocompleteSelectCellEditor,
        cellEditorParams: {
          autocomplete: {
            async fetch(cellEditor, text, update, params) {
              let match = encodeURI(text) || encodeURI(cellEditor.eInput.value)
              let spinner = document.createElement('i')
              spinner.className = 'ag-cell-editor-autocomplete-spinner'
              if (match.length >= 3) {
                const response = await self.store.assort.search(match)

                let data = response || []
                let items = data.map(d => ({
                  value: d.fullName,
                  label: d.fullName,
                  name: d.userId,
                }))
                const codes = []
                self.issues.forEach(row => {
                  codes.push(row.assignedToFullName)
                })
                // items = items.filter(item => {
                //   return !codes.includes(item.value)
                // })
                update(items)
              }
            },
          },
          placeholder: 'Select User',
        },
        valueFormatter: params => {
          if (params.value) {
            return params.value.value || params.value
          }
          return ''
        },
      },
      {
        headerName: 'Status',
        editable: () => {
          return this.store.user.isAdmin
        },
        singleClickEdit: true,
        width: 170,
        field: 'issueStatus',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: [
            'New',
            'In Progress',
            'Assigned',
            'QA',
            'Resolved',
            'Cancelled',
          ],
        },
      },
      {
        headerName: 'Completed On',
        width: 135,
        field: 'completedOn',
        editable: false,
        cellRenderer: this.showCompletedDate.bind(this),
        valueFormatter: params => {
          if (params.value) {
            return params.value.value || params.value
          }
          return ''
        },
      },
      {
        headerName: '',
        field: '',
        cellClass: 'cellAlign',
        cellRenderer: this.openNote.bind(this),
        width: 130,
        filter: false,
      },
    ]
  },
  async mounted() {
    this.issues = this.store.assort.issues
  },
  methods: {
    onGridReady(params) {
      setTimeout(() => {
        // params.api.doLayout()
        // params.api.redrawRows()
        params.api.refreshCells()
      }, 100)
    },
    appname() {
      return this.store.user.appname
    },
    getRowHeight(params) {
      let rowLength = 70
      let totalLen = 0
      params.data.groups?.map(app => {
        totalLen = totalLen + app.length
      })
      rowLength = rowLength + totalLen * 30
      return rowLength
    },
    openNote(event) {
      var link = document.createElement('a')
      link.innerHTML = ' <div class="boxType">View Notes</div>'
      link.addEventListener('click', async () => {
        this.subject = event.data.subject
        this.notetouser = event.data.note
        this.number = event.data.number
        this.attachments = event.data.attachments
        this.$refs.noteRef.open(event.data, 'global')
      })
      return link
    },
    formatdDate(data) {
      return moment(data.value).format('MM-DD-YYYY')
    },
    showCompletedDate(data) {
      if (data.data.issueStatus === 'Resolved') {
        return moment(data.value).format('MM-DD-YYYY')
      } else return ''
    },
    async downloadImg(attachment) {
      let images = ['.png', 'jpeg', '.jpg']
      if (attachment.displayName.includes(images)) {
        let url = await this.convertingTourl(
          attachment.filePath,
          attachment.displayName,
        )
        const link = document.createElement('a')
        link.href = url
        link.download = this.fName
        document.body.appendChild(link)
        link.click()
      } else {
        await this.store.applications.downloadFile(
          attachment.filePath,
          attachment.displayName,
        )
      }
    },
    async convertingTourl(path, name) {
      const tmpCanvas = document.createElement('canvas')
      const tmpContext = tmpCanvas.getContext('2d')
      let tmpData
      try {
        let Image = await this.displayImg(path, name)
        tmpCanvas.width = Image.width
        tmpCanvas.height = Image.height
        tmpContext.drawImage(Image, 0, 0)
        tmpData = tmpCanvas.toDataURL('png')
      } catch (e) {
        console.log('ignore')
      }
      this.blob = tmpData
    },
    async displayImg(path, name) {
      let fUrl = `${process.env.VUE_APP_LEVI_RVT_API_URL}${path}`
      let src
      this.fName = name
      try {
        console.log(fUrl)
        src = await loadImage(this.store.assetLoader, fUrl)
      } catch (e) {
        console.log('ignore', e)
      }
      return src
    },
    onCellValueChanged(event) {
      const tmpData = event.data.assignedToFullName?.name
        ? event.data.assignedToFullName?.name
        : event.data.assignedTo
      let dateTime = createTimestamp()
      try {
        if (tmpData && event.value.name !== event.oldValue)
          this.store.assort.updateIssue(
            tmpData,
            dateTime,
            event.data.issueStatus,
            event.data.number,
          )
      } catch (e) {
        console.log('ignore')
      }
    },
  },
}
</script>
<style lang="scss">
.ag-body-viewport .ag-center-cols-container,
.ag-theme-fresh .ag-body-viewpor,
.ag-center-cols-viewport,
.ag-center-cols-clipper,
.ag-center-cols-clipper,
.ag-theme-fresh .ag-row-even,
.ag-theme-fresh .ag-paging-panel,
.ag-header-viewport,
.ag-header-container,
.ag-header-cell-label,
.ag-header-row {
  background-color: #e5e5e5 !important;
}
.ag-header-cell-label .ag-header-cell-text {
  color: #050505;
  font-size: 14px;
  font-weight: 700;
}
.ag-theme-fresh .ag-root-wrapper {
  border: none;
}
.ag-theme-fresh .ag-header-cell {
  border-right: none;
}
.ag-theme-fresh .ag-header-cell::after,
.ag-theme-fresh .ag-header-group-cell::after {
  content: none;
}
.ag-row-position-absolute {
  position: relative;
  margin: 20px 0;
}
#page-size {
  width: 60px;
}
ul.nav {
  border-bottom: 2px solid #d8d8d8;
  margin-top: 20px;
  margin-bottom: 40px;
}
ul.nav li {
  display: inline-block;

  a.router-link-active {
    color: #c21a31;
    border-left: 0 !important ;
    margin-right: 20px;
    border-bottom: 2px solid;
  }
}
a {
  color: #d8d8d8;
}
img {
  float: left;
  margin: 0 5px;
}
.ag-paging-row-summary-panel {
  position: absolute;
  left: 0;
}
.ag-paging-page-summary-panel .ag-paging-button button {
  position: relative;
}
.pages {
  width: 100%;
  background-color: #e5e5e5;
}

.button-top {
  position: fixed;
  top: 22px;
  right: 35px;
}
.boxType {
  border: 2px solid rgb(1, 1, 51);
  padding-left: 6px;
  border-radius: 10px;
  margin-left: 15px;
  color: #050505;

  &:hover {
    background-color: black;
    color: white;
    cursor: pointer;
  }
}
.pr-44 {
  padding-right: 13rem;
}
.textHeight {
  height: 250px;
}
.autocomplete {
  background-color: white !important;
  .selected {
    &:hover {
      background-color: gray !important;
    }
  }
}
</style>
