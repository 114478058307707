import Vue from 'vue'

const assortments = store => {
  return new Vue({
    data: {
      // currentAssortment: '4cc39977-fde9-4e8c-903a-049aa0fc0bd1',
      currentAssortmentList: [],
      selectedPC9s: [],
      componentKey: 0,
      isError: false,
    },
    computed: {
      error() {
        return this.$asyncComputed.items.exception
      },
      isLoading() {
        const notStarted =
          this.items.length === 0 && this.$asyncComputed.items.success
        return notStarted || this.$asyncComputed.items.updating
      },
      isReady() {
        return this.$asyncComputed.items.success
      },
      isEmpty() {
        return this.isReady && !this.items.length
      },
      isUnauthorized() {
        return this.error && this.error.status === 401
      },
      currentAssortment() {
        if (typeof store.router.params.assortmentId === 'undefined') {
          return '4cc39977-fde9-4e8c-903a-049aa0fc0bd1'
        }
        return store.router.params.assortmentId

        // return this.$router
      },
    },
    asyncComputed: {
      items: {
        default: [],
        lazy: true,
        async get() {
          try {
            const response = await store.api.get({
              url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/assortment/${this.currentAssortment}/pc9/all`,
            })

            // if (response.message === 'Unauthorized') {
            //   const error = new Error('Unauthorized')
            //   error.status = 401
            //   throw error
            // }
            this.componentKey += 1
            // this.$router.push({ name: 'assortLanding' })
            // store.assort.refresh()

            return response
          } catch (ex) {
            this.isError = true

            // We can ignore the error if they added one they already have
            // on their list.
            // if (ex.message !== '400') {
            //   throw ex
            // }
          }
        },
      },
    },
    methods: {
      find(assortmentId) {
        return this.items.find(assortment => {
          return assortment.id === assortmentId
        })
      },
      findByRegion(region) {
        return this.items.find(assortment => {
          return assortment.region === region
        })
      },
      addToAssortment(items) {
        this.currentAssortmentList = this.currentAssortmentList.concat(items)

        /**
         * Push these PC9s to the API
         */
      },
      saveSelectedPC9s(PC9s) {
        this.selectedPC9s = PC9s
      },
      isPC9Selected(PC9) {
        return this.selectedPC9s.includes(PC9)
      },
    },
  })
}

export default assortments
