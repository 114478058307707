import Vue from 'vue'

const assort = store => {
  return new Vue({
    data: {
      selectedSeason: '',
      componentKey: 0,
      allPC9s: [],
      gridHeight: 0,
      gridCardHeight: 0,
      windowHeightSaved: 0,
      windowHeightSavedCard: 0,
      enableBack: false,
      currAppName: null,
      applicationName: '',
      correspondingSubGrp: [],
      subAllGroupId: [],
      applications: [],
      parentGrpId: [],
      parentName: null,
    },
    computed: {
      error() {
        return this.$asyncComputed.items.exception
      },
      isLoading() {
        const notStarted =
          this.items.length === 0 && this.$asyncComputed.items.success
        return notStarted || this.$asyncComputed.items.updating
      },
      isReady() {
        return this.$asyncComputed.items.success && this.items.length >= 0
      },
      isEmpty() {
        return this.items.length === 0
      },
      isUnauthorized() {
        return this.error && this.error.status === 401
      },
      isUserReady() {
        return this.$asyncComputed.users.success && this.users.length >= 0
      },
      isVendorReady() {
        return this.$asyncComputed.vendors.success && this.vendors.length >= 0
      },
    },
    asyncComputed: {
      items: {
        default: [],
        lazy: true,
        async get() {
          const response = await store.api.get({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/application/all`,
          })

          if (response.status === 404) {
            const error = new Error('Not found')
            error.status = 404
            throw error
          }
          if (response.message === 'Unauthorized') {
            const error = new Error('Unauthorized')
            error.status = 401
            throw error
          }
          return response
        },
      },
      users: {
        default: [],
        lazy: true,
        async get() {
          this.enableBack = true
          const response = await store.api.get({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/user/${store.user.appName}/users`,
          })
          if (response.status === 404) {
            const error = new Error('Not found')
            error.status = 404
            throw error
          }
          if (response.message === 'Unauthorized') {
            const error = new Error('Unauthorized')
            error.status = 401
            throw error
          }
          return response
        },
      },
      vendors: {
        default: [],
        lazy: true,
        async get() {
          this.enableBack = true
          const response = await store.api.get({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/user/${store.user.appName}/vendors`,
          })
          if (response.status === 404) {
            const error = new Error('Not found')
            error.status = 404
            throw error
          }
          if (response.message === 'Unauthorized') {
            const error = new Error('Unauthorized')
            error.status = 401
            throw error
          }

          // if (store.user.appName === 'NestRVT') {
          //   let accessVar2 = []
          //   response.map(r => {
          //     if (r.tag[0] === 'US-171-approved') {
          //       accessVar2.push(r)
          //     }
          //   })
          //   return accessVar2
          // }
          return response
        },
      },
      aproveaccess: {
        default: [],
        lazy: true,
        async get() {
          this.enableBack = true
          const response = await store.api.get({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/user/${store.user.appName}/requestedUsers`,
          })
          if (response.status === 404) {
            const error = new Error('Not found')
            error.status = 404
            throw error
          }
          if (response.message === 'Unauthorized') {
            const error = new Error('Unauthorized')
            error.status = 401
            throw error
          }
          let accessVarapprove = []
          response.map(r => {
            if (r.status === 'Approved') {
              accessVarapprove.push(r)
            }
          })
          return accessVarapprove
        },
      },
      access: {
        default: [],
        lazy: true,
        async get() {
          this.enableBack = true
          const response = await store.api.get({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/user/${store.user.appName}/requestedUsers`,
          })
          if (response.status === 404) {
            const error = new Error('Not found')
            error.status = 404
            throw error
          }
          if (response.message === 'Unauthorized') {
            const error = new Error('Unauthorized')
            error.status = 401
            throw error
          }
          let accessVar1 = []
          response.map(r => {
            if (r.status === 'Requested') {
              accessVar1.push(r)
            }
          })
          return accessVar1
        },
      },
      denied: {
        default: [],
        lazy: true,
        async get() {
          this.enableBack = true
          const response = await store.api.get({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/user/${store.user.appName}/requestedUsers`,
          })
          if (response.status === 404) {
            const error = new Error('Not found')
            error.status = 404
            throw error
          }
          if (response.message === 'Unauthorized') {
            const error = new Error('Unauthorized')
            error.status = 401
            throw error
          }
          let accessVar = []
          response.map(r => {
            if (r.status === 'Denied') {
              accessVar.push(r)
            }
          })
          // console.log(response)
          return accessVar
        },
      },
      groups: {
        default: [],
        lazy: true,
        async get() {
          this.enableBack = true
          const response = await store.api.get({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/group/${this.applicationName}/groups`,
          })
          if (response.status === 404) {
            const error = new Error('Not found')
            error.status = 404
            throw error
          }
          if (response.message === 'Unauthorized') {
            const error = new Error('Unauthorized')
            error.status = 401
            throw error
          }
          return response
        },
      },
      allApnames: {
        default: [],
        lazy: true,
        async get() {
          const response = await store.api.get({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/application/all`,
          })
          if (response.status === 404) {
            const error = new Error('Not found')
            error.status = 404
            throw error
          }
          if (response.message === 'Unauthorized') {
            const error = new Error('Unauthorized')
            error.status = 401
            throw error
          }
          return response
        },
      },
      issues: {
        default: [],
        lazy: true,
        async get() {
          const response = await store.api.get({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/issue/all?application=${store.user.appName}`,
          })
          if (response.status === 404) {
            const error = new Error('Not found')
            error.status = 404
            throw error
          }
          if (response.message === 'Unauthorized') {
            const error = new Error('Unauthorized')
            error.status = 401
            throw error
          }
          return response
        },
      },
      topics: {
        default: [],
        lazy: true,
        async get() {
          const response = await store.api.get({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/${store.user.appName}/compare/topics`,
          })
          if (response.status === 404) {
            const error = new Error('Not found')
            error.status = 404
            throw error
          }
          if (response.message === 'Unauthorized') {
            const error = new Error('Unauthorized')
            error.status = 401
            throw error
          }
          return response
        },
      },
      messages: {
        default: [],
        lazy: true,
        async get() {
          const response = await store.api.get({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/${store.user.appName}/compare/topics/${store.user.topic}/${store.user.eventType}`,
          })
          if (response.status === 404) {
            const error = new Error('Not found')
            error.status = 404
            throw error
          }
          if (response.message === 'Unauthorized') {
            const error = new Error('Unauthorized')
            error.status = 401
            throw error
          }
          return response
        },
      },
    },
    methods: {
      async refresh() {
        await this.$asyncComputed.items.update()
      },
      async refreshUsers() {
        await this.$asyncComputed.users.update()
      },
      async refreshVendors() {
        await this.$asyncComputed.vendors.update()
      },
      async refreshDenied() {
        await this.$asyncComputed.denied.update()
      },
      async refreshAccess() {
        await this.$asyncComputed.access.update()
      },
      async refreshGroups() {
        await this.$asyncComputed.groups.update()
      },
      async refreshIssues() {
        await this.$asyncComputed.issues.update()
      },
      async refreshTopics() {
        await this.$asyncComputed.topics.update()
      },
      async refreshMessages() {
        await this.$asyncComputed.messages.update()
      },
      async fetchCompareMessages(topic, type) {
        const response = await store.api.get({
          url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/${store.user.appName}/compare/topics/${topic}/${type}`,
        })
        return response.messages || []
      },
      async fetchCompareTopics() {
        const response = await store.api.get({
          url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/${store.user.appName}/compare/topics`,
        })
        return response || []
      },
      async fetchApplications() {
        const response = await store.api.get({
          url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/application/all`,
        })
        return response || []
      },
      async fetchUsers() {
        const response = await store.api.get({
          url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/user/${store.user.appName}/users`,
        })
        return response || []
      },
      async fetchVendors() {
        const response = await store.api.get({
          url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/user/${store.user.appName}/vendors`,
        })
        return response || []
      },
      async fetchGroups() {
        const response = await store.api.get({
          url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/group/${this.applicationName}/groups`,
        })
        return response || []
      },
      async search(searchTerm) {
        const response = await store.api.get({
          url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/user/${store.user.appName}/users?term=${searchTerm}`,
        })
        return response
      },
      async searchVendor(searchTerm) {
        const response = await store.api.get({
          url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/user/${store.user.appName}/vendors?term=${searchTerm}`,
        })
        return response
      },
      async searchUserDetail(searchTerm) {
        const response = await store.api.get({
          url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/user/all/users?term=${searchTerm}`,
        })
        return response
      },
      async addUserToApp(application, id, type, val = null) {
        if (application && id) {
          let furl
          if (type) {
            furl = `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/application/${application}/${id}?auth=${type}`
          } else {
            furl = `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/application/${application}/${id}`
          }
          const response = await store.api.post({
            url: furl,
          })
          if (!val) {
            store.user.message = 'User has been added to the application'
          }
          return response || []
        }
        return []
      },
      async statusDataDenied(application, id, comments, noteToUser, email) {
        if (application && id) {
          const response = await store.api.post({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/application/${application}/${id}/deny`,
            body: {
              // userStatus: 'Denied',
              comments: comments,
              noteToUser: noteToUser,
              email: email,
            },
          })
          store.user.message = 'User has been denied from the access'
          return response || []
        }
        return []
      },
      async statusData(application, id) {
        if (application && id) {
          const response = await store.api.put({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/user/${application}/${id}`,
            body: {
              userStatus: 'Approved',
            },
          })
          store.user.message = 'Approved the access'
          return response || []
        }
        return []
      },
      async delUserFromApp(application, id) {
        if (application && id) {
          const response = await store.api.delete({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/application/${application}/${id}`,
          })
          store.user.message = 'User has been deleted from the application'
          return response || []
        }
        return []
      },
      async addGrpToApp(groupId, userId) {
        const response = await store.api.post({
          url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/group/users/${userId}`,
          body: groupId,
        })
        store.user.message = 'Group has been added to the user'
        this.applications = await response
        return response || []
      },
      async delGrpFrmApp(groupId, userId) {
        let response = []
        if (groupId) {
          response = await store.api.delete({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/group/${groupId}/users/${userId}`,
          })
          store.user.message = 'User has been removed from the group'
        }
        // this.groups = response
        return response || []
      },
      setCurrentPC9(pc9Data) {
        this.pc9Data = pc9Data
      },
      async getAllPC9s(assorts) {
        const promises = assorts.map(async assort => {
          const response = await store.api.get({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/assortment/${assort.assortmentId}/pc9/all`,
          })

          return response
        })

        const results = await Promise.all(promises)
        this.allPC9s = [...results]
        return results
      },
      async updateIssue(assignedTo, date, status, number) {
        await store.api.put({
          url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/issue/${number}`,
          body: {
            assignedTo: assignedTo,
            issueStatus: status,
            completedOn: date,
          },
        })
        this.refreshIssues()
        store.user.message = 'Updated the Issue'
        return []
      },
    },
  })
}

export default assort
