class HistoryCache {
  async match(request) {
    return false
  }

  async put(request, response) {
    return true
  }
}

export default HistoryCache
