import { format, formatISO, parseISO } from 'date-fns'

export function createTimestamp() {
  return formatISO(new Date())
}

export function formatTimestamp(isoString) {
  const date = parseISO(isoString)
  return format(date, 'MM/dd/yyyy, h:mm a')
}
