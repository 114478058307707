/* eslint-disable no-prototype-builtins */
import { OBJLoader2 } from 'three/examples/jsm/loaders/OBJLoader2'
import { RGBFormat, RGBAFormat, Texture } from 'three'
import { getGeometryMesh } from './rendering-utils.js'

const textureMapTypes = [
  'aoMap',
  'metalnessMap',
  'heightMap',
  'normalMap',
  'roughnessMap',
  'colorMap',
]

class AssetLoader {
  constructor({ fileLoader }) {
    this.fileLoader = fileLoader
    this.disposable = []
    this.loadGeometry = async url => {
      const response = await this.fileLoader.load({ url })
      const objLoader = new OBJLoader2()
      const content = await response.arrayBuffer()
      const geometry = objLoader.parse(content)
      this.disposable.push(geometry)
      return geometry
    }

    this.loadImage = async url => {
      const response = await this.fileLoader.load({ url })
      // console.log(this.fileLoader.headers)
      const blob = await response.blob()
      const image = await this.createImage(blob)
      return image
    }

    this.loadImageData = async url => {
      const response = await this.fileLoader.load({ url })
      let objectUrl = null
      if (
        response.url &&
        response.headers.get('content-type') !== 'text/html'
      ) {
        const blob = await response.blob()
        objectUrl = URL.createObjectURL(blob)
      }
      // const image = new Image()
      // image.src = objectUrl
      return objectUrl
    }

    this.loadImageFile = async url => {
      const response = await this.fileLoader.load({ url })
      return response
    }

    this.createImage = async blob => {
      const objectUrl = URL.createObjectURL(blob)
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => {
          resolve(image)
          // URL.revokeObjectURL(objectUrl)
        })
        image.addEventListener('error', reject)
        image.src = objectUrl
      })
    }

    this.loadTexture = async url => {
      const image = await this.loadImage(url)
      const texture = new Texture()
      texture.image = image
      texture.format = this.getTextureFormat(url)
      texture.needsUpdate = true
      this.disposable.push(texture)
      return texture
    }

    this.getTextureFormat = url => {
      if (url.includes('.jpg')) {
        return RGBFormat
      }
      return RGBAFormat
    }

    this.findApplicableMapUrls = maps => {
      return textureMapTypes.reduce((acc, type) => {
        if (maps.hasOwnProperty(type)) {
          acc.push({
            type,
            url: maps[type],
          })
        }
        return acc
      }, [])
    }

    this.loadTextures = async (
      material,
      maps,
      assetLoader,
      ignoreMissingMaps,
    ) => {
      const mapsToLoad = this.findApplicableMapUrls(maps)
      const promises = mapsToLoad.map(async map => {
        try {
          const texture = await this.loadTexture(map.url)
          const name = map.type === 'colorMap' ? 'map' : map.type
          material[name] = texture
          return texture
        } catch (error) {
          if (!ignoreMissingMaps && !map.url.includes('DAMAGE')) {
            throw error
          }
        }
      })
      return Promise.all(promises)
    }

    this.syncAssets = urls => {
      return urls.map(this.syncAsset.bind(this))
    }

    this.syncAsset = async url => {
      try {
        const response = await this.fileLoader.load({
          url,
          addToCache: true,
          skipCache: true,
        })
        return response
      } catch (error) {
        // NOTE: Certain types of fetch errors result in no response.
        // We create these fake responses for failed sync requests so
        // sync progress can be accurately tracked.
        const fakeResponse = {
          url,
          status: 499,
          statusText: 'Network Error',
        }
        return fakeResponse
      }
    }

    /*
    async clearSyncedAssets() {
      return this.fileLoader.cache.clear()
    }
    */

    this.setHeaders = headers => {
      // console.log(this, headers, 'headers set?')
      this.fileLoader.headers = headers
    }

    this.disposeAll = () => {
      this.disposable.forEach(item => {
        item.dispose?.()
        const child = getGeometryMesh(item)
        child?.dispose?.()
      })
      this.disposable = []
    }
  }
}

export default AssetLoader
