import Vue from 'vue'
import {
  baseFields,
  PC5Fields,
  PC9Fields,
  PC9SeasonFields,
} from '../util/gridHelper'
import axios from 'axios'

const unknownUser = {
  lscoUserName: '(Unknown User)',
}

const user = store => {
  return new Vue({
    data() {
      return {
        condition: 'global',
        appName: 'BOM', // HACK: Until we find a better way to set the current application
        userData: null,
        grpUsers: [],
        message: null,
        errormsg: null,
        save: false,
        isAdmin: false,
      }
    },
    computed: {
      isReady() {
        return (
          this.current &&
          this.current.lscoUserName &&
          this.current.lscoUserName.length
        )
      },
      role() {
        return this.current.role || store.codes.roles.view
      },
      getMostRecentFilter() {
        let recentFilter
        if (this.currentFilters.results.length > 0) {
          this.currentFilters.results.map(filter => {
            if (
              filter.assortmentId === store.assortments.currentAssortment &&
              store.auth.userName === filter.createdBy
            ) {
              recentFilter = filter
            }
          })
        }

        return recentFilter
      },
    },
    asyncComputed: {
      current: {
        default: {},
        lazy: true,
        async get() {
          try {
            const user = await store.api.get({
              url: '/api/me',
            })

            if (user.status === 404 || user.status === 401) {
              return { ...unknownUser }
            }
            this.isAdmin = user.isAdmin
            return user
          } catch (error) {
            return { ...unknownUser }
          }
        },
      },
      userDetails: {
        default: undefined,
        lazy: true,
        async get() {
          if (!this.userData) return []
          try {
            const response = await store.api.get({
              url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/user/all/${this.userData}`,
            })
            if (response.status === 404) {
              const error = new Error('Not found')
              error.status = 404
              throw error
            }
            if (response.message === 'Unauthorized') {
              const error = new Error('Unauthorized')
              error.status = 401
              throw error
            }
            return response
          } catch (err) {
            return []
          }
        },
      },
      listOfUsers: {
        default: undefined,
        lazy: true,
        async get() {
          try {
            const response = await store.api.get({
              url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/user/${this.appName}/users
              `,
            })
            if (response.status === 404) {
              const error = new Error('Not found')
              error.status = 404
              throw error
            }
            if (response.message === 'Unauthorized') {
              const error = new Error('Unauthorized')
              error.status = 401
              throw error
            }
            return response
          } catch (err) {
            return []
          }
        },
      },
      currentFilters: {
        default: undefined,
        lazy: true,
        async get() {
          try {
            // const filters = await store.api.get({
            // url: '/api/me/filters',
            // })

            if (user.status === 404) {
              return []
            }

            return true
          } catch (error) {
            return []
          }
        },
      },
    },
    created() {
      this.$asyncComputed.currentFilters.update()
    },
    methods: {
      isRole(role) {
        return this.role === role
      },
      async refreshUsersInfo() {
        await this.$asyncComputed.userDetails.update()
      },
      async addNewUser(id, params, type) {
        let response
        if (type) {
          store.assort.addUserToApp(this.appName, id, type)
        }
        try {
          response = await store.api.post({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/user/${this.appName}/${id}`,
            body: params,
          })
          this.message = 'User added Successfully'
        } catch (er) {
          // this.errormsg= "Null Error, try again!!"

          this.editUser(id, params)
        }
        return response || []
      },
      async editUser(id, params, type) {
        let response
        try {
          if (id) {
            response = await store.api.put({
              url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/user/${this.appName}/${id}`,
              body: params,
            })
            response.applications.map(app => {
              if (app.name !== 'NestRVT' && type) {
                store.assort.addUserToApp(app.name, id, type, 'edit')
              }
            })
            this.message = 'Success'
          } else {
            this.message = 'Provide ID'
          }
        } catch (er) {
          this.errormsg = 'Please add the user, Try again'
        }
        return response || []
      },
      async submitFilters(filters) {
        // change this to compute the submitted filters
        try {
          await store.api
            .post({
              url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/me/filters`,
              body: {
                ...filters,
                assortmentId: store.assortments.currentAssortment,
              },
            })
            .then(response => {
              if (response.filterId) {
                this.lastSaved = response.filterId
              }
            })
        } catch (ex) {
          // We can ignore the error if they added one they already have
          // on their list.
          if (ex.message !== '400') {
            throw ex
          }
        }
      },
      async fetchFilters() {
        await store.api
          .get({
            url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/assortment/${store.assortments.currentAssortment}/filters`,
          })
          .then(response => {
            if (response.results.length > 0) {
              // this.lastSaved = response.filterId
              let i = response.results.length - 1
              // for (let i = 0; i < response.results.length; i++) {

              PC9Fields.map(field => {
                if (response.results[i][field]) {
                  this.setFilter(field, response.results[i][field])
                }
              })
              PC5Fields.map(field => {
                if (response.results[i][field]) {
                  this.setFilter(field, response.results[i][field])
                }
              })
              PC9SeasonFields.map(field => {
                if (response.results[i][field]) {
                  this.setFilter(field, response.results[i][field])
                }
              })
              baseFields.map(field => {
                if (
                  field !== 'modifyStamp' &&
                  field !== 'pobjrefColorwayCode'
                ) {
                  if (response.results[i][field]) {
                    this.setFilter(field, response.results[i][field])
                  }
                }
              })
              // }
            }
          })
      },
      async uploadfile(url, file) {
        const promise = await axios.put(url, file, {
          headers: {
            'Content-Type': 'plain/text',
          },
        })
        return promise
      },
    },
  })
}

export default user
