<template>
  <div>
    <div class="flex mb-5">
      <label class="pt-5 pl-4 w-70"> <b>Group Name</b> </label>
      <input class="ml-40 inputText" type="text" />
    </div>
    <div class="flex mb-5">
      <label class="pt-5 pl-4 w-70"> <b>Parent</b> </label>
      <div class="ml-32">
        <div class="w-70 ml-10" @change="parentType">
          <div class="float-left">
            <input id="yes" type="radio" name="grpType" value="Yes" checked />
            <label for="yes">Yes</label>
          </div>
          <div class="ml-20">
            <input id="no" type="radio" name="grpType" value="No" />
            <label for="no">No</label>
          </div>
        </div>
      </div>
    </div>
    <div v-if="parentGroup === 'Yes'" class="flex mb-5">
      <label class="pt-5 pl-4 w-70"> <b>Sub Groups</b> </label>
      <input class="ml-40 inputText" type="text" />

      <button class="CTAButton bg-transparent ml-12" @click="addChildGroup">
        Add Child Group
      </button>
    </div>
    <div v-else class="flex mb-5">
      <label class="pt-5 pl-4 w-70"> <b>Parent Group</b> </label>
      <input class="ml-40 inputText" type="text" />
    </div>
    <div class="flex mb-5">
      <label class="pt-5 pl-4 w-70"> <b>Group Id</b> </label>
      <input class="ml-40 inputText" type="text" />
    </div>
    <div class="flex mb-5">
      <label class="pt-5 pl-4 w-70"> <b>Organization</b> </label>
      <select id="org" class="transparentSelect w-70" name="org">
        <option class="trans" value="Organization1">Organization1</option>
        <option class="trans" value="Organization2">Organization2</option>
        <option class="trans" value="Organization3">Organization3</option>
        <option class="trans" value="Organization4">Organization4</option>
      </select>
    </div>
    <div class="flex mb-5">
      <label class="pt-5 pl-4 w-70"> <b>Application</b> </label>
      <input class="ml-40 inputText" type="text" />
    </div>
    <span v-if="parentGroup === 'Yes'">
      <div class="flex mb-5">
        <label class="pt-5 pl-4 w-70"> <b>Admin Name</b> </label>
        <input class="ml-40 inputText" type="text" />
      </div>
      <div class="flex mb-5">
        <label class="pt-5 pl-4 w-70"> <b>Admin E-mail</b> </label>
        <input class="ml-40 inputText" type="text" />
      </div>
    </span>
  </div>
</template>

<script>
export default {
  name: 'UserDetails',
  inject: ['store'],
  data() {
    return {
      parentGroup: 'Yes',
    }
  },
  mounted() {
    this.store.user.grpUsers = []
  },
  methods: {
    parentType(val) {
      this.parentGroup = val.target.value
    },
    addChildGroup() {
      // this.parentGroup = false
    },
  },
}
</script>
<style scoped>
input[type='text'] {
  margin-top: 3px;
  width: 30%;
  height: 40px;
  border-style: groove;
  border-width: 1px;
  box-sizing: border-box;
  background-color: transparent;
  color: black;
}
.transparentSelect {
  background-color: transparent;
}
.trans {
  background: #e5e5e5;
}
</style>
