var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex autocompleteUser"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userId),expression:"userId"}],staticClass:"ml-40",attrs:{"type":"text"},domProps:{"value":(_vm.userId)},on:{"input":[function($event){if($event.target.composing)return;_vm.userId=$event.target.value},_vm.onChange],"change":_vm.mousserLeave}})]),_c('div',{staticClass:"flex mb-5 mt-5"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fullName),expression:"fullName"}],staticClass:"ml-40",attrs:{"type":"text"},domProps:{"value":(_vm.fullName)},on:{"input":function($event){if($event.target.composing)return;_vm.fullName=$event.target.value}}})]),_vm._m(2),_c('div',{staticClass:"flex mb-5"},[_vm._m(3),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"ml-40",attrs:{"type":"text"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('div',{staticClass:"flex mb-5"},[_vm._m(4),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],staticClass:"ml-40",attrs:{"type":"text"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.phone=$event.target.value}}})]),_c('div',{staticClass:"flex mb-5"},[_vm._m(5),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],staticClass:"ml-40",attrs:{"type":"text"},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing)return;_vm.address=$event.target.value}}})]),_c('div',{staticClass:"flex mb-5"},[_vm._m(6),_vm._l((_vm.applications),function(app){return _c('p',{key:app,staticClass:"borderBox"},[_vm._v(" "+_vm._s(app)+" ")])})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"pt-5 pl-4 w-70"},[_c('b',[_vm._v("User Id "),_c('span',{staticClass:"mandatory"},[_vm._v("*")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"pt-5 pl-4 w-70"},[_c('b',[_vm._v("Name "),_c('span',{staticClass:"mandatory"},[_vm._v("*")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex mb-5"},[_c('label',{staticClass:"pt-5 pl-4 w-70"},[_c('b',[_vm._v("Organization")])]),_c('select',{staticClass:"transparentSelect w-70",attrs:{"id":"org","name":"org"}},[_c('option',{staticClass:"trans",attrs:{"value":"Organization1"}},[_vm._v("Organization1")]),_c('option',{staticClass:"trans",attrs:{"value":"Organization2"}},[_vm._v("Organization2")]),_c('option',{staticClass:"trans",attrs:{"value":"Organization3"}},[_vm._v("Organization3")]),_c('option',{staticClass:"trans",attrs:{"value":"Organization4"}},[_vm._v("Organization4")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"pt-5 pl-4 w-70"},[_c('b',[_vm._v("Email "),_c('span',{staticClass:"mandatory"},[_vm._v("*")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"pt-5 pl-4 w-70"},[_c('b',[_vm._v("Phone Number")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"pt-5 pl-4 w-70"},[_c('b',[_vm._v("Address")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"pt-5 pl-4 w-70"},[_c('b',[_vm._v("Applications")])])
}]

export { render, staticRenderFns }