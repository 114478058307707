import AssetLoader from './AssetLoader.js'
import createPlatformCache from './create-platform-cache.js'
import FileLoader from './FileLoader.js'

export default async () => {
  const cache = await createPlatformCache()
  const fileLoader = new FileLoader({ cache })
  const assetLoader = new AssetLoader({ fileLoader })
  return assetLoader
}
