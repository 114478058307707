<template>
  <div
    v-if="isReady"
    ref="gridBox"
    class="gridlist grid-Scroll grid-ScrollList"
  >
    <DeleteUser ref="delUser">
      Are you sure you want to delete this User from this application?
    </DeleteUser>
    <SubGroupList ref="subgrplist" @confirmed="confirmed()">
      Add sub group
    </SubGroupList>
    <ag-grid-vue
      :key="changeVal"
      :style="{ width, height }"
      class="ag-theme-fresh"
      :row-height="rowHeight"
      :get-row-height="getRowHeight"
      :default-col-def="defaultColDef"
      :column-defs="columnDefs"
      :pagination="true"
      :pagination-page-size="paginationPageSize"
      :row-data="rowData"
      :modules="modules"
      :row-class-rules="rowClassRules"
      @cell-clicked="onCellClick"
      @grid-ready="onGridReady"
    />
  </div>
  <Spinner v-else />
</template>
<script>
import 'ag-grid-community/dist/styles/ag-theme-fresh.css'
import { AgGridVue } from '@ag-grid-community/vue'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { RichSelectModule } from '@ag-grid-enterprise/rich-select'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import Spinner from '../components/Spinner.vue'
import DeleteUser from '../components/Modal/DeleteUser.vue'
import SubGroupList from '../components/Modal/SubGroupList.vue'

export default {
  components: {
    AgGridVue,
    Spinner,
    DeleteUser,
    SubGroupList,
  },
  inject: ['store'],
  data() {
    return {
      rowData: [],
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      width: '100%',
      height: '80vh',
      rowSelection: 'multiple',
      rowClassRules: null,
      modules: [
        SetFilterModule,
        ClientSideRowModelModule,
        MenuModule,
        ColumnsToolPanelModule,
        RichSelectModule,
        AllModules,
      ],
      title: '',
      diff: [],
      rowHeight: 150,
      changeVal: null,
      index: 0,
      allVal: false,
      parentAllGroup: [],
      subGrpLink: null,
      prntGrplink: null,
      Groups: null,
      name: null,
      selectedParentGrp: null,
      selectedChildGrp: null,
      delResp: null,
      addResp: null,
      parentIndex: 0,
      saveIcon: false,
      heightLen: 0,
    }
  },
  computed: {
    isAppName() {
      return this.store.user.appName
    },
    allGroupNames() {
      return this.store.assort.groups
    },
    isReady() {
      if (!this.store.user.userDetails?.applications) {
        return true
      }
      return this.store.user.userDetails?.applications
    },
    updatedGroup() {
      return this.store.user.userDetails?.applications
    },
  },
  watch: {
    isReady(newVal, oldVal) {
      if (newVal) {
        this.addingApp()
      }
    },
    delResp(newVal, oldVal) {
      if (newVal) {
        this.allVal = false
        this.changeVal = this.store.assort.applicationName + 'del'
      }
    },
    async updatedGroup(newVal, oldVal) {
      if (newVal) {
        this.addResp = await this.store.user.userDetails
        this.delResp = null
        this.allVal = false
        //  this.allVal = true

        this.changeVal = this.store.assort.applicationName + 'updatedVal'
      }
    },
    addResp(newVal, oldVal) {
      if (newVal) {
        this.allVal = false
        if (this.saveIcon) {
          this.changeVal = this.store.assort.applicationName + 'add'
        } else {
          this.changeVal = this.store.assort.applicationName
        }
      }
    },
    allGroupNames(newVal, oldVal) {
      if (newVal && this.changeVal) {
        this.allVal = true
        this.Groups = this.store.assort.groups
        this.changeVal = this.store.assort.applicationName
      } else {
        this.allVal = false
      }
    },
    isAppName(newVal, oldVal) {
      if (newVal) {
        this.addingApp()
      }
    },
  },
  beforeMount() {
    this.gridOptions = {
      // floatingFiltersHeight: 40,
      // headerHeight: 32,
      // suppressScrollOnNewData: true,
    }
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      cellStyle: {
        'background-color': '#E5E5E5',
        transform: 'translateY(0px)',
      },
      // filter: 'agTextColumnFilter',
      // floatingFilter: true,
    }
    this.paginationPageSize = 10
    this.paginationNumberFormatter = params => {
      return '[' + params.value.toLocaleString() + ']'
    }
    this.addingApp()
    this.columnDefs = [
      {
        headerName: 'Application',
        field: 'name',
        width: 150,
      },
      {
        headerName: 'Group',
        // field: 'data.applications[0].name',
        width: 190,
        cellStyle: this.cellStyle,
        cellRenderer: this.group.bind(this),
        // cellEditorParams: this.values.bind(this)
      },
      {
        headerName: 'Sub Group',
        // field: 'email',
        width: 190,
        cellStyle: this.cellStyle,
        cellRenderer: this.subgroup.bind(this),
      },
      {
        headerName: '',
        field: '',
        cellClass: 'cellAlign',
        cellRenderer: this.createElement.bind(this),
        width: 35,
        filter: false,
      },
      {
        headerName: '',
        field: '',
        cellClass: 'cellAlign',
        cellRenderer: this.deleteUser.bind(this),
        minWidth: 80,
        maxWidth: 80,
        filter: false,
      },
    ]
  },
  mounted() {
    this.addingApp()
  },
  methods: {
    onGridReady(params) {
      setTimeout(() => {
        params.api.redrawRows()
      }, 100)
    },
    getRowHeight(params) {
      let rowLength = 100
      let totalLen = 0
      // params.data.groups?.map(app => {
      //  console.log(app)
      totalLen = totalLen + params.data.groups?.length * 25
      // totalLen = totalLen + app.groupList.length
      // })
      rowLength = rowLength + totalLen
      return rowLength
    },
    onCellClick(event) {
      if (event.column.colDef.headerName === 'Full Name') {
        this.title = event.value
        this.openModal()
      }
    },
    addingApp() {
      if (this.store.user.userDetails?.applications) {
        this.rowData = this.store.user.userDetails?.applications
      } else {
        this.rowData = []
      }
      let applicationName = []
      this.store.assort.allApnames.map(app => {
        applicationName.push(app.name)
      })
      let names = []
      this.rowData?.map(row => {
        names.push(row.name)
      })
      this.diff = applicationName.filter(x => !names.includes(x))

      this.diff.map(val => {
        this.rowData.push({ name: val, status: 'disabled' })
      })
    },
    cellStyle(params) {
      return {
        backgroundColor: '#E5E5E5',
        //   color: '#7c7c7c',
      }
    },
    subgroup(event) {
      let subgrp = []
      let deleteVal = false
      let subAllGroupId = []
      let subAllGrpId = []
      let allId = []
      let sbgrpsel = null
      let updatedVal = event.data
      if (this.delResp || this.addResp) {
        let val = this.delResp ? this.delResp : this.addResp
        val.applications?.map(app => {
          if (app.name === event.data.name) {
            updatedVal = app
          }
        })
      }
      try {
        updatedVal.groups.map(eve => {
          if (eve.isParentLinked) {
            subAllGrpId.push({ name: 'All Child Groups', id: eve.groupId })
            subgrp.push('All Child Groups')
            allId.push(eve.groupId)
          } else {
            eve.groupList.map(sbgrp => {
              subAllGrpId.push({ name: sbgrp.name, id: sbgrp.groupId })
              allId.push(sbgrp.name)
              subgrp.push(sbgrp.name)
            })
          }
        })
        this.heightLen = subgrp.length
      } catch (er) {
        subgrp = []
      }
      var link = document.createElement('a')
      // let parentGroup = ['Sub Group 1', 'Sub Group 2', 'Sub Group 3']
      if (
        this.changeVal === event.data.name ||
        event.data.name + 'plus' === this.changeVal ||
        event.data.name + 'del' === this.changeVal
      ) {
        if (!this.allVal) {
          if (event.data.name + 'plus' === this.changeVal) {
            let subGroups = this.Groups
            subGroups.map(gp => {
              gp.groupList.map(li => {
                subgrp.push(li.name)
              })
            })
          }
          // let options = subgrp.map((gp, i) => {
          //   if (gp === subgrp[this.index]) {
          //     return `<option selected class='text-black'>${gp}</option>`
          //   } else {
          //     return `<option class='text-black'>${gp}</option>`
          //   }
          // })
          subgrp.map((gp, i) => {
            this.index = i
            subgrp.map((gp, i) => {
              if (gp === subgrp[this.index]) {
                sbgrpsel = gp
                return `<option selected class='text-black'>${gp}</option>`
              } else {
                return `<option class='text-black'>${gp}</option>`
              }
            })
            link.innerHTML =
              link.innerHTML +
              `<span class='h-1.5 pt-1 pb-1 w-32  text-black flex'>
             <div class=''> ${sbgrpsel} </div>
        
        <p class=" ${i} fa subgrps fa-trash inline-block pt-2 pl-4" ></p>
        </span>
        `
            let delsubGrpId = null
            link.addEventListener('click', async event => {
              deleteVal = true
              let index = event.target.classList[0]
              subAllGrpId.map(p => {
                // if (p.name === 'All') {
                //   if (allId[index] === p.id) {
                //     console.log(p.id)
                //     delsubGrpId = p.id
                //     i++
                //   }
                // } else {
                if (subgrp[index] === p.name && p.name !== 'All Child Groups') {
                  delsubGrpId = p.id
                  i++
                  // }
                }
                if (p.name === 'All Child Groups') {
                  if (allId[index] === p.id) {
                    delsubGrpId = p.id
                    i++
                  }
                }
              })
              this.addResp = null
              if (i === 1) {
                this.delResp = await this.store.assort.delGrpFrmApp(
                  delsubGrpId,
                  this.store.user.userData,
                )
                allId = []
              }
              this.changeVal = null
            })
          })
          this.subGrpLink = link.innerHTML
        } else {
          let subAllGroup = ['Select Sub Group']
          this.Groups.map(gp => {
            gp.groupList.map(li => {
              subAllGroupId.push({ name: li.name, id: li.groupId })
              subAllGroup.push(li.name)
            })
          })
          // let allOptions = [...new Set(subAllGroup)].map(op => {
          // return `<option class='text-black'>${op}</option>`
          // })
          //   link.innerHTML =
          //     this.subGrpLink +
          //     `<span class='flex'>
          // <select class='h-0.5 pt-1 pb-1 flex text-black' >
          // ${allOptions}
          // </select>
          // </span>
          // `

          link.innerHTML =
            this.subGrpLink +
            `<span class='flex pr-16'>
            <div class="subgrpcl">Select Subgroup</div>
        </span>
        `
        }
      } else {
        subgrp.map(gp => {
          link.innerHTML =
            link.innerHTML + `<i class="fa pb-2 flex text-black" >${gp}</i>`
        })
      }
      link.addEventListener('click', ev => {
        this.store.assort.subAllGroupId = subAllGroupId
        if (!deleteVal) {
          // && event.data.name + 'plus' === this.changeVal) {
          this.$refs.subgrplist.open()
          this.saveIcon = false
        }
        subAllGroupId.map(id => {
          if (ev.target.value === id.name) {
            this.selectedChildGrp = id.id
          }
        })
      })
      link.addEventListener('change', event => {
        subAllGroupId.map(id => {
          if (event.target.value === id.name) {
            this.selectedChildGrp = id.id
          }
        })
      })
      if (event.data.status === 'Active') {
        return link
      } else {
        return ''
      }
    },
    group(event) {
      let parentGroup = []
      let AllGroupId = []
      let corrSubGrp = []
      let subGroups = []
      let gpsel = null
      let updatedVal = event.data
      if (this.delResp || this.addResp) {
        let val = this.delResp ? this.delResp : this.addResp
        val.applications?.map(app => {
          if (app.name === event.data.name) {
            updatedVal = app
          }
        })
      }
      try {
        updatedVal.groups.map(app => {
          parentGroup.push(app.name)
        })
      } catch (er) {
        parentGroup = []
      }
      var link = document.createElement('a')
      // let parentGroup = ['Sub Group 1', 'Sub Group 2', 'Sub Group 3']
      if (
        this.changeVal === event.data.name ||
        event.data.name + 'plus' === this.changeVal ||
        event.data.name + 'del' === this.changeVal
      ) {
        if (!this.allVal) {
          if (event.data.name + 'plus' === this.changeVal) {
            this.Groups.map(gp => {
              this.parentAllGroup.push(gp.name)
            })
          }
          // let finalGroupa = [...new Set(parentGroup)]
          parentGroup.map((gp, i) => {
            this.parentIndex = i
            parentGroup.map((gp, i) => {
              if (gp === parentGroup[this.parentIndex]) {
                gpsel = gp
                return `<option selected class='text-black'>${gp}</option>`
              } else {
                return `<option class='text-black'>${gp}</option>`
              }
              // return `<option class='text-black'>${gp}</option>`
            })
            link.innerHTML =
              link.innerHTML +
              `<p class='h-0.5 pt-1  pb-1 flex text-black'> ${gpsel} </p>`
          })
          this.prntGrplink = link.innerHTML
        } else {
          let parentAllGroupName = [['Select Group']]
          this.Groups?.map(gp => {
            AllGroupId.push({ name: gp.name, id: gp.groupId })
            parentAllGroupName.push(gp.name)
          })
          let allOptions = parentAllGroupName.map(op => {
            return `<option class='text-black'>${op}</option>`
          })
          link.innerHTML =
            this.prntGrplink +
            `
        <select class='h-0.5 pt-1 pb-1 flex text-black'>
         ${allOptions}
        </select>
        `
        }
      } else {
        //  let finalGroup = [...new Set(parentGroup)]
        parentGroup.map(gp => {
          link.innerHTML =
            link.innerHTML + `<i class="fa pb-2 flex text-black" >${gp}</i>`
        })
      }
      this.Groups?.map(gp => {
        gp.groupList.map(cgp => {
          corrSubGrp.push({
            name: gp.name,
            childName: cgp.name,
          })
        })
      })
      link.addEventListener('click', event => {
        subGroups = []
        corrSubGrp.map(cor => {
          if (cor.name === event.target.value) {
            subGroups.push(cor.childName)
          }
        })
        this.store.assort.correspondingSubGrp = subGroups
        AllGroupId.map(id => {
          if (event.target.value === id.name) {
            this.store.assort.parentGrpId = []
            this.store.assort.parentGrpId.push(id.id)
            this.store.assort.parentName = id.name
            this.selectedParentGrp = id.id
          }
        })
      })
      link.addEventListener('change', event => {
        subGroups = []
        corrSubGrp.map(cor => {
          if (cor.name === event.target.value) {
            subGroups.push(cor.childName)
          }
        })
        this.store.assort.correspondingSubGrp = subGroups
        AllGroupId.map(id => {
          if (event.target.value === id.name) {
            this.store.assort.parentGrpId = []
            this.selectedParentGrp = id.id
            this.store.assort.parentName = id.name
            this.store.assort.parentGrpId.push(id.id)
          }
        })
      })
      if (event.data.status === 'Active') {
        return link
      } else {
        return ''
      }
    },
    createElement(event) {
      let ltype = null
      if (event.data.status === 'disabled') {
        let app = document.createElement('a')
        app.innerHTML = '<i class="fa fa-plus" style="color:black"></i>'
        app.addEventListener('click', async () => {
          this.store.user.userDetails.applications.map(app => {
            if (app.name !== 'NestRVT') {
              if (app.authProvider) {
                ltype = app.authProvider
              }
            }
          })
          await this.store.assort.addUserToApp(
            event.data.name,
            this.store.user.userData,
            ltype,
          )

          this.store.user.refreshUsersInfo()
          // this.store.user.condition = 'newUser'
          // this.store.user.userData = data.data.userId
        })
        return app
      }
      var link = document.createElement('a')
      link.innerHTML = '<i class="fa fa-pencil"  ></i>'
      link.addEventListener('click', () => {
        if (event.data.name === this.changeVal) {
          // this.parentGroup.push('Parent Group 4')
          // this.subGroup.push('Sub Group 4')

          this.allVal = true
          this.name = event.data.name + 'plus'
          this.changeVal = event.data.name + 'plus'
        } else {
          this.allVal = false
          this.store.assort.applicationName = event.data.name
          this.Groups = this.store.assort.groups
          this.changeVal = event.data.name
        }

        // this.store.user.condition = 'newUser'
        // this.store.user.userData = data.data.userId
      })
      if (
        event.data.name === this.changeVal ||
        event.data.name + 'plus' === this.changeVal ||
        event.data.name + 'del' === this.changeVal
      ) {
        link.innerHTML = '<i class="fa fa-plus" style="color:black"></i>'
        return link
      }
      return link
    },
    deleteUser(event) {
      if (event.data.status === 'disabled') {
        return null
      }
      var link = document.createElement('a')
      link.innerHTML = '<i class="fa fa-trash" ></i>'
      link.addEventListener('click', async () => {
        if (
          event.data.name === this.changeVal ||
          event.data.name + 'plus' === this.changeVal ||
          event.data.name + 'del' === this.changeVal
        ) {
          this.delResp = null
          await this.store.user.refreshUsersInfo()
          this.addResp = await this.store.user.userDetails
          this.changeVal = null
          this.saveIcon = true
        } else {
          await this.$refs.delUser.open(event.data)
        }

        // this.store.user.condition = 'newUser'
        // this.store.user.userData = data.data.userId
      })
      if (
        event.data.name === this.changeVal ||
        event.data.name + 'plus' === this.changeVal ||
        event.data.name + 'del' === this.changeVal
      ) {
        link.innerHTML = '<i class="fas fa-save" style="color:black"></i>'
        return link
      }
      return link
    },
    async save() {
      this.delResp = null
      // this.addResp = await this.store.assort.addGrpToApp(
      //   this.selectedChildGrp,
      //   this.store.user.userData,
      // )
      this.changeVal = null
    },
    confirmed() {
      this.store.user.refreshUsersInfo()
      this.changeVal = this.store.assort.applicationName
    },
    openModal() {
      this.$refs.userModal.open()
    },
  },
}
</script>
<style lang="scss">
// .ag-header-cell-label,
// .ag-header-row,
// .ag-theme-fresh .ag-row-odd {
//   background-color: #fff;
//   background: none;
// }
.ag-body-viewport .ag-center-cols-container,
.ag-theme-fresh .ag-body-viewpor,
.ag-center-cols-viewport,
.ag-center-cols-clipper,
.ag-center-cols-clipper,
.ag-theme-fresh .ag-row-even,
.ag-theme-fresh .ag-paging-panel,
.ag-header-viewport,
.ag-header-container,
.ag-header-cell-label,
.ag-header-row {
  background-color: #e5e5e5 !important;
}
.ag-header-cell-label .ag-header-cell-text {
  color: #050505;
  font-size: 14px;
  font-weight: 700;
}
.ag-theme-fresh .ag-root-wrapper {
  border: none;
}
.ag-theme-fresh .ag-header-cell {
  border-right: none;
}
.ag-theme-fresh .ag-header-cell::after,
.ag-theme-fresh .ag-header-group-cell::after {
  content: none;
}
.ag-row-position-absolute {
  position: relative;
  margin: 20px 0;
}
#page-size {
  width: 60px;
}
ul.nav {
  border-bottom: 2px solid #d8d8d8;
  margin-top: 20px;
  margin-bottom: 40px;
}
ul.nav li {
  display: inline-block;
  font-size: 16px;

  a.router-link-active {
    color: #c21a31;
    border-left: 0 !important ;
    margin-right: 20px;
    border-bottom: 2px solid;
  }
}
a {
  color: #d8d8d8;
}
img {
  float: left;
  margin: 0 5px;
}
.ag-paging-row-summary-panel {
  position: absolute;
  left: 0;
}
.ag-paging-page-summary-panel .ag-paging-button button {
  position: relative;
}
.pages {
  width: 100%;
  background-color: #e5e5e5;
}
.button-top {
  position: fixed;
  top: 22px;
  right: 35px;
}
select.text-black {
  font-size: 13px;
  height: 28px;
  background-color: transparent;
  margin-top: 10px;
  margin-right: 30px;
}
.subgrpcl {
  color: #000;
  border: 1px solid #ccc;
  width: 144px;
  height: 29px;
  padding: 4px 0 0 13px;
  margin-top: 9px;
}
.subgrps {
  position: absolute;
  left: 80%;
}
</style>
