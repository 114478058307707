<template>
  <div id="app" @click="onClick">
    <div v-if="isSignInFlow">
      <router-view />
    </div>
    <!--<Spinner v-else-if="isLoading" class="stretch" />-->
    <div v-else-if="isSignedIn">
      <main>
        <Header v-if="!isIpadPath" />
        <div class="pages overflow-auto">
          <UserGrid v-if="store.user.condition === 'global'" />
          <AddUser v-else-if="store.user.condition === 'newUser'" />
          <GroupDetails v-else-if="store.user.condition === 'newGroup'" />
          <AddVendor v-else />
          <!-- <router-view :key="$route.fullPath" /> -->
        </div>
        <!-- <UserGrid class="aggrid" /> -->
        <Footer :key="`footermain-${componentKey}-${footerKey}`" />
      </main>
      <Popups v-if="store.user.message || store.user.errormsg" />

      <portal-target name="modal" />
      <portal-target name="context-menu" class="context-menu-portal-target" />
      <portal-target name="auto-complete" class="auto-complete-portal-target" />
      <!-- <Alerts /> -->
    </div>
    <div v-else-if="isTokenUnauthorized">
      {{ showUnauthorizedError() }}
    </div>
    <div v-else>
      {{ showUnknownError() }}
    </div>
  </div>
</template>

<script>
// import Alerts from './components/Alerts.vue'
// import Footer from './components/Footer.vue'
import Header from './components/Header.vue'
import UserGrid from './views/UsersGrid.vue'
import AddUser from './views/AddUser.vue'
import AddVendor from './views/AddVendor.vue'
import GroupDetails from './views/GroupDetails.vue'
import Popups from './views/Popups.vue'
// import Spinner from './components/Spinner.vue'

export default {
  components: {
    UserGrid,
    // Alerts,
    // Footer,
    Header,
    AddUser,
    AddVendor,
    GroupDetails,
    Popups,
    // Spinner,
  },
  inject: ['store'],
  computed: {
    componentKey() {
      return this.store.assortUser.componentKey
    },
    footerKey() {
      return this.store.addGlobalAssortment.footerKey
    },
    isLoading() {
      return this.store.seasons.isLoading
    },
    isSignedIn() {
      return this.store.auth.hasValidTokens()
    },
    isSignInFlow() {
      return (
        this.store.router.path === '/sign-in' ||
        this.store.router.path === '/auth-callback'
      )
    },
    isTokenUnauthorized() {
      return this.store.applications.isUnauthorized
    },
    isIpadPath() {
      if (this.store.router.path === '/ipad') {
        return true
      }
      return false
    },
  },
  created() {
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('load', this.clearStorage)
  },
  mounted() {
    // Clear the browser cache data in localStorage when closing the browser window
    window.onbeforeunload = function (e) {
      if (this.store) {
        this.store.session.clear()
        sessionStorage.clear()
        // localStorage.clear()
      }
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onClick(event) {
      this.store.contextMenu.hide()
    },
    onScroll() {
      this.store.contextMenu.hide()
      this.store.lookups.hide()
    },
    showUnauthorizedError() {
      this.$router.replace({
        path: '/auth-callback',
        query: {
          error: 'ACCESS_TOKEN_UNAUTHORIZED',
        },
      })
    },
    showUnknownError() {
      this.$router.replace({
        path: '/auth-callback',
        query: {
          error: 'UNKNOWN_ERROR',
        },
      })
    },
    clearStorage() {
      let session = sessionStorage.getItem('ref')
      if (session == null) {
        // localStorage.clear()
      }
      sessionStorage.setItem('ref', 1)
    },
  },
}
</script>

<style lang="scss">
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-balham.css';

#app {
  overflow: hidden;
  main {
    display: flex;
    height: 100vh;
  }
  .aggrid {
    padding-left: 10%;
  }
  main.loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
