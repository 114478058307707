import { mapFieldToUIName, exclusionGlobalFields } from './fieldHelpers'

const baseColumn = {
  headerName: '',
  field: '',
}
export const baseFields = [
  'pobjrefColorwayCode',
  'lscoAdopted',
  'modifyStamp',
  'lscoTriggerSAPforBulk',
  'colorDominantHslFront',
  // 'lscoImuRetailActual',
  // 'lscoImuWhslActual',
  // 'lscoCogSinLc',
  // 'lscoDemandAttr',
  // 'lscoProdPricePosition',
  // 'lscoCrrtPlndLfCycl',
  // 'lscoQuantityColorway',
  // 'lscoNrfColorCdusOnly',
  //  'lscoDropDate',
  // 'lscUpStSeaGroToSkuLin',
  // 'lscStSeaGroToSkuLin',
  // 'lscoBucketCode',
  // 'lscoNrfSizeScaleUsOnly',
  // 'lscoImuRetailTarget',
  // 'lscoDropLateAddReason',
  // 'lscoSgslKeyName',
  // 'lscoPriceWhslLc',
  // 'lscoPriceRetail',
  // 'lscoCopyViewFieldsOnly',
  // 'ida2a2',
  // 'viewKey',
  // 'lscoNrfClassUsOnly',
  // 'lscLsuStoCusSerCnt',
  // 'pobjrefSeasonId', // This is duplicated
  // 'lscoImuWhslTarget',
  // 'lscoSelectedSizes',
  // 'pc9SeasonId', // This is duplicated
  // 'lscoDroppedUserObjRef',
  // 'lscoPriceRetailLc',
  // 'sgslId',
  // 'lscoAdoptedSizes',
  // 'lscoDroppedUser',
]
export const PC9Fields = [
  'lscoProductType',
  'lscoMerchantName',
  'lscoPdcValue',
  'colorwayName',
  'lscoPdsName',
  'lscoGdName',
  // 'lscoColorwayCode',
  'colorDominantHslFront',
  'lscoDesignerName',
  'lscoCapsuleNew',
]
export const PC9SeasonFields = [
  'lscoProductPricePosit',
  'lscoGtmTrack',
  'lscoEarlyDelivery',
  'lscoAvailable',
  'lscoNewCarryover',
  'lscoOriginalBFF',
  'lscoLSUSCSTrigger',
  'lscoTriggerSAPforCSPO',
  // gender
  // category
]
export const PC5Fields = [
  // 'lscoProductSubCat_1',
  'lscoClassPh',
  'productName',
  'lscoGender',
  'lscoProductCategory',
  'lscoProductCode',
  'lscoSubClassPh',
  'lscoConsumerProdHier',
]

export const generateUIHeaders = (scope = 'custom') => {
  let tmpColumns = []
  baseFields.map(field => {
    const newCol = Object.assign({}, baseColumn)
    newCol.headerName = mapFieldToUIName(field)
    newCol.field = field
    tmpColumns.push(newCol)
  })
  PC9Fields.map(field => {
    const newCol = Object.assign({}, baseColumn)
    newCol.headerName = mapFieldToUIName(field)
    newCol.field = `PC9.${field}`
    tmpColumns.push(newCol)
  })
  PC5Fields.map(field => {
    const newCol = Object.assign({}, baseColumn)
    newCol.headerName = mapFieldToUIName(field)
    newCol.field = `PC5.${field}`
    tmpColumns.push(newCol)
  })
  PC9SeasonFields.map(field => {
    const newCol = Object.assign({}, baseColumn)
    newCol.headerName = mapFieldToUIName(field)
    newCol.field = `PC9Season.${field}`
    tmpColumns.push(newCol)
  })

  if (scope === 'line') {
    tmpColumns = tmpColumns.filter(column => {
      if (exclusionGlobalFields.includes(column.headerName)) {
        return false
      } else {
        return true
      }
    })
  }

  return tmpColumns
}
