import Vue from 'vue'

const config = () => {
  return new Vue({
    data: {
      authClientId: process.env.VUE_APP_AUTH_CLIENT_ID,
      authUrl: process.env.VUE_APP_AUTH_URL,
      authSource: process.env.VUE_APP_AUTH_SOURCE || 'azure',
      authUrlAWS: 'https://dev.userrvt.io',
      baseUrl: process.env.VUE_APP_BASE_URL || '/',
    },
  })
}

export default config
