import { Mesh, WebGLRenderer } from 'three'
import { CubeTextureLoader } from 'three/src/loaders/CubeTextureLoader'

let envMap = null
// / let assetLoader = null
/*
export function setAssetLoader(assetLoaderArg) {
  assetLoader = assetLoaderArg
}


export function setHeader(headers) {
  // console.log()
  return assetLoader.setHeaders(headers)
} 
*/

export function createRenderer(domElement) {
  const renderer = new WebGLRenderer({
    canvas: domElement,
    antialias: true,
    alpha: true,
    getMaxPrecision: true,
    getMaxAnisotropy: true,
    preserveDrawingBuffer: true,
    shadowMapEnabled: true,
  })
  renderer.setPixelRatio(window.devicePixelRatio)
  return renderer
}

export function loadGeometry(loader, path) {
  return loader.loadGeometry(path)
}

export function loadImage(loader, path) {
  return loader.loadImage(path)
}

export function loadImageData(loader, path) {
  return loader.loadImageData(path)
}

export function loadTextures(material, maps, assetLoader, ignoreMissingMaps) {
  return assetLoader.loadTextures(
    material,
    maps,
    assetLoader,
    ignoreMissingMaps,
  )
}

export function getGeometryMesh(geometry) {
  if (!geometry?.children) {
    return
  }

  for (const child of geometry.children) {
    if (child instanceof Mesh) {
      return child
    }
  }

  return null
}

export async function getEnvMap() {
  if (envMap) {
    return Promise.resolve(envMap)
  }

  return new Promise((resolve, reject) => {
    const urls = [
      `/cubemap/px.jpg`,
      `/cubemap/nx.jpg`,
      `/cubemap/py.jpg`,
      `/cubemap/ny.jpg`,
      `/cubemap/pz.jpg`,
      `/cubemap/pz.jpg`,
    ]

    new CubeTextureLoader().load(
      urls,
      map => {
        envMap = map
        resolve(map)
      },
      null,
      reject,
    )
  })
}

export function dispose(loader) {
  console.log(loader)
  loader.disposeAll()
  envMap?.dispose()
}
