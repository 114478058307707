<template>
  <div class="SignIn page center-col">
    <Spinner class="stretch" />
  </div>
</template>

<script>
import Spinner from '../components/Spinner.vue'

export default {
  components: {
    Spinner,
  },
  inject: ['store'],
  computed: {
    nextPath() {
      const { hash } = this.store.router
      if (hash.nextPath && hash.nextPath.length) {
        return hash.nextPath
      }
      return '/userRVT'
    },
  },
  mounted() {
    this.store.auth.signIn(this.nextPath)
  },
}
</script>

<style lang="scss">
.SignIn.page {
  height: 100vh;
}
</style>
