import Vue from 'vue'

const contextMenu = store => {
  return new Vue({
    data: {
      currentId: '',
    },
    methods: {
      hide() {
        this.currentId = ''
      },
      isButtonClass(className) {
        return (
          className === 'ContextMenuButton' || className.baseVal === '' // SVG button clicked
        )
      },
      show(uid) {
        this.currentId = uid
      },
      toggle(uid) {
        if (this.currentId === uid) {
          this.hide()
        } else {
          this.hide()
          this.$nextTick(() => {
            this.show(uid)
          })
        }
      },
    },
  })
}

export default contextMenu
