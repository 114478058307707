<template>
  <SweetModal
    ref="modal"
    :has-title="true"
    :has-content="true"
    :hide-close-button="true"
    :blocking="true"
    :allow-outside-click="false"
    :pulse-on-block="false"
    :width="1200"
  >
    <template #title>
      <div class="modalTitle flex flex-row w-full">
        <div class="flex flex-row flex-grow font-extrabold uppercase">
          Add Selected Users
          <!-- <span class="truncate-10 pl-2">{{ assortmentScope }}</span> -->
        </div>
        <div class="cursor-pointer" @click="cancel">
          <i class="fa fa-window-close"></i>
        </div>
      </div>
    </template>
    <div>
      <h3 v-for="user in store.user.grpUsers" :key="user">
        {{ user }}
      </h3>
    </div>
    <div style="float: right" class="py-5 my-5">
      <button class="ml-5 btn btn-primary" @click="cancel">Cancel</button>
      <button class="ml-5 btn btn-primary" @click="cancel">Confirm</button>
    </div>
  </SweetModal>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue'
export default {
  components: {
    SweetModal,
  },
  inject: ['store'],
  methods: {
    open() {
      this.$refs.modal.open()
    },
    cancel() {
      this.$refs.modal.close()
    },
  },
}
</script>

<style></style>
