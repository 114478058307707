<template>
  <SweetModal
    ref="modal"
    :has-title="true"
    :has-content="true"
    :hide-close-button="true"
    :blocking="true"
    :allow-outside-click="false"
    :pulse-on-block="false"
    :width="540"
  >
    <div>
      <h3>
        <slot> </slot>
      </h3>
    </div>

    <div style="float: right" class="py-5 my-5">
      <button class="CTAButton ml-5 btn btn-primary" @click="deleteUser">
        Yes, Delete
      </button>
      <button class="CTAButton ml-5 btn btn-primary" @click="cancel">
        No, Go back to Screen
      </button>
    </div>
  </SweetModal>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue'
export default {
  components: {
    SweetModal,
  },
  inject: ['store'],
  data() {
    return {
      data: null,
      val: null,
    }
  },
  methods: {
    open(data, val) {
      this.$refs.modal.open()
      this.data = data
      this.val = val
    },
    cancel() {
      this.$refs.modal.close()
    },
    async deleteUser() {
      if (this.val) {
        await this.store.assort.delUserFromApp(
          this.store.user.appName,
          this.data.userId,
        )
      } else {
        await this.store.assort.delUserFromApp(
          this.data.name,
          this.store.user.userDetails.userId,
        )
      }
      this.store.user.refreshUsersInfo()
      this.cancel()
    },
  },
}
</script>

<style lang="scss" scoped>
.CTAButton {
  background-color: #fff;
  height: 40px;
  width: 230px;
  font-size: 16px;
  float: left;
  text-transform: capitalize;
  padding-top: 6px;
  padding-right: 5px;
  padding-left: 5px;
}
</style>
