import HistoryCache from './caching/HistoryCache.js'
import StorageCache from './caching/StorageCache.js'

export default async () => {
  if (window.CacheStorage && window.caches) {
    const storageCache = new StorageCache()
    await storageCache.init()
    return storageCache
  }

  const historyCache = new HistoryCache()
  return historyCache
}
