<template>
  <SweetModal
    ref="modal"
    :has-title="true"
    :has-content="true"
    :hide-close-button="true"
    :blocking="true"
    :allow-outside-click="false"
    :pulse-on-block="false"
    :width="1200"
  >
    <template #title>
      <div class="modalTitle flex flex-row w-full">
        <div class="flex flex-row flex-grow font-extrabold uppercase">
          Sub Group List - {{ store.assort.parentName }} -
          {{ store.assort.applicationName }}
          <!-- <span class="truncate-10 pl-2">{{ assortmentScope }}</span> -->
        </div>
        <div class="cursor-pointer" @click="cancel">
          <i class="fa fa-window-close"></i>
        </div>
      </div>
    </template>
    <div class="flex">
      <input
        class="subgroup mr-10 mt-4 flex"
        type="checkbox"
        :checked="selected"
        @change="selectAll"
      />
      <h3 class="flex">All Child Groups</h3>
    </div>
    <div v-if="!selected">
      <div
        v-for="list in store.assort.correspondingSubGrp"
        :key="list"
        class="flex"
      >
        <input
          :value="list"
          name="subgroup"
          class="subgroup mr-10 mt-4"
          type="checkbox"
          @change="changegrp"
        />
        <h3>
          {{ list }}
        </h3>
      </div>
      <!-- <h3 v-for="user in store.user.grpUsers" :key="user">
        {{ user }}
      </h3> -->
    </div>
    <div style="float: right" class="py-5 my-5">
      <button class="ml-5 btn btn-primary" @click="cancel">Cancel</button>
      <button class="ml-5 btn btn-primary" @click="confirm">Confirm</button>
    </div>
  </SweetModal>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue'
export default {
  components: {
    SweetModal,
  },
  inject: ['store'],
  data() {
    return {
      checkedValue: [],
      selected: false,
    }
  },
  methods: {
    open() {
      if (this.store.assort.correspondingSubGrp.length > 0) {
        this.$refs.modal.open()
      }
    },
    selectAll() {
      this.selected = !this.selected
    },
    cancel() {
      this.$refs.modal.close()
    },
    changegrp() {
      var cboxes = document.getElementsByName('subgroup')
      var len = cboxes.length
      for (var i = 0; i < len; i++) {
        if (this.checkedValue.includes(cboxes[i].value)) {
          let index = this.checkedValue.indexOf(cboxes[i].value)
          if (index > -1) {
            this.checkedValue.splice(index, 1)
          }
        }
        cboxes[i].checked && this.checkedValue.push(cboxes[i].value)
      }
      // var inputElements = document.getElementsByClassName('subgroup');
      // for(var i=0; inputElements[i]; ++i){
      //       if(inputElements[i].checked){
      //            this.checkedValue.push(inputElements[i].value)
      //            break;
      //       }
      // }
    },
    async confirm() {
      let srgpid = []
      if (!this.selected) {
        let subGroups = [...new Set(this.checkedValue)]
        subGroups.map(async srgp => {
          await this.store.assort.subAllGroupId.map(id => {
            if (srgp === id.name) {
              srgpid.push(id.id)
            }
          })
        })
        await this.store.assort.addGrpToApp(srgpid, this.store.user.userData)
      } else {
        await this.store.assort.addGrpToApp(
          this.store.assort.parentGrpId,
          this.store.user.userData,
        )
      }
      await this.store.user.refreshUsersInfo()
      this.checkedValue = []
      this.$emit('confirmed', 'save')
      this.cancel()
    },
  },
}
</script>

<style></style>
