export const carryOver = value => (value === 'C' ? 'Carryover' : 'New')
export const consumerProduct = value => {
  const consumers = {
    '0001': 'Mens',
    '0002': 'Womens',
    '0003': 'Boys',
    '0004': 'Girls',
  }
  return consumers[value]
}
export const adopted = value => {
  const adoptions = {
    Y: 'Yes',
    N: 'Dropped',
  }
  return adoptions[value] || 'N/A'
}
export const earlyDelivery = value => {
  const conversions = {
    no: 'No',
    mayd0: 'May D0',
    pullForward: 'Pull Forward',
    novd0: 'Nov D0',
    earlySalesOnly: 'Early Sales Only',
  }
  return conversions[value]
}

export const GTMTrackValues = value => {
  const conversions = {
    standard: 'Standard',
    flaGap: 'FLA Gaps',
    lawGap: 'LAW Gaps',
    rflaGap: 'RFLA Gaps',
    graphicTeeTrack: 'Graphic Tee Track',
    srt: 'SRT',
    fastTrack: 'Fast Track',
    lateAdd: 'Late Add',
  }

  return conversions[value]
}

export const brand = value => {
  const conversions = {
    489: 'Denizen',
    482: 'Dockers',
    483: "Levi's",
    276: 'Signature',
  }
  return conversions[value]
}

export const brandToUrl = value => {
  const conversions = {
    489: 'denizen',
    482: 'dockers',
    483: "levi's",
    276: 'signature',
  }
  return conversions[value]
}

export const gender = value => {
  const conversions = {
    663: 'Male',
    664: 'Female',
  }
  return conversions[value]
}

export const category = value => {
  const types = {
    673: 'Tops',
    674: 'Bottoms',
  }
  return types[value]
}

export const productType = value => {
  const types = {
    N: 'Product',
    Z: 'Blank',
  }
  return types[value]
}

export const pricePosition = value => {
  const tiers = {
    '01': 'Tier 1',
    '02': 'Tier 2',
    '03': 'Tier 3',
    '04': 'Tier 4',
  }
  return tiers[value]
}

export const classValue = value => {
  const classes = {
    1001: 'Alternative Lengths',
    1004: 'Jeans',
    1005: 'Outerwear',
    1006: 'Polos',
    1009: 'Shorts',
    1010: 'Skirts',
    1013: 'Sweaters',
    1014: 'Sweatshirts',
    1016: 'Tees',
    1018: 'Truckers',
    1020: 'Woven Tops',
    1027: 'Dresses',
    1033: 'Khakis',
    1034: 'Other Pants',
    1041: 'Bodywear Tops',
    1042: 'Bodywear Bottoms',
  }

  return classes[value] || value
}

export const subClassValue = value => {
  const classes = {
    2001: '501 Original Straight',
    2003: 'Relaxed or Boyfriend',
    2004: 'Capris',
    2005: 'Cardigan',
    2006: 'Cargo',
    2007: 'Casual',
    2008: 'Chino',
    2009: 'Classic',
    2010: 'Crew',
    2011: 'Crewneck',
    2012: '501 Custom Tapered',
    2013: 'Cropped',
    2014: 'Dresses',
    2015: 'Flare',
    2016: 'Graphic Crew',
    2017: 'Graphic Crewneck',
    2018: 'Graphic Henley',
    2019: 'Graphic Hooded',
    2020: 'Graphic Polos',
    2021: 'Graphic Tank',
    2022: 'Graphic VNeck',
    2023: 'Athletic',
    2025: 'Heavyweight',
    2026: 'Henley',
    2028: 'Hooded',
    2029: 'Jean Short',
    2030: 'Lightweight',
    2031: 'Loose Straight',
    2032: 'Loose Taper',
    2033: 'Overalls',
    2034: 'Athletic Straight',
    2035: 'Polos',
    2036: 'Refined',
    2037: 'Regular Boot',
    2038: 'Regular or Straight',
    2039: 'Regular Taper',
    2040: 'Relaxed',
    2041: 'Relax Straight',
    2042: 'Rompers',
    2044: 'Shirts',
    2046: 'Short',
    2047: 'Skinny',
    2048: 'Skinny Skinny',
    2049: 'Slim',
    2050: 'Slim Boot',
    2051: 'Slim Slim',
    2052: 'Slim Straight',
    2053: 'Slim Taper',
    2055: 'Straight',
    2057: 'Straight Straight',
    2059: 'Tank',
    2061: 'Trouser',
    2062: 'VNeck',
    2063: 'Vests',
    2065: 'Blouse',
    2067: 'Boot',
    2068: 'Comfort Straight',
    2069: 'Super Skinny',
    2070: 'Mid',
    2071: 'Carpenter',
    2074: 'Graphic Zip Hooded',
    2075: 'Half Zip',
    2077: 'Long',
    2079: 'Scooters',
    2081: 'Zip Crew',
    2082: 'Zip Hooded',
    2083: 'Alternative Neck',
    2084: 'Alternative Neck Graphic',
    2104: 'Briefs',
    2141: 'Leggings',
    2160: 'Midweight',
    2190: 'Loose or Baggy',
    2191: 'Taper',
    2192: 'Bootcut or Flare',
    2193: 'Casual Khaki',
    2194: 'Refined Khaki',
    2195: 'Crops',
    2196: 'Kids Suits or Kids Sets',
    2197: 'Mid Length Shorts',
    2198: 'Short Length Shorts',
    2199: 'Skirts',
    2200: 'Woven Shirts',
    2201: 'Unlined Truckers',
    2202: 'Lined Truckers',
    2203: 'Sherpa Lined Truckers',
    2204: 'Blazers',
    2205: 'Jackets',
    2206: 'Full Zip Sweatshirts',
    2207: 'Hooded Sweatshirts',
    2208: 'Crew Sweatshirts',
    2209: 'Sweaters',
    2210: 'Graphic Tees',
    2211: 'Non Graphic Tees',
    2212: 'Other Knit Tops',
    2213: 'Woven Blouses',
    2214: 'Jogger',
    2247: 'Bras',
    2248: 'Bodysuits',
    2249: 'Tank or Cami',
    2250: 'Tees',
  }
  return classes[value] || value
}

export const capsuleValues = value => {
  const capsules = {
    8090: 'Commuter',
    8830: 'Sustainability',
    8213: 'Made in USA',
    8250: 'Made in Japan',
    9510: 'Wellthread',
    9710: 'Destination Tees',
    9711: 'Chinese New Year',
    9712: 'Pride',
    9713: 'Alternative 5 Pocket',
    9714: 'Unisex',
    9750: 'Clubs',
  }
  return capsules[value]
}
export const regions = region => {
  const regs = {
    AMER: 'Americas',
    ASEAN: 'Asia',
    CNBG: 'Bangalore',
    EUROPE: 'Europe',
    HONGKONG: 'AMA',
    LSE: 'Brussels',
    LSUS: 'San Francisco',
    TOKYO: 'Tokyo',
  }
  return regs[region] || region
}
export const affiliates = value => {
  const affs = {
    '02_AFF': 'Philippines',
    10: 'Signature Japan',
    '10-DOCKER_AFF': 'Japan (Dockers)',
    '27_AFF': 'South Africa',
    '50_AFF': 'Indonesia (Dockers)',
    '54_AFF': 'Korea (Dockers)',
    '54_GBL': 'Denizen Korea',
    '54_LSS_AFF': 'Signature Korea',
    '57_AFF': 'Malaysia',
    '63_GBL_AFF': 'Denizen India',
    AUC_GDV_DIST: 'Au Chau Fashion and Cosmetics Co., Ltd (Vietnam)',
    AUS_GDV_AFF: 'Australia',
    BN_RET: 'Beno Retail',
    BN_WS: 'Beno Wholesale',
    BR_GBL_AFF: 'Brazil',
    BR_GDV_AFF: 'Brazil',
    CA_AFF: 'Canada Wholesale',
    CA_GBL_AFF: 'Canada',
    CA_GDV_AFF: 'Canada Wholesale',
    CA_GDV_ON: 'Canada Online',
    CA_GDV_OUT: 'Canada Outlet',
    CA_GDV_RET: 'Canada Retail',
    CA_ON: 'Canada Online',
    CA_RET: 'Canada Retail',
    CH_AFF: 'China (Signature)',
    CH_GDV_AFF: 'China',
    CLR_AFF: 'Chile (Dockers)',
    CL_GBL_AFF: 'Denizen Chile',
    CL_GDV_AFF: 'Chile',
    CL_LSS_AFF: 'Signature Chile',
    DE_RET: 'Germany Retail',
    DE_WS: 'Germany Wholesale',
    DT_DIST: 'Diethlam (Thailand)',
    DT_GDV_DIST: 'Diethlam Company (Thailand)-Distributor',
    ES_RET: 'Spain Retail',
    ES_WS: 'Spain Wholesale',
    EU: 'Levi Strauss Europe',
    FR_RET: 'France Retail',
    FR_WS: 'France Wholesale',
    GB_ON: 'Great Britain Online',
    GB_RET: 'Great Britain Retail',
    GB_WS: 'Great Britain Wholesale',
    HK_GDV_AFF: 'Hong Kong',
    INDIA_GDV_AFF: 'India',
    INDO_GDV_AFF: 'Indonesia',
    IT_RET: 'Italy Retail',
    IT_WS: 'Italy Wholesale',
    JAPAN_GDV_AFF: 'Japan',
    JG_DIST: 'Singapore (Dockers)',
    JG_GDV_DIST: 'Singapore (Levis)',
    JP_GBL_AFF: 'Denizen Japan',
    KOREA_GDV_AFF: 'Korea',
    LA_AFF: 'Latin America Licensee',
    LA_GBL_AFF: 'Latin America Licensee',
    LA_GDV_AFF: 'Latin America Licensee',
    LSPO_RET: 'Poland Retail',
    LSPO_WS: 'Poland Wholesale',
    LSTU_IC: 'Turkey Intercompany',
    LSTU_RET: 'Turkey Retail',
    LSTU_WS: 'Turkey Wholesale',
    LSUS_AFF: 'US',
    LSUS_ON: 'US Online',
    LSUS_RET: 'US Retail',
    LSUS_WS: 'US Wholesale',
    MALA_GDV_AFF: 'Malaysia',
    MENA_DIST: 'MENA (Singapore)',
    MENA_GDV_DIST: 'MENA (Levis)',
    MX_AFF: 'Mexico and Brazil',
    MX_GBL_AFF: 'Mexico',
    MX_GDV_AFF: 'Mexico',
    MX_LSS_AFF: 'Mexico',
    NEWZ_GDV_AFF: 'New Zealand',
    PHIL_GDV_AFF: 'Philippines',
    PK_AFF: 'Pakistan',
    PK_GBL_AFF: 'Pakistan',
    PK_GDV_AFF: 'Pakistan',
    PR_DKS_AFF: 'Peru (Dockers)',
    PR_GBL_AFF: 'Denizen Peru',
    PR_GDV_AFF: 'Peru',
    PR_LSS_AFF: 'Signature Peru',
    RF_DIST: 'Roots Fashion Pvt Inc(Nepal)',
    RF_GDV_DIST: 'Roots Fashion Pvt Ltd-(Nepal)',
    RKR_DIST: 'RKR Inc.(Guam)',
    RKR_GDV_DIST: 'RKR Inc. (Guam)',
    RU_RET: 'Russia Retail',
    RU_WS: 'Russia Wholesale',
    SA_GDV_AFF: 'South Africa',
    SL_DIST: 'Softlogic Retail Pvt(Sri Lanka)',
    SL_GDV_DIST: 'SoftLogic Retail Pvt Ltd-(Sri Lanka)',
    TAIWAN_GDV_AFF: 'Taiwan Tai Pei',
    US_GBL_AFF: 'US',
    US_GDV_ON: 'US Online',
    US_GDV_OUT: 'US Outlet',
    US_GDV_RET: 'US Retail',
    US_GDV_WS: 'US Wholesale',
    VN_DIST: 'Thanh Bac Indo-China(Vietnam)',
    VTN_GDV_DIST: 'Thanh Bac Indo-China PTE Limited (Vietnam)',
  }
  return affs[value] || value
}

export const valueHelpers = {
  lscoAdopted: adopted,
  lscoNewCarryover: carryOver,
  lscoProductPricePosit: pricePosition,
  lscoEarlyDelivery: earlyDelivery,
  lscoGtmTrack: GTMTrackValues,
  // lscoProductSubCat_1: classValue,
  lscoSubClassPh: subClassValue,
  affiliates: affiliates,
}
