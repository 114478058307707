<template>
  <div class="SignInRedirect page center-col">
    <div v-if="showError" class="error">
      <h1>We're sorry. There was a problem trying to sign you in.</h1>
      <h3>Don't worry. You have options!</h3>
      <p>You can try to <a href="/sign-in">sign in</a> to this app again.</p>
      <p>
        You can attempt to <a @click="onSignOutClick">sign out</a> of all Levi's
        apps.
      </p>
      <p>You can contact technical support.</p>
    </div>
    <div v-else-if="showSignedOut" class="signedOut">
      <h1>You are now signed out.</h1>
      <p>
        <router-link to="/sign-in"> Sign In </router-link>
      </p>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
import Spinner from '../components/Spinner.vue'

export default {
  components: {
    Spinner,
  },
  inject: ['store'],
  computed: {
    hasPkceCode() {
      return this.query.code && this.query.code.length
    },
    query() {
      return this.store.router.query
    },
    showError() {
      return this.query.error && this.query.error.length
    },
    showSignedOut() {
      return !this.hasPkceCode
    },
  },
  mounted() {
    if (this.hasPkceCode) {
      this.store.auth.requestAccessToken(this.query.code, this.query.state)
    }
  },
  methods: {
    onSignOutClick() {
      this.store.auth.signOut()
    },
  },
}
</script>

<style>
.SignInRedirect {
  padding: 2rem;
}
</style>
