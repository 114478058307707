import './scss/app.scss'
import Vue from 'vue'
import { LicenseManager } from '@ag-grid-enterprise/core'
import App from './App.vue'
import hotkeys from './hotkeys.js'
import PortalVue from 'portal-vue'
import Store from './state/Store.js'
import { SweetModal } from 'sweet-modal-vue'
import VueAsyncComputed from 'vue-async-computed'
import VueDragDrop from 'vue-drag-drop'
import VueObserveVisibility from 'vue-observe-visibility'
import VTooltip from 'v-tooltip'
import VueWindowSize from 'vue-window-size'
import './icons.js'
import 'animate.css'

import { longClickDirective } from 'vue-long-click'

const liscKey =
  'CompanyName=Levis Strauss & Co.,LicensedGroup=Bomapp/gap-debrief,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=5,LicensedProductionInstancesCount=1,AssetReference=AG-012062,ExpiryDate=13_January_2022_[v2]_MTY0MjAzMjAwMDAwMA==84b5111072c8813bb4bd316d98f86de6'
LicenseManager.setLicenseKey(liscKey)
Vue.config.devtools = true
Vue.config.performance = true
Vue.config.productionTip = false
Vue.component('SweetModal', SweetModal)
Vue.use(hotkeys())
Vue.use(PortalVue)
Vue.use(VueAsyncComputed)
Vue.use(VueDragDrop)
Vue.use(VueObserveVisibility)
Vue.use(VTooltip)
Vue.use(VueWindowSize)
// Vue.directive('long-press', LongPress)
const longClickInstance = longClickDirective({ delay: 250, interval: 50 })
Vue.directive('longclick', longClickInstance)

const store = new Store()
store.init()

if (process.env.NODE_ENV === 'development') {
  // require('./miragejs/server').makeServer()
}

new Vue({
  router: store.router.instance,
  provide: {
    store,
  },
  render: h => h(App),
}).$mount('#app')

// PIXI.utils.sayHello(type)
