<template>
  <div class="mt-20 ml-8 userGrid-wrapper">
    <p class="cursor-pointer" @click="appPage">
      <b
        >{{ store.user.appName.split('RVT')[0] + ' RVT' }}
        <span v-if="!store.user.userData"> > Add LS&Co User </span>
        <span v-else> > User Details</span>
      </b>
    </p>
    <div class="mb-8 flex flex-end justify-end button-top">
      <button class="CTAButton bg-transparent mr-2 mt-4" @click="valueNotNull">
        Save Changes
      </button>
    </div>
    <div class="splitter">
      <ul class="nav">
        <li
          :class="{ active: page === 'userDetails' }"
          @click="page = 'userDetails'"
        >
          User Details
        </li>
        <li :class="appDis" @click="page = 'userApp'">Applications</li>
        <!-- <li @click="urlUser">
          <router-link :to="url"> LS&amp;Co Internal Users </router-link>
        </li>
        <li @click="urlVendor">
          <router-link :to="url"> Vendor Users </router-link>
        </li> -->
      </ul>
      <p v-if="store.user.userData" class="mb-8 mr-8">
        {{ store.user.userData }}
      </p>
    </div>
    <div v-if="page === 'userDetails'">
      <UserDetails />
    </div>
    <div v-else>
      <AppDetails />
    </div>
  </div>
</template>

<script>
import UserDetails from './UserDetails.vue'
import AppDetails from './AppDetails.vue'
export default {
  name: 'AddUser',
  components: {
    UserDetails,
    AppDetails,
  },
  inject: ['store'],
  data() {
    return {
      page: 'userDetails',
      save: false,
    }
  },
  computed: {
    userData() {
      return this.store.user.userData
    },
    appDis() {
      if (!this.userData) {
        return 'disabled'
      }
      if (this.page !== 'userDetails') {
        return 'active'
      }
      return ''
    },
  },
  watch: {},
  methods: {
    valueNotNull() {
      this.store.user.save = true
    },
    appPage() {
      this.store.user.condition = 'global'
    },
  },
}
</script>
<style scoped>
.active {
  color: #c41230;
  font-weight: bold;
  border-bottom: 2px solid #c41230;
}
.disabled {
  color: gray;
  pointer-events: none;
  cursor: default;
}
</style>
