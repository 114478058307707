/* eslint-disable no-unused-vars */
import Vue from 'vue'
// import { decode, encode } from '../util/params.js'
// import klona from 'klona'

const lines = store => {
  return new Vue({
    data: {
      componentKey: 0,
      pageSize: 7000,
    },
    computed: {
      error() {
        return this.$asyncComputed.items.exception
      },
      isLoading() {
        const notStarted =
          this.items.length === 0 && this.$asyncComputed.items.success
        return notStarted || this.$asyncComputed.items.updating
      },
      isReady() {
        if (this.$asyncComputed.items.updating) {
          return false
        }
        if (this.$asyncComputed.items.success) {
          if (this.items.length > 0) {
            return true
          }
        } else {
          if (this.items.length > 0) {
            return true
          } else {
            return false
          }
        }
        return false
      },
      isUnauthorized() {
        return this.error && this.error.status === 401
      },
    },
    asyncComputed: {
      items: {
        default: [],
        lazy: true,
        async get() {
          let records = []
          let keepGoing = true
          let offset = 0
          store.fetchingAssortment = true
          store.selectedPC9s.selected = []
          while (keepGoing) {
            const response = await store.api.get({
              url: '/api/line/filter/v2',
              params: {
                brand: store.currentBrand,
                year: store.currentYear,
                season: store.currentSeason,
                hub: store.lookupHub,
                perPage: this.pageSize,
                skip: offset,
              },
            })
            if (response.message === 'Unauthorized') {
              const error = new Error('Unauthorized')
              error.status = 401
              throw error
            }
            await records.push.apply(records, response.results)
            offset += this.pageSize
            if (response.results.length < this.pageSize) {
              keepGoing = false
            }
          }
          const tempSet = new Set([...records])
          records = [...tempSet]
          this.componentKey += 1
          store.fetchingAssortment = false
          return records
        },
      },
    },
    methods: {
      setCurrentPC9(pc9Data) {
        this.pc9Data = pc9Data
      },
      async refresh() {
        // store.fetchingAssortment = true
        this.$asyncComputed.items.update()
      },
    },
  })
}

export default lines
