<template>
  <SweetModal
    ref="modal"
    :has-title="true"
    :has-content="true"
    :hide-close-button="true"
    :blocking="true"
    :allow-outside-click="false"
    :pulse-on-block="false"
    :width="540"
  >
    <div>
      <h3>
        <slot> </slot>
      </h3>
    </div>
    <div style="float: right" class="py-5 my-5">
      <h2>Are you sure you want to deny access for {{ store.user.appName }}</h2>
      <div class="text-left">Note for User</div>
      <textarea v-model="noteToUser"></textarea>
      <div class="text-left">Internal Description</div>
      <textarea v-model="comments"></textarea>
      <button
        id="deny"
        class="CTAButton ml-5 btn btn-primary"
        @click="deleteUser"
      >
        Yes, Deny Access
      </button>
      <button class="CTAButton ml-5 btn btn-primary" @click="cancel">
        No, Go back to Screen
      </button>
    </div>
  </SweetModal>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue'
export default {
  components: {
    SweetModal,
  },
  inject: ['store'],
  data() {
    return {
      data: null,
      val: null,
      noteToUser: null,
      comments: null,
    }
  },
  methods: {
    open(data, val) {
      this.$refs.modal.open()
      this.data = data
    },
    cancel() {
      this.$refs.modal.close()
    },
    async deleteUser() {
      await this.store.assort.statusDataDenied(
        this.store.user.appName,
        this.data.userId,
        this.comments,
        this.noteToUser,
        this.data.email,
      )

      this.store.assort.refreshDenied()
      this.store.assort.refreshAccess()
      this.cancel()
    },
  },
}
</script>

<style lang="scss" scoped>
.CTAButton {
  background-color: #fff;
  height: 40px;
  width: 230px;
  font-size: 16px;
  float: left;
  text-transform: capitalize;
  padding-top: 6px;
  padding-right: 5px;
  padding-left: 5px;
}
.CTAButton:hover {
  background-color: #c21a31;
}
</style>
