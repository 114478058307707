<template>
  <div>
    <div class="flex autocompleteVendor">
      <label class="pt-5 pl-4 w-70">
        <b>User Id <span class="mandatory">*</span></b>
      </label>
      <input v-model="userId" class="ml-40" type="text" @input="onChange" />
    </div>
    <div class="flex mb-5 mt-5">
      <label class="pt-5 pl-4 w-70">
        <b>Name <span class="mandatory">*</span></b>
      </label>
      <input v-model="fullName" class="ml-40" type="text" />
    </div>
    <div class="flex mb-5">
      <label class="pt-5 pl-4 w-70"> <b>Organization</b> </label>
      <select id="org" class="transparentSelect w-70" name="org">
        <option class="trans" value="Organization1">Organization1</option>
        <option class="trans" value="Organization2">Organization2</option>
        <option class="trans" value="Organization3">Organization3</option>
        <option class="trans" value="Organization4">Organization4</option>
      </select>
    </div>
    <div class="flex mb-5">
      <label class="pt-5 pl-4 w-70">
        <b>Email <span class="mandatory">*</span></b>
      </label>
      <input v-model="email" class="ml-40" type="text" />
    </div>
    <div class="flex mb-5">
      <label class="pt-5 pl-4 w-70">
        <b>Login Type <span class="mandatory">*</span></b>
      </label>
      <input v-model="ltype" class="mt-2" type="radio" value="AZURE" />
      <span class="mt-2"> AZURE </span>
      <input v-model="ltype" class="ml-5 mt-2" type="radio" value="COGNITO" />
      <span class="mt-2"> COGNITO </span>
    </div>
    <div class="flex mb-5">
      <label class="pt-5 pl-4 w-70"> <b>Phone Number</b> </label>
      <input v-model="phone" class="ml-40" type="text" />
    </div>
    <div class="flex mb-5">
      <label class="pt-5 pl-4 w-70"> <b>Address</b> </label>
      <input v-model="address" class="ml-40" type="text" />
    </div>
    <div class="flex mb-5">
      <label class="pt-5 pl-4 w-70"> <b>Applications</b> </label>
      <p v-for="app in applications" :key="app" class="borderBox">
        {{ app }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserDetails',
  inject: ['store'],
  props: {},
  data() {
    return {
      userId: null,
      fullName: null,
      email: null,
      phone: null,
      address: null,
      applications: [],
      response: [],
      ltype: 'AZURE',
    }
  },
  computed: {
    vendorDetails() {
      return this.store.user.userDetails
    },
    save() {
      return this.store.user.save
    },
  },
  watch: {
    vendorDetails(newVal, oldVal) {
      if (newVal) {
        this.applications = []
        this.fetchUserDetails()
      }
    },
    async save(newVal, oldVal) {
      let details = null
      if (newVal) {
        details = {
          fullName: this.fullName,
          email: this.email,
          userId: this.userId,
          phone: this.phone || '',
          address: this.address || '',
          vendor: true,
          isVendor: true,
        }
        if (this.fullName && this.email && this.userId) {
          if (!this.store.user.userData) {
            await this.store.user.addNewUser(this.userId, details, this.ltype)
            this.store.user.userData = this.userId
          } else {
            await this.store.user.editUser(this.userId, details, this.ltype)
            this.store.user.userData = this.userId
          }
          this.store.assort.refreshVendors()
        } else {
          this.store.user.errormsg = 'Please enter mandatory fields'
        }
        this.store.user.save = false
      }
    },
  },
  mounted() {
    this.userId = this.store.user.userData
    this.fetchUserDetails()
    if (this.store.user.appName === 'NestRVT') {
      this.ltype = 'COGNITO'
    }
  },
  methods: {
    setResult(response) {
      this.userId = response.userId

      this.isOpen = false
    },
    onChange() {
      this.isOpen = true
    },
    fetchUserDetails() {
      if (this.userId) {
        let resp = this.store.user.userDetails
        this.fullName = resp.fullName
        this.email = resp.email
        this.phone = resp.phone
        this.address = resp.address
        resp.applications.map(app => {
          if (app.status === 'Active') {
            this.applications.push(app.name)
            if (app.authProvider && app.name !== 'NestRVT') {
              this.ltype = app.authProvider
            }
          }
        })
      }
    },
  },
}
</script>
<style scoped>
input[type='text'] {
  margin-top: 3px;
  width: 30%;
  height: 40px;
  border-style: groove;
  border-width: 1px;
  box-sizing: border-box;
  background-color: transparent;
  color: black;
}
.transparentSelect {
  background-color: transparent;
}
.trans {
  background: #e5e5e5;
}
.borderBox {
  text-align: center;
  margin-left: 8px;
  width: 8%;
  height: 40px;
  padding-top: 8px;
  cursor: pointer;
  border-style: groove;
  border-width: 1px;
  box-sizing: border-box;
  background-color: transparent;
  color: black;
}
/* input[type='text']:focus {
  
} */

.autocompleteVendor {
  position: relative;
}

.autocomplete-results {
  padding: 0;
  margin: 0 0 0 17%;
  border: 1px solid #eeeeee;
  height: 120px;
  /* min-height: 1em;
  max-height: 6em; */
  overflow: auto;
  width: 317px;
  background-color: transparent;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  /* padding: 4px 2px; */
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #4aae9b;
  color: white;
}
.mandatory {
  color: red;
}
</style>
