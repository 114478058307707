<template>
  <SweetModal
    ref="modal"
    :has-title="true"
    :has-content="true"
    :hide-close-button="true"
    :blocking="true"
    :allow-outside-click="false"
    :pulse-on-block="false"
    width="740"
  >
    <div>
      <h3>
        <slot> </slot>
      </h3>
    </div>

    <div style="float: left" class="py-10 my-2 rounded-lg">
      <button class="CTAButton btn btn-primary w-1/2" @click="cancel">
        Close
      </button>
    </div>
  </SweetModal>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue'
export default {
  components: {
    SweetModal,
  },
  inject: ['store'],
  data() {
    return {
      data: null,
      val: null,
      statusData: [],
    }
  },
  methods: {
    open(data, val) {
      this.$refs.modal.open()
      this.data = data
      this.val = val
    },
    cancel() {
      this.$refs.modal.close()
    },
  },
}
</script>

<style lang="scss" scoped>
.CTAButton {
  background-color: #fff;
  height: 40px;
  width: 230px;
  font-size: 16px;
  float: left;
  text-transform: capitalize;
  padding-top: 6px;
  padding-right: 5px;
  padding-left: 5px;
}
.CTAButton:hover {
  background-color: #c21a31;
}
</style>
