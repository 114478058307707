<template>
  <SweetModal
    ref="modal"
    :has-title="true"
    :has-content="true"
    :hide-close-button="true"
    :blocking="true"
    :allow-outside-click="false"
    :pulse-on-block="false"
    width="750"
  >
    <div class="flex flex-col text-left">
      <div class="items-end text-black" @click="cancel">
        <i class="close far fa-times-circle float-right"></i>
      </div>
      <form @submit.prevent="submitForm">
        <div class="heading">Contact Support</div>
        <div class="text">
          Email Subject
          <input
            v-model="subject"
            type="text border"
            placeholder="Subject Here"
          />
        </div>
        <div class="flex mt-5 pb-12">
          <span class="pl-1 pr-5 text">Attachments </span>
          <div class="pl-6 upload_button">
            <label>
              <input type="file" @change="onAttachment" />
              <div
                class="cursor-pointer border-black border-2 mr-3 p-4 font-bold text-black"
              >
                Add Attachment
              </div>
            </label>
            <div class="atachments flex-row flex attachmentSection pt-1">
              <div v-for="at in attachments" :key="at.id">
                <p class="rounded-lg ml-1 att-title cursor-pointer text-black">
                  {{ at.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="text text-3xl font-bold">Note to Team</div>
        <textarea id="" v-model="note" name="note" placeholder="">
Note</textarea
        >
        <div style="float: left" class="py-5 my-5">
          <button class="CTAButton ml-5 btn btn-primary" type="submit">
            Send Email
          </button>
        </div>
      </form>
    </div>
  </SweetModal>
</template>

<script>
export default {
  name: 'Help',
  components: {},
  inject: ['store'],

  data: () => ({
    resolve: null,
    subject: '',
    note: '',
    fileNames: '',
    attachments: [],
    attachfile: [],
  }),
  beforeDestroy() {
    this.$refs.modal.close()
  },
  methods: {
    async submitForm() {
      this.attachments.map(att => {
        this.attachfile.push(att.name)
      })
      await this.store.api.post({
        url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/support/email`,
        body: {
          subject: this.subject,
          note: this.note,
          fileNames: this.attachfile,
        },
      })
      if (this.attachments.length) {
        this.attachments?.map(async att => {
          let url = await this.updateAttachments(att.name)
          await this.uploadAttachmenttoAws(url.url, att.file)
        })
      }
      this.$refs.modal.close()
      this.store.user.message =
        'Thank you, your email request has been successfully submitted!'
    },
    async onAttachment(e) {
      this.attachments.push({
        name: e.target.files[0].name,
        file: e.target.files[0],
      })
    },
    async uploadAttachmenttoAws(url, file) {
      const res = await this.store.user.uploadfile(url, file)
      return res
    },
    async updateAttachments(name) {
      const res = await this.store.api.get({
        url: `${process.env.VUE_APP_LEVI_RVT_API_URL}/api/support/uploadurl?filename=${name}&contenttype=plain/text`,
      })
      return res
    },
    open() {
      this.$refs.modal.open()
    },
    cancel() {
      this.$refs.modal.close()
    },
    submit() {
      this.cancel()
    },
  },
}
</script>
<style scoped>
.heading {
  font-size: 25px;
  color: black;
  font-weight: bold;
  padding-bottom: 60px;
}
.text {
  font-size: 20px;
  color: black;
  font-weight: bold;
  padding-bottom: 20px;
}
.text input {
  width: 40%;
  height: 30px;
  margin-left: 17px;
  border-radius: 4px;
  border: 1px solid;
  font-size: 14px;
}
textarea {
  /* width: 85%; */
  height: 250px;
  border-radius: 4px;
  color: black;
}
.upload_button input[type='file'] {
  display: none;
}
.att-title {
  font-size: 12px;
  padding: 0 5px;
  border-radius: 2px;
  border: 1px solid #ccc;
}
.att-text {
  text-transform: capitalize;
}
.CTAButton {
  background-color: #fff;
  height: 40px;
  width: 230px;
  font-size: 16px;
  float: left;
  text-transform: capitalize;
  padding-top: 6px;
  padding-right: 5px;
  padding-left: 5px;
}
.CTAButton:hover {
  background-color: #c21a31;
}
</style>
