import deepEqual from 'deep-equal'
import hotkeys from 'hotkeys-js'

export default () => {
  hotkeys.filter = () => {
    return true
  }
  let scopes = []

  const pushScope = scope => {
    scopes.push(scope)
    hotkeys.setScope(scope)
  }

  const popScope = scope => {
    if (scopes[scopes.length - 1] === scope) {
      scopes.pop()
      hotkeys.setScope(scopes[scopes.length - 1])
    }
  }

  const addHotkeys = (scope, keys) => {
    const keyNames = Object.keys(keys)
    keyNames.forEach(keyName => {
      hotkeys(keyName, scope, keys[keyName])
    })
    pushScope(scope)
  }

  const removeHotkeys = scope => {
    scopes = scopes.filter(aScope => aScope !== scope)
    hotkeys.deleteScope(scope)
  }

  return {
    install(Vue, alias = {}) {
      Vue.directive('hotkey', {
        bind(el, binding) {
          const { scope, keys } = binding.value
          addHotkeys(scope, keys)
        },
        componentUpdated(el, binding) {
          if (!deepEqual(binding.value, binding.oldValue)) {
            removeHotkeys(binding.oldValue.scope)
            const { scope, keys } = binding.value
            addHotkeys(scope, keys)
          }
        },
        unbind(el, binding) {
          const { scope } = binding.value
          popScope(scope)
          removeHotkeys(scope)
        },
      })
    },
  }
}
