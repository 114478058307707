<template>
  <div class="mt-16 ml-8 userGrid-wrapper">
    <p class="cursor-pointer -mt-6 mb-6 text-3xl" @click="appPage">
      <b
        >{{ store.user.appName.split('RVT')[0] + ' RVT' }}
        <span v-if="!store.user.userData"> > Add Group</span>
        <span v-else> > Group Details</span>
      </b>
    </p>
    <AddUsers ref="addUsers" />
    <div class="mb-8 flex flex-end justify-end button-top">
      <button
        v-if="page === 'userGrp' && this.store.user.grpUsers.length"
        class="CTAButton bg-transparent mr-2"
        @click="addUsers"
      >
        Add Selected Users
      </button>
      <button class="CTAButton bg-transparent mr-2">Save Changes</button>
    </div>
    <div class="splitter">
      <ul class="nav">
        <li :class="{ active: page === 'grpDetails' }" @click="userDetails">
          Group Details
        </li>
        <li :class="{ active: page !== 'grpDetails' }" @click="applications">
          Users
        </li>
        <!-- <li @click="urlUser">
          <router-link :to="url"> LS&amp;Co Internal Users </router-link>
        </li>
        <li @click="urlVendor">
          <router-link :to="url"> Vendor Users </router-link>
        </li> -->
      </ul>
      <div class="inlineSearchInput mb-8" :class="{ active: isSearching }">
        <input
          v-model="searchTerm"
          type="text"
          placeholder="Enter User Name or ID"
        />
      </div>
      <div
        v-if="page === 'userGrp'"
        class="pt-3 flex pr-3"
        @click="() => (isSearching = !isSearching)"
      >
        <i class="fa fa-search mr-3"></i>
        <p class="cursor-pointer mb-12">Search</p>
      </div>
    </div>
    <div v-if="page === 'grpDetails'">
      <GroupDetail />
    </div>
    <div v-else>
      <UserGroup :response="response" />
    </div>
  </div>
</template>

<script>
import GroupDetail from './GroupDetail.vue'
import UserGroup from './UserGroup.vue'
import AddUsers from '../components/Modal/AddUsers.vue'
export default {
  name: 'AddUser',
  components: {
    GroupDetail,
    UserGroup,
    AddUsers,
  },
  inject: ['store'],
  data() {
    return {
      page: 'grpDetails',
      isSearching: false,
      searchTerm: '',
      response: [],
    }
  },
  watch: {
    async searchTerm(newVal, oldVal) {
      if (newVal) {
        let resp = await this.store.assort.search(this.searchTerm)
        this.response = resp
      } else {
        this.response = this.store.user.listOfUsers
      }
    },
  },
  methods: {
    userDetails() {
      this.page = 'grpDetails'
    },
    applications() {
      this.page = 'userGrp'
    },
    appPage() {
      this.store.user.condition = 'global'
    },
    addUsers() {
      this.$refs.addUsers.open()
    },
  },
}
</script>

<style lang="scss" scoped>
input[type='text'] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  color: black;
}
</style>
